import _ from 'lodash';
import { RulesSatisfactionType, RuleType } from './types';
import { checkIfRulesSatisfied } from '../helpers';
import { useFormikContext } from 'formik';

export const RuleEvaluatorWrapper = ({
  rules,
  rulesSatisfactionType,
  children,
  elementType,
}: {
  rules: Array<RuleType>;
  rulesSatisfactionType?: RulesSatisfactionType;
  children: React.ReactElement;
  elementType: 'field' | 'section' | 'page';
}) => {
  const formik: any = useFormikContext();

  const itemConfig = elementType === 'field'
    ? children.props.itemData.config
    : children.props.config.config;

  const defaultRules = _.filter(rules, (rule: RuleType) => !rule.type || rule.type === 'default');
  const isDisplayed: boolean = _.some(defaultRules)
    ? checkIfRulesSatisfied(formik.values, defaultRules, rulesSatisfactionType)
    : (elementType === 'field' ? itemConfig.displayed : true);
  itemConfig.displayed = isDisplayed;

  if (elementType === 'field') {
    const requiredRules = _.filter(rules, { type: 'required' });
    const isRequired: boolean = _.some(requiredRules)
      ? checkIfRulesSatisfied(formik.values, requiredRules, rulesSatisfactionType)
      : itemConfig.required;
    itemConfig.required = isRequired;
  }

  return { ...children, props: { ...children.props } };
};
