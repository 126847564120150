import React, { useContext } from "react";
import { formatCurrency } from "../../../helpers/stringFormatters";
import { VendorContext } from "../../../context";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { makeStyles } from "@mui/styles";
import { getContrastColor } from "../../../helpers";

import { Box } from "@mui/material";
import { ProposalProduct } from "@trnsact/trnsact-shared-types/dist/generated";
import ProductCard from "./ProductCard";


interface MenuOption {
  monthlyPayments: any,
  aftermarketName?: any,
  handleClick: any,
  name: string,
  term: any,
  downPayment: any,
  advancePayments: any,
  titleBgColor?: string,
  packages?: any,
  titleColor?: string,
  products: any,
  payment: any;
}

interface Props {
  option: MenuOption,
  setIsMenuOptionConfirmationModalOpen: any,
  setSelectedMenuOption: any,
};

const MenuOption = ({ option, setIsMenuOptionConfirmationModalOpen, setSelectedMenuOption }: Props) => {

  const classes = useStyles();
  const { monthlyPayments, aftermarketName, handleClick, name, term, downPayment, advancePayments, titleBgColor, packages, titleColor } = option;

  const { color: vendorColor } = useContext(VendorContext);

  const aftermarketNameSplit = !!aftermarketName ? aftermarketName.split(", ") : null;

  let primaryColor = titleBgColor || vendorColor || "#ffffff";
  if (primaryColor) {
    primaryColor = primaryColor.includes("#") ? primaryColor : `#${primaryColor}`;
  }

  let headerFontColor = titleColor ? (titleColor.includes("#") ? titleColor : `#${titleColor}`) : getContrastColor(primaryColor);

  return (
    <>
      <Grid item container className={classes.gbbCardContainer} justifyContent="center" xs={12} sm={6} md={4} lg={4} xl={4}>
        <Card className={classes.gbbCard}>
          <CardContent className={classes.cardContent}>
            <Box className={classes.cardContentWrapper}>
              <Typography variant="h4" className={classes.optionTitle} style={{ backgroundColor: primaryColor, color: headerFontColor }}>
                {name}
              </Typography>
              {option.products.map((product: ProposalProduct) => {
                return <React.Fragment key={product.proposalProductId}>
                  <ProductCard product={product} primaryColor={primaryColor} />
                </React.Fragment>
              })}
              <Box className={classes.perMonthContainer}>
                <Typography>
                  <strong>{formatCurrency(parseFloat(option.payment.paymentAmountPerPeriod).toFixed(2))}</strong>
                </Typography>
                <Typography style={{ color: "#9E9E9E" }}>per month</Typography>
              </Box>
            </Box>
          </CardContent>
          <CardActions style={{ justifyContent: "center" }}>
            <Button
              className={classes.chooseButton}
              style={{ backgroundColor: primaryColor, color: headerFontColor }}
              onClick={() => {
                setSelectedMenuOption(option);
                setIsMenuOptionConfirmationModalOpen(true);
              }}
            >
              Choose
            </Button>
          </CardActions>
        </Card>
      </Grid>
    </>
  );
};

const useStyles = makeStyles(() => {
  return {
    gbbCardContainer: {
      /*       flexGrow: 1,
            maxWidth: 260, */
    },
    gbbCard: {
      minHeight: 350,
      minWidth: 210,
      //color: primaryColor,
      boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
      display: "flex",
      justifyContent: "space-between",
      flexDirection: "column"
    },
    cardContent: {
      height: "100%",
      padding: "0 !important",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    },
    cardContentWrapper: {
      display: "flex",
      flexDirection: "column",
      flexBasis: "100%",
    },
    optionTitle: {
      padding: "16px",
      textAlign: "center",
    },
    perMonthContainer: {
      background: "#F5F5F5",
      margin: "auto 4px 12px 4px",
      textAlign: "center",
      borderTop: "1px solid #F5F5F5",
      //marginTop: 10
    },
    chooseButton: {
      borderRadius: 8,
      width: "100%",
    },
  };
});

export default MenuOption;