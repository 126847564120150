import {
  Box,
} from '@mui/material';
import { FormikProps, useFormikContext } from 'formik';
import _ from 'lodash';
import { useEffect } from 'react';
import { FieldType } from '../types';
import { makeStyles } from '@mui/styles';
import { SelectField } from './SelectField';

const useStyles = makeStyles(() => ({
  selectHeight: {
    maxHeight: '40vh',
  },
}));
const IsOwnerOrOfficerField = ({
  itemData,
  value,
  locale,
  displayLabel,
  isNeedToMoveLabelOutOfInput,
}: {
  itemData: FieldType;
  value: any;
  locale: string;
  displayLabel: string;
  isNeedToMoveLabelOutOfInput: boolean;
}) => {
  const { id: fieldId, config: fieldConfig } = itemData;
  const classes = useStyles();
  const formik: FormikProps<any> = useFormikContext();

  const fieldsKeysToPopulate = ['firstName', 'lastName', 'email']
  const pcFirstName = _.get(formik, 'values.firstName', '');
  const pcLastName = _.get(formik, 'values.lastName', '');
  const pcFullName = `${pcFirstName} ${pcLastName}`

  //TODO: usePrevious custom hook
  useEffect(() => {
    if (formik.values.isOwnerOrOfficer === '') {  // not set yet
      return;
    }
    if (formik.values.isOwnerOrOfficer) {
      _.forEach(fieldsKeysToPopulate, fieldKey => {
        const pcValue = _.get(formik, `values.${fieldKey}`, '');
        formik.setFieldValue(`owners[0].${fieldKey}`, pcValue, false);
      });
    } else {
      _.forEach(fieldsKeysToPopulate, fieldKey => {
        formik.setFieldValue(`owners[0].${fieldKey}`, '', false);
      });
    }
  }, [formik.values.isOwnerOrOfficer]);

  return (
    <SelectField
      itemData={itemData}
      value={value}
      locale={locale}
      displayLabel={_.replace(displayLabel, '{full_name}', pcFullName)}
      isNeedToMoveLabelOutOfInput={isNeedToMoveLabelOutOfInput}
    />
  );
};

export { IsOwnerOrOfficerField };
