import { FormikProps, useFormikContext } from 'formik';
import { useEffect, useState, useContext } from 'react';
import { FieldType } from '../types';
import { CommonDataContext } from "../../context";
import { Box, Grid } from '@mui/material';
import _ from 'lodash';

export const HtmlOutputField = ({
  itemData,
}: {
  itemData: FieldType;
}) => {
  const { config: fieldConfig } = itemData;
  const { publicAccount } = useContext(CommonDataContext);
  const formik: FormikProps<any> = useFormikContext();
  const [markupToDisplay, setMarkupToDisplay] = useState(fieldConfig.customMarkup || "");

  const markupBySourceHashMap = {
    'VP - Personal Guarantee Agreement Disclosure': _.get(publicAccount, 'vendorProfile.ocaPersonalGuaranteeAgreementDisclosure', '')
  };

  useEffect(() => {
    if (fieldConfig.selectedSource && fieldConfig.selectedSource !== "Custom Markup") {
      setMarkupToDisplay(markupBySourceHashMap[fieldConfig.selectedSource]);
    }
    if (fieldConfig.selectedApiId) {
      formik.setFieldValue(fieldConfig.selectedApiId, markupToDisplay);
      console.log("SET: " + fieldConfig.selectedApiId)
    }
  }, []);

  return (
    <Box
      component={Grid}
      item
      container
      xl={_.get(fieldConfig, 'columns.xl', 12)}
      lg={_.get(fieldConfig, 'columns.lg', 12)}
      md={_.get(fieldConfig, 'columns.md', 12)}
      sm={_.get(fieldConfig, 'columns.sm', 12)}
      xs={_.get(fieldConfig, 'columns.xs', 12)}
      display={{
        xl: !itemData.config.displayed ? 'none' : 'block',
        lg: !itemData.config.displayed ? 'none' : 'block',
        md: !itemData.config.displayed ? 'none' : 'block',
        sm: !itemData.config.displayed ? 'none' : 'block',
        xs: !itemData.config.displayed ? 'none' : 'block',
      }}
    >
      <div
        dangerouslySetInnerHTML={{
          __html: markupToDisplay,
        }}
      ></div>
    </Box>
  );
};
