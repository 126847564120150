// Component was cied from OCAv3 as is
// @ts-nocheck
import React from "react";
import Fade from "@mui/material/Fade";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import { Grid, Typography } from '@mui/material';
import searchGIF from "../assets/images/searching-papers-blue-small.gif";

function DeltaTimer(render, interval) {
  var timeout;
  var lastTime;

  this.start = start;
  this.stop = stop;

  function start() {
    timeout = setTimeout(loop, 0);
    lastTime = +new Date();
    return lastTime;
  }

  function stop() {
    clearTimeout(timeout);
    return lastTime;
  }

  function loop() {
    var thisTime = +new Date();
    var deltaTime = thisTime - lastTime;
    var delay = Math.max(interval - deltaTime, 0);
    timeout = setTimeout(loop, delay);
    lastTime = thisTime + delay;
    render(thisTime);
  }
}

class ProcessingScreen extends React.Component {
  constructor(props) {
    super(props);
    this.setState = this.setState.bind(this);
  }

  state = {
    fadeInterval: 3600,
    fadeIn: false,
    fadeTextArr: [
      "Reaching out to lenders network...",
      "Comparing financial products...",
      "Sorting out interest rates...",
      "Scrutinizing spreadsheets...",
      "Comparing payment options...",
      "Looking at alternate sources...",
    ],
    fadeTextI: -1,
  };

  componentDidMount() {
    var timer = new DeltaTimer(time => {
      this.setState((prevState, props) => {
        const { fadeIn, fadeTextArr, fadeTextI } = prevState;
        let nextFadeTextI = fadeTextI;
        if (!fadeIn) {
          if (fadeTextI === fadeTextArr.length - 1) {
            nextFadeTextI = 0;
          } else {
            nextFadeTextI += 1;
          }
        }
        return { fadeIn: !fadeIn, fadeTextI: nextFadeTextI };
      });
    }, this.state.fadeInterval);

    timer.start();
  }

  render() {
    const { fadeInterval, fadeIn, fadeTextArr, fadeTextI } = this.state;
    return (
      <React.Fragment>
        <span styles={{ fontSize: "30px", fontWeight: 300, display: "block", lineHeight: "32px", marginBottom: "8px" }}>
          Please give us a moment while we take a look at your credit.
        </span>
        <p>
          This may take a few minutes. <strong>Please do not navigate away from this screen.</strong>
        </p>
        <br />
        <Container maxWidth="sm">
          <Box height={1}>
            <Container maxWidth="lg" fixed={true}>
              <Fade in={fadeIn} timeout={fadeInterval}>
                <Typography align="center" variant="h3" style={{ fontFamily: "Source Sans Pro, sans-serif" }}>
                  {fadeTextArr[fadeTextI]}
                </Typography>
              </Fade>
            </Container>
          </Box>
          <div className="row">
            <div className="col s12">
              <img className="responsive-img" src={searchGIF} alt="Searching" />
            </div>
          </div>
        </Container>
      </React.Fragment>
    );
  }
}

export default ProcessingScreen;
