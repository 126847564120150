import { Box, LinearProgress, Typography } from '@mui/material';
import { useState, useEffect } from 'react';

export const LinearProgressWithLabel = ({
  pageNumber,
  totalPages,
}: {
  pageNumber: number;
  totalPages: number;
}) => {
  const [progressPerc, setProgressPerc] = useState(0);

  useEffect(() => {
    setProgressPerc(
      Math.round(((pageNumber - 1) / (totalPages - 1)) * 100) // first page - 0, last page - 100%
    );
  }, [pageNumber, totalPages]);

  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" value={progressPerc} />
      </Box>
    </Box>
  );
};
