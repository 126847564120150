import documentsSigningConfig from "../sample-config/documentsSigningConfig.json";

import { UPSERT_FILE } from '../../api';
import { apiURL } from '../../config';
import { v4 as uuidv4 } from 'uuid';
import { Alert, AlertTitle, Button, CircularProgress, Grid, Icon, Typography } from '@mui/material';
import { FormikProps, useFormikContext } from 'formik';
import { useEffect, useState, useRef, useContext } from 'react';
import { Element } from '../Element';
import { TaskStatuses } from '../../api/types';
import { SectionType } from '../types';
import { Constants } from "../../constants";
import { Document, Page, pdfjs } from 'react-pdf';
import highlightCover from '../../assets/images/highlightCover.png';
import axios from 'axios';
import { useEffectDebugger } from '../../helpers/customHooks';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import { CommonDataContext } from "../../context";
import { getAvailableToSignPersonsFieldOptions, getOwnerFullNameByWhoIsPresentToSignKey } from "../../helpers";
import _ from 'lodash';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

/* TODO TO Improve
  * Addendum question not hardcoded
  *
*/

const DocumentsSigningSection = ({
  config,
  locale,
  externalData,
}: {
  config: SectionType;
  locale: string;
  externalData: any;
}) => {
  const { containerMaxWidth, setContainerMaxWidth, isMobileView, accountTasksTemplates } = useContext(CommonDataContext);
  const formik: FormikProps<any> = useFormikContext();
  const formikDocsUpload = _.get(formik, `values.docsUpload`) || [{ id: uuidv4() }];
  const requireSigning = _.get(config, `config.requireSigning`);
  const tasksAutoCreation = _.get(config, `config.tasksAutoCreation`);
  const templateId = _.get(config, `config.pdfTemplateId`);
  const [docsUpload, setDocsUpload] = useState(formikDocsUpload);
  //@ts-ignore
  const templateConfiguration = _.get(documentsSigningConfig, templateId);
  const fieldsToFillIn = _.reject(templateConfiguration.fields, 'toSkipForNow');
  _.forEach(fieldsToFillIn, (field) => {
    _.set(field, 'config.required', requireSigning);
  });
  const acceptAddendumField = _.find(config.fields, f => f.config.fieldName === 'addendum');
  const whoIsPresentToSignField = _.find(config.fields, f => f.config.fieldName === 'whoIsPresentToSignDocument');
  const useMultipleSigning = !!whoIsPresentToSignField;

  const pdfContainerRef = useRef<HTMLInputElement>(null);
  const [currentFieldIdx, setCurrentFieldIdx] = useState(0);
  const [currentSignorIdx, setCurrentSignorIdx] = useState(0);

  const [isSigningAccepted, setIsSigningAccepted] = useState(_.get(formik, `values.addendum`) === 'accept');
  const [isAddendumSigned, setIsAddendumSigned] = useState(!!_.get(formik, `values.isAddendumSigned`));

  const [isJustFinishedSigning, setIsJustFinishedSigning] = useState(false);
  const initialPayload = {
    date: '',
  };
  const [isPDFLoading, setIsPDFLoading] = useState(false);
  const [isFileUploadLoading, setIsFileUploadLoading] = useState(false);
  const [currentPDFPayload, setCurrentPDFPayload] = useState<any>({ ...initialPayload });
  const [pdfContainerWidth, setPDFContainerWidth] = useState(535);
  const [pdfData, setPdfData] = useState();
  const [currentSignorFullName, setCurrentSignorFullName] = useState<string | undefined>();
  const [sendDocumentsSignaturesTasks, setSendDocumentsSignaturesTasks] = useState(_.get(formik, `values.sendDocumentsSignaturesTasks`, {}));

  const isWhoIsPresentToSignDocumentConfirmed = _.get(formik, `values.isWhoIsPresentToSignDocumentConfirmed`);
  console.log(isWhoIsPresentToSignDocumentConfirmed);

  const sectionHeader = _.get(config, `title.config.displayLabel.${locale}`, '');

  //@ts-ignore
  const onDocumentLoadSuccess = ({ numPages }) => {
    //setNumPages(numPages);
  };


  const getPDFPayloadData = (isFinished: boolean = false) => {
    const currentField = fieldsToFillIn[currentFieldIdx];
    const nextField = isFinished ? null : fieldsToFillIn[currentFieldIdx + 1];

    let newData = {
      ...(currentField.fontSettings)
        ? { [currentField.config.fieldName]: { ...currentField.fontSettings, value: _.get(formik, `values.${currentField.config.fieldName}`, '') } }
        : { [currentField.config.fieldName]: _.get(formik, `values.${currentField.config.fieldName}`, '') },
      [currentField.highlightProp]: null,
      ...(nextField) && { [nextField.highlightProp]: highlightCover },
      date: Date.now(),
    };

    if (currentField.fieldToDuplicateValue) {
      newData = {
        ...newData,
        [currentField.fieldToDuplicateValue]: _.get(formik, `values.${currentField.config.fieldName}`, '')
      }
    }

    const newPayload = {
      ...currentPDFPayload,
      ...newData
    };
    return newPayload;
  };

  const downloadPDFHandler = async () => {
    try {
      const downloadPDFFromResponse = (response: any) => {
        const fileURL = URL.createObjectURL(response.data);
        const link = document.createElement('a');
        link.href = fileURL;
        link.setAttribute('target', '_blank');
        link.setAttribute('download', `addendum_${Date.now()}.pdf`);
        document.body.appendChild(link);
        link.click();
      };
      const response = await requestPDF(initialPayload);
      downloadPDFFromResponse(response);
    } catch (error) {
      console.log(error);
    }
  };

  const requestPDF = async (payloadData: any) => {
    try {
      setIsPDFLoading(true);
      const payload = {
        title: 'Addendum',
        fontSize: 10,
        textColor: '#000000',
        data: payloadData
      };
      return await axios.post(`${process.env.REACT_APP_DCR_API_BASE_SERVER_URL}/create-pdf-to-sign?templateId=${templateId}`,
        payload,
        {
          responseType: "blob",
        })
        .catch(error => console.log(error));
    } catch (error) {
      console.log(error);
    } finally {
      setIsPDFLoading(false);
    }
  };

  // TODO: reload only if payloadData changed
  const requestPDFToRender = async (payloadData: any) => {
    try {
      const response = await requestPDF(payloadData);
      if (response && response.data) {
        const pdfData = response.data;
        setPdfData(pdfData);
        return pdfData;
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setContainerMaxWidth(false);  // need to make screen wider
    const currentPDFPayloadToSet = getPDFPayloadData(isAddendumSigned);
    setCurrentPDFPayload(currentPDFPayloadToSet);
    requestPDFToRender(currentPDFPayloadToSet);
    return () => {
      setContainerMaxWidth(Constants.DefaultContainerMaxWidth);
      formik.setFieldError('isAddendumSigned', '');
    };
  }, []);

  useEffect(() => {
    if (containerMaxWidth === false) {
      if (pdfContainerRef.current && pdfContainerRef.current.offsetWidth) {
        setPDFContainerWidth(pdfContainerRef.current.offsetWidth);
      }
    }
  }, [containerMaxWidth]);

  const resetFlow = async (isSigningAccepted: boolean, isFullReset: boolean) => {
    _.forEach(fieldsToFillIn, field => {
      formik.setFieldValue(field.config.fieldName, '', false);
    });
    setCurrentFieldIdx(0);
    setIsJustFinishedSigning(false);
    setIsAddendumSigned(false);
    const currentPDFPayloadToSet = isSigningAccepted
      ? { ...initialPayload, [fieldsToFillIn[0].highlightProp]: highlightCover }
      : { ...initialPayload };
    setCurrentPDFPayload(currentPDFPayloadToSet);
    await requestPDFToRender(currentPDFPayloadToSet);
    if (isFullReset) {
      setDocsUpload(docsUpload.filter((d: any) => d.docsUpload !== 'Addendum'));
    }
  };

  useEffectDebugger((changedDeps: any) => {
    const isSigningAccepted = formik.values.addendum === 'accept';
    setIsSigningAccepted(isSigningAccepted);

    const valueBefore = _.get(changedDeps, 'addendum.before');
    const valueAfter = _.get(changedDeps, 'addendum.after');
    if (!!valueBefore && valueBefore !== valueAfter) {
      resetFlow(isSigningAccepted, true);
    }
  }, [_.get(formik, `values.addendum`)], ['addendum']);


  useEffect(() => {
    if (isSigningAccepted && !isAddendumSigned && requireSigning) {
      formik.setFieldError('isAddendumSigned', 'required');
    } else {
      formik.setFieldError('isAddendumSigned', '');
    }
  }, [isSigningAccepted, isAddendumSigned]);


  const continueHandler = async () => {
    const currentPDFPayloadToSet = getPDFPayloadData();
    setCurrentPDFPayload(currentPDFPayloadToSet);
    await requestPDFToRender(currentPDFPayloadToSet);
    //setCurrentField(fieldsToFillIn[currentFieldIdx + 1]);
    setCurrentFieldIdx(currentFieldIdx + 1);
  };

  const finishHandler = async () => {
    const currentPDFPayloadToSet = getPDFPayloadData(true);
    setCurrentPDFPayload(currentPDFPayloadToSet);
    const pdfData = await requestPDFToRender(currentPDFPayloadToSet);
    await uploadFile(pdfData);
    setIsAddendumSigned(true);

    if (useMultipleSigning) {
      const isLastSignor = currentSignorIdx === _.size(_.get(formik, `values.${whoIsPresentToSignField.config.fieldName}`)) - 1;
      handleSendDocumentsSignaturesTasks(isLastSignor);
      setAddendumDataForSignor();
      if (!isLastSignor) {
        resetFlow(false, false);
        setCurrentSignorIdx(prevIdx => prevIdx + 1);
      }
    }
  };

  useEffect(() => {
    formik.setFieldValue(`docsUpload`, docsUpload, false);
  }, [docsUpload]);

  useEffect(() => {
    formik.setFieldValue(`isAddendumSigned`, isAddendumSigned, false);
  }, [isAddendumSigned]);

  useEffect(() => {
    if (!useMultipleSigning || !isWhoIsPresentToSignDocumentConfirmed) {
      return;
    }
    const whoIsPresentToSignKey = getCurrentWhoIsPresentToSignKey();
    setCurrentSignorFullName(getOwnerFullNameByWhoIsPresentToSignKey(whoIsPresentToSignKey, formik));
  }, [currentSignorIdx, isWhoIsPresentToSignDocumentConfirmed]);


  useEffect(() => {
    formik.setFieldValue(`sendDocumentsSignaturesTasks`, sendDocumentsSignaturesTasks);
  }, [sendDocumentsSignaturesTasks]);

  const uploadFile = async (pdfData: any) => {
    if (!pdfData) {
      return;
    }
    const fileOfBlob = new File([pdfData], `CatFi Addendum.pdf`);
    const formData = new FormData();
    const fileStringMap = `{"0": ["variables.files.0"]}`;
    formData.append(
      'operations',
      JSON.stringify({
        query: UPSERT_FILE,
        variables: {
          files: [null],
        },
      })
    );
    formData.append('map', fileStringMap);
    formData.append('0', fileOfBlob);
    setIsFileUploadLoading(true);
    return new Promise(async (resolve, reject) => {
      fetch(apiURL, {
        method: 'POST',
        body: formData,
      })
        .then(response => response.json())
        .then(response => {
          const upsertedFile = _.get(response, 'data.upsertFile[0]');
          const uploadedDocument = {
            id: uuidv4(),
            fileToUpload: upsertedFile,
            docsUpload: 'Addendum',
            docDescription: _.get(formik, 'values.authorizedSignature'),
            /* , file: { name: 'Signed_Addendum' } */
          };

          setDocsUpload((docsUpload: any) => {
            return [
              ...docsUpload,
              uploadedDocument
            ];
          });
          if (externalData?.setUploadedSignedDocument) {
            externalData.setUploadedSignedDocument(uploadedDocument);
          }
          resolve(true);
        })
        .finally(() => {
          setIsFileUploadLoading(false);
        });
    });
  };

  const getCurrentWhoIsPresentToSignKey = () => {
    const whoIsPresentToSignValue = _.get(formik, `values.${whoIsPresentToSignField?.config.fieldName}`);
    return whoIsPresentToSignValue[currentSignorIdx];
  };

  const setAddendumDataForSignor = () => {
    const whoIsPresentToSignKey = getCurrentWhoIsPresentToSignKey();
    const formikValuesKey = whoIsPresentToSignKey === Constants.WhoIsPresentToSignPOCKey
      ? ``
      : `owners[${whoIsPresentToSignKey}].`;
    formik.setFieldValue(`${formikValuesKey}addendum`, 'accept');
    formik.setFieldValue(`${formikValuesKey}isAddendumSigned`, true);
  };

  const handleSendDocumentsSignaturesTasks = (isLastSignor: boolean) => {
    if (!tasksAutoCreation) {
      return;
    }
    const whoIsPresentToSignKey = getCurrentWhoIsPresentToSignKey();
    setSendDocumentsSignaturesTasks((prev: any) => ({ ...prev, [whoIsPresentToSignKey]: TaskStatuses.Complete }));
    if (isLastSignor) {
      const isIndividualApp = _.get(externalData, 'isIndividualApp');
      const whoIsPresentToSignAvailableKeys = _.map(getAvailableToSignPersonsFieldOptions(formik, isIndividualApp), 'optionValue');
      const nonSignors = _.differenceBy(whoIsPresentToSignAvailableKeys, _.get(formik, `values.${whoIsPresentToSignField?.config.fieldName}`));
      const nonSignorsConfig = _.reduce(nonSignors, (result, key) => ({ ...result, [key]: TaskStatuses.Open }), {});
      setSendDocumentsSignaturesTasks((prev: any) => ({ ...prev, ...nonSignorsConfig }));
    }
  };

  const handleWhoIsPresentToSignConfirm = () => {
    formik.setFieldValue('isWhoIsPresentToSignDocumentConfirmed', true);
  };

  const getDownloadPDFButtonLayoyt = () => {
    return (
      <Grid item container xs={12} justifyContent={'flex-end'}>
        <Button
          variant="contained"
          size="small"
          color="primary"
          onClick={downloadPDFHandler}
          endIcon={<Icon>download</Icon>}
        >
          Download PDF
        </Button>
      </Grid>
    );
  };

  const getInputsWizardLayoyt = () => {
    return (
      <>
        {fieldsToFillIn.map((field: any, index: number) => (
          (index === currentFieldIdx) && <Element
            item={field}
            key={field.config.fieldName}
            locale={locale}
          />
        ))}
        <Grid item xs={6}>
          {currentFieldIdx !== 0 && <Button
            variant="outlined"
            color="primary"
            disabled={isPDFLoading || isFileUploadLoading}
            onClick={() => {
              setCurrentFieldIdx(currentFieldIdx - 1);
            }}
          >
            Back
          </Button>
          }
        </Grid>
        <Grid item container xs={6} justifyContent="flex-end">
          <Button
            variant="contained"
            color="primary"
            disabled={isPDFLoading || isFileUploadLoading || !!formik.errors[fieldsToFillIn[currentFieldIdx]?.config.fieldName]}
            onClick={() => {
              currentFieldIdx === (fieldsToFillIn.length - 1) ? finishHandler() : continueHandler();
            }}
          >
            {currentFieldIdx === (fieldsToFillIn.length - 1) ? (<span>Finish</span>) : (<span>Continue</span>)}
          </Button>
        </Grid>
      </>
    );
  };

  const getWhoIsPresentToSignLayoyt = () => {
    return (
      <>
        <Element
          item={whoIsPresentToSignField}
          locale={locale}
          externalData={externalData}
        />
        <Grid item container xs={12} justifyContent="flex-end">
          <Button
            variant="contained"
            color="primary"
            disabled={_.isEmpty(_.get(formik, `values.${whoIsPresentToSignField?.config.fieldName}`))}
            onClick={handleWhoIsPresentToSignConfirm}
          >
            Confirm
          </Button>
        </Grid>
      </>
    );
  };

  const isNeedToRenderWhoIsPresentToSign = useMultipleSigning
    ? (!isWhoIsPresentToSignDocumentConfirmed && !isAddendumSigned)
    : false;

  const isNeedToRenderAcceptAddendumField = useMultipleSigning
    ? (isWhoIsPresentToSignDocumentConfirmed && !isAddendumSigned)
    : true;

  const isNeedToRenderInputsWizard = useMultipleSigning
    ? (isWhoIsPresentToSignDocumentConfirmed && isSigningAccepted && !isAddendumSigned)
    : (isSigningAccepted && !isAddendumSigned);

  const isNeedToRenderSigningForLabel = useMultipleSigning && isWhoIsPresentToSignDocumentConfirmed && currentSignorFullName && !isAddendumSigned;
  return (
    <Grid container item xs={12} spacing={2}>
      <Grid item xs={12}>
        <h3 style={config.title.config}>
          {sectionHeader}
        </h3>
      </Grid>
      <Grid item container md={4} sm={12} spacing={2} style={{ alignSelf: 'flex-start' }}>

        {isNeedToRenderWhoIsPresentToSign && (
          getWhoIsPresentToSignLayoyt()
        )}

        {isNeedToRenderSigningForLabel &&
          <Typography style={{ paddingLeft: 16, marginBottom: 20 }}>
            Signing For <strong>{currentSignorFullName}</strong>
          </Typography>}

        {isNeedToRenderAcceptAddendumField && <Element
          item={acceptAddendumField}
          locale={locale}
          externalData={externalData}
        />}

        {isNeedToRenderInputsWizard && (
          getInputsWizardLayoyt()
        )}

        {isAddendumSigned && (
          <Grid item xs={12}>
            <Alert severity="success">
              <AlertTitle>Success</AlertTitle>
              You have successfully completed the signing process!
            </Alert>
          </Grid>
        )}
      </Grid>
      <Grid item container md={8} sm={12} spacing={2} ref={pdfContainerRef} style={{ minHeight: '450px' }}>
        {isPDFLoading && <CircularProgress style={{ margin: '60px auto' }} />}
        {!isPDFLoading && pdfData && (
          <>
            {!isMobileView && getDownloadPDFButtonLayoyt()}
            <Grid item container xs={12}>
              <Document
                file={pdfData}
                onLoadSuccess={onDocumentLoadSuccess}

              >
                <Page pageNumber={1} renderTextLayer={false} scale={1/* .5 */} width={pdfContainerWidth} />
              </Document>
            </Grid>
            {isMobileView && getDownloadPDFButtonLayoyt()}
          </>
        )}
      </Grid>
    </Grid>
  );
};

export { DocumentsSigningSection };
