import { useMutation } from '@apollo/react-hooks';
import { Alert, Backdrop, Button, CircularProgress, Container, Fab, Grid, Tooltip, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useEffect, useState } from 'react';
import { TaskStatuses } from '../../../api/types';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import {
  UPDATE_TASK_STATUS,
  UPSERT_DOCUMENT,
  REMOVE_DOCUMENT_TASK_ITEM,
  REMOVE_ALL_DOCUMENT_TASK_ITEM,
} from '../../../api';
import { TaskItem } from "@trnsact/trnsact-shared-types";
import { apiURL } from "../../../config";
import _ from 'lodash';


const useStyles = makeStyles(() => {
  return {
    containerRoot: {
      backgroundColor: 'white',
      marginTop: "20px",
      marginBottom: "20px",
    },
    backdrop: {
      zIndex: 999,
      color: "#fff",
    },
    highlightDocType: {
      fontWeight: "bolder",
      textTransform: "capitalize",
    },
    uploadMoreLink: {
      color: "blue",
      textDecoration: "underline",
      display: "block",
      textAlign: "center",
      cursor: "pointer",
      margin: "5px 0 0 0",
    },
    fileNameColumn: {
      minWidth: "150px",
    },
    fileCheckIconBox: {
      top: "5px",
      position: "relative",
    },
    removeFabLabel: {
      fontSize: 10,
    },
  };
});


export const ApplicantsTaskPage = ({
  task,
  publicAccount,
  activeTemplate,
  refetchTask,
}: {
  task: any;
  publicAccount: any;
  activeTemplate: any;
  refetchTask: any;
}) => {

  const [updateTaskStatus] = useMutation(UPDATE_TASK_STATUS, {
    /*     onCompleted() {
          setIsTaskCompleted(true);
        },
        onError() {
          setIsTaskCompleted(false);
        }, */
  });
  const [removeDocumentTaskItem, { data: removedDocumentTaskItem }] = useMutation(REMOVE_DOCUMENT_TASK_ITEM);
  const [removeAllDocumentTaskItem, { data: removedAllDocumentTaskItem }] = useMutation(REMOVE_ALL_DOCUMENT_TASK_ITEM);

  const classes = useStyles();


  const [fileList, setFileList] = useState([]);
  const [loadingScreen, setLoadingScreen] = useState(false);
  const [buttonDisplayPartialSubmissionLabel, setButtonDisplayPartialSubmissionLabel] = useState(false);
  const [buttonDisplayCompleteSubmissionLabel, setButtonDisplayCompleteSubmissionLabel] = useState(false);
  const [isTaskCompleted, setIsTaskCompleted] = useState(task.status === TaskStatuses.Complete);


  useEffect(() => {
    setButtonDisplayPartialSubmissionLabel(false);
    setButtonDisplayCompleteSubmissionLabel(false);
    let fileListByDocuments: any = [];
    task.taskItems?.map((taskItem: TaskItem) => {
      if (_.size(taskItem.documents)) {
        setButtonDisplayPartialSubmissionLabel(true);
      }
      fileListByDocuments.push({
        target: taskItem.documents?.map((doc: any) => {
          return { name: doc.docName, id: doc.documentId };
        }),
        taskItemId: taskItem.taskItemId,
      });
    });
    setFileList(fileListByDocuments);

    const taskCanBeCompleted = _.every(_.get(task, "taskItems", []), ti => {
      return _.size(ti.documents) > 0;
    });

    setButtonDisplayCompleteSubmissionLabel(taskCanBeCompleted || isTaskCompleted);
  }, [task]);


  useEffect(() => {
    setIsTaskCompleted(false);
    if (task.status === "complete") {
      setIsTaskCompleted(true);
      return;
    }

    let status = "in_progress";

    if (buttonDisplayCompleteSubmissionLabel) {
      status = "complete";
    }

    if (task.status === status) {
      return;
    }

    updateTaskStatus({
      variables: {
        taskId: task.taskId,
        status,
      },
    });
    console.log(`INFO: task has been marked as ${status}`);
  }, [task, buttonDisplayCompleteSubmissionLabel]);


  const asyncForEach = async (array: any, callback: any) => {
    for (let index = 0; index < array.length; index++) {
      await callback(array[index], index, array);
    }
  };

  const uploadFiles = async (targets: any, taskItemId: string) => {
    try {
      const taskItem = _.find(task.taskItems, { taskItemId });
      const vendorOpportunityId = task.vendorOpportunityId;
      const dynamicsVendorOpportunityId = _.get(task, "vendorOpportunity.dynamicsVendorOpportunityId", null);
      const fileType = taskItem.docType;
      await asyncForEach(targets, async (file: any) => {
        const formData = new FormData();
        formData.append(
          "operations",
          JSON.stringify({
            query: UPSERT_DOCUMENT,
            variables: {
              input: {
                vendorOpportunityId,
                source: "Task Documents Page",
                createdBy: "Applicant",
                docDescription: task.assignedToUser,
                docType: fileType,
                docName: fileType,
                dynamicsVendorOpportunityId,
                taskItemId,
              },
              file,
            },
          })
        );
        if (file) {
          formData.append(
            "map",
            JSON.stringify({
              0: ["variables.file"],
            })
          );
          formData.append("0", file);
        }

        const response = await fetch(apiURL, {
          method: "POST",
          body: formData,
        });
        const documentId = _.get(await response.json(), "data.upsertDocument.document");
      });
    } catch (error) {
      //@ts-ignore
      window.NREUM.noticeError(error);
    }
  };


  function uploadButtonHandler(id: string) {
    const file: any = document.getElementById(id);
    file.value = "";
    file.click();
  }

  const uploadFileChanged = async (event: any) => {
    setLoadingScreen(true);
    const taskItemId = _.get(event, "target.id", null);
    const target = _.get(event, "target.files", null);
    await uploadFiles(target, taskItemId);
    refetchTask();
    setLoadingScreen(false);
  };

  const handleReplaceFromFileList = async (taskItemId: string, documentId: string) => {
    setLoadingScreen(true);
    //First upload the new document
    await uploadButtonHandler(taskItemId);

    const result = await removeDocumentTaskItem({
      variables: {
        taskItemId,
        documentId,
      },
    });

    refetchTask();
    setLoadingScreen(false);
  };


  return (
    <>
      <Container
        maxWidth="lg"
        className={classes.containerRoot}
      >
        <Backdrop className={classes.backdrop} open={loadingScreen}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <Grid container spacing={3}>

          <Grid item xs={12}>
            <Typography variant="h6">
              Hi <b>{task.assignedToUser}</b>, <b>{task.assignedByUser}</b> required the following items:
            </Typography>
          </Grid>

          {task.taskItems?.map((taskItem: TaskItem, index: number) => (
            <Grid item xl={12} lg={12} md={12} sm={12} key={`itemList${index}`}>
              <Grid container spacing={3}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Typography variant="h6">
                    {index + 1}) <span className={classes.highlightDocType}>{taskItem.docType}</span>
                  </Typography>
                </Grid>
                {taskItem.taskAssignorNotes ? (
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <u>Assignor Notes</u>: {taskItem.taskAssignorNotes}
                  </Grid>
                ) : null}
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Grid container spacing={3}>
                    <input id={`${taskItem.taskItemId}`} multiple type="file" style={{ display: "none" }} onChange={uploadFileChanged} />
                    <Grid item lg={4} md={5} sm={5} xs={5}>
                      <Grid container spacing={3}>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <Tooltip title="You can select multiple files" arrow>
                            <Button
                              startIcon={<CloudUploadIcon />}
                              className="formBtn"
                              color="primary"
                              fullWidth
                              variant="outlined"
                              disabled={!_.isEmpty(_.get(_.find(fileList, { taskItemId: taskItem.taskItemId }), "target", []))}
                              onClick={e => {
                                e.preventDefault();
                                uploadButtonHandler(taskItem.taskItemId || "");
                              }}>
                              UPLOAD
                            </Button>
                          </Tooltip>
                          {_.size(_.get(_.find(fileList, { taskItemId: taskItem.taskItemId }), "target", [])) ? (
                            <span
                              onClick={e => {
                                e.preventDefault();
                                uploadButtonHandler(taskItem.taskItemId || "");
                              }}
                              className={classes.uploadMoreLink}>
                              Upload more
                            </span>
                          ) : null}
                        </Grid>
                        {/* 
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            {!_.isEmpty(_.get(_.find(fileList, { taskItemId: taskItem.taskItemId }), "target", [])) ? (
                              <Button
                                onClick={() => {
                                  handleRemoveAll(taskItem.taskItemId);
                                }}
                                variant="outlined"
                                color="secondary"
                                fullWidth
                                size="small"
                                startIcon={<DeleteForeverIcon />}>
                                REMOVE ALL FILES ({_.size(_.get(_.find(fileList, { taskItemId: taskItem.taskItemId }), "target", []))})
                              </Button>
                            ) : null}
                          </Grid> */}
                      </Grid>
                    </Grid>
                    <Grid item lg={8} md={7} sm={7} xs={7}>
                      {_.get(_.find(fileList, { taskItemId: taskItem.taskItemId }), "target", false) ? (
                        <Grid container spacing={1}>
                          {Object.values(_.get(_.find(fileList, { taskItemId: taskItem.taskItemId }), "target") || {}).map((fileObject: any, idx) => (
                            <Grid item lg={12} md={12} sm={12} xs={12} key={`${taskItem.taskItemId}_fileManager_${idx}`}>
                              <Grid container spacing={1}>
                                <Grid item className={classes.fileNameColumn}>
                                  <Grid container alignItems="flex-end">
                                    <Grid item>
                                      <CheckCircleIcon fontSize="small" color={"primary"} className={classes.fileCheckIconBox} />
                                    </Grid>
                                    <Grid item> {fileObject.name}</Grid>
                                  </Grid>
                                </Grid>
                                <Grid item>
                                  <Fab
                                    color="secondary"
                                    className={classes.removeFabLabel}
                                    variant="extended"
                                    size="small"
                                    onClick={() => handleReplaceFromFileList(taskItem.taskItemId || "", fileObject.id)}>
                                    <RemoveCircleIcon fontSize="small" />
                                    Replace
                                  </Fab>
                                </Grid>
                              </Grid>
                            </Grid>
                          ))}
                          <hr />
                        </Grid>
                      ) : null}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <br />
            </Grid>
          ))}

          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            {buttonDisplayCompleteSubmissionLabel || isTaskCompleted ? (
              <Alert severity="success">All set! You have completed all tasks!</Alert>
            ) : buttonDisplayPartialSubmissionLabel ? (
              <Alert severity="info">One or more documents have been uploaded</Alert>
            ) : null}
          </Grid>
        </Grid>
      </Container>
    </>
  );
};
