import { Box, Button, CircularProgress, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FormikProps, useFormikContext } from 'formik';
import _ from 'lodash';
import React, { SyntheticEvent, useContext, useEffect, useState } from 'react';
import { FieldType } from '../types';
import { UPSERT_FILE } from '../../api';
import { apiURL } from '../../config';
import { CommonDataContext } from '../../context';

const useStyles = makeStyles(() => ({
  chooseFileButton: {
    minWidth: '200px !important',
    padding: '5px !important',
    border: '1px solid #a13939 !important',
    color: '#a13939 !important',
  },
  noImageProvided: {
    color: '#fd3b3b',
    marginTop: '6px',
  },
}));

export const ImageInput = ({
  itemData,
  value,
  locale,
}: {
  itemData: FieldType;
  value: any;
  locale: string;
}) => {
  const { id: fieldId, config: fieldConfig } = itemData;
  const formik: FormikProps<any> = useFormikContext();
  const { isMobileView, highlightErrorFieldsState } = useContext(CommonDataContext);
  const classes = useStyles();
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileUploaded, setFileUploaded] = useState(false);
  const [uploadingFile, setUploadingFile] = useState(false)
  const [isInvalid, setIsInvalid] = useState(false);

  const uploadButtonHandler = (elementId: string) => {
    const file = document.getElementById(fieldConfig.fieldName);
    if (!!file) {
      file.click();
    }
  };

  const _resetField = (): void => {
    // Check if error is already set to ''
    if (Boolean(_.get(formik.errors, itemData.config.fieldName))) {
      //Remove Error
      formik.setFieldError(itemData.config.fieldName, '');
    }
    if (_.get(formik, itemData.config.fieldName)) { // do not set '' if the value has not been set before
      //Erase value
      formik.setFieldValue(itemData.config.fieldName, '');
    }
    setSelectedFile(null);
    setIsInvalid(false);
  };

  const uploadFileChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileToUpload: any = _.get(event, 'currentTarget.files', false);
    if (!_.isEmpty(fileToUpload)) {
      setUploadingFile(true)
      setSelectedFile(fileToUpload[0] as any);
      formik.setFieldValue(
        `${itemData.config.fieldName}.name`,
        _.get(fileToUpload, '[0].name', ''),
        false
      );

      const formData = new FormData();

      if (fileToUpload && fileToUpload.length > 0) {
        let fileStringMap = '{';
        let arrOfFiles = [];
        for (let i = 0; i < fileToUpload.length; i++) {
          if (i !== fileToUpload.length - 1) {
            fileStringMap += `"${JSON.stringify(
              i
            )}": ["variables.files.${JSON.stringify(i)}"],`;
          } else {
            fileStringMap += `"${JSON.stringify(
              i
            )}": ["variables.files.${JSON.stringify(i)}"]}`;
          }
          arrOfFiles.push(null);
        }

        formData.append(
          'operations',
          JSON.stringify({
            query: UPSERT_FILE,
            variables: {
              files: arrOfFiles,
            },
          })
        );
        formData.append('map', fileStringMap);
        for (let i = 0; i < fileToUpload.length; i++) {
          formData.append(JSON.stringify(i), fileToUpload[i]);
        }
      }
      return new Promise(async (resolve, reject) => {
        fetch(apiURL, {
          method: 'POST',
          body: formData,
        })
          .then((response) => response.json())
          .then((response) => {
            if (response && response.data && response.data.upsertFile) {
              try {
                formik.setFieldValue(
                  `${itemData.config.fieldName}.docsUpload`,
                  { ...response.data.upsertFile[0], ...fileToUpload[0] },
                  false
                );
                setFileUploaded(true)
              } catch (error) {
                console.log(`Error creating object for Image Input component`);
                formik.setFieldValue(
                  `${itemData.config.fieldName}.docsUpload`,
                  null,
                  false
                );
              }
              setIsInvalid(false);
            }
          })
          .catch((err) => {
            console.log(err);
            setIsInvalid(true);
          })
          .finally(() => {
            setUploadingFile(false);
          });
      });
    } else {
      setUploadingFile(false)
      setIsInvalid(true);
    }
  };

  useEffect(() => {
    return () => {
      setSelectedFile(null);
      setIsInvalid(false);
      setUploadingFile(false);
      setFileUploaded(false);
      formik.setFieldError(itemData.config.fieldName, '');
    };
  }, []);

  useEffect(() => {
    //When there is no need to display the field, we reset it's state to a clean one
    if (!itemData.config.displayed) {
      _resetField();
    } else {
      let isEmpty = false;

      if (itemData.config.required) {
        isEmpty = _.isEmpty(value);
      }

      formik.setFieldError(
        itemData.config.fieldName,
        isInvalid || isEmpty || uploadingFile ? 'required' : ''
      );
    }
  }, [itemData.config.displayed, itemData.config.required, isInvalid, uploadingFile, value]);

  useEffect(() => {
    if (highlightErrorFieldsState.state) {
      setIsInvalid(!!formik.getFieldMeta(itemData.config.fieldName).error);
    }
  }, [highlightErrorFieldsState]);

  return (
    <Box
      component={Grid}
      item
      xl={_.get(fieldConfig, 'columns.xl', 12)}
      lg={_.get(fieldConfig, 'columns.lg', 12)}
      md={_.get(fieldConfig, 'columns.md', 12)}
      sm={_.get(fieldConfig, 'columns.sm', 12)}
      xs={_.get(fieldConfig, 'columns.xs', 12)}
      display={{
        xl: !itemData.config.displayed ? 'none' : 'block',
        lg: !itemData.config.displayed ? 'none' : 'block',
        md: !itemData.config.displayed ? 'none' : 'block',
        sm: !itemData.config.displayed ? 'none' : 'block',
        xs: !itemData.config.displayed ? 'none' : 'block',
      }}
    >
      <div key={fieldId}>
        <Grid container spacing={2}>
          {!!selectedFile && fileUploaded && (
            <img
              src={URL.createObjectURL(selectedFile)}
              style={{
                margin: '15px 15px 15px 0',
                maxWidth: '100%',
                maxHeight: '190px',
                display: 'block',
              }}
            />
          )}
          <input
            accept="image/*"
            id={`${fieldConfig.fieldName}`}
            type="file"
            required={fieldConfig.required}
            style={{ display: 'none' }}
            onChange={uploadFileChanged}
            name={fieldConfig.fieldName}
          />
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            {!uploadingFile ? (
              <Button
                variant="outlined"
                className={classes.chooseFileButton}
                onClick={(e: SyntheticEvent) => {
                  e.preventDefault();
                  uploadButtonHandler(fieldConfig.fieldName);
                }}
              >
                {_.get(fieldConfig, `displayLabel.${locale}`, '')}
              </Button>
            ) : (
              <CircularProgress />
            )}
          </Grid>
          <Grid
            item
            xl={12}
            lg={12}
            md={12}
            sm={12}
            xs={12}
            className={classes.noImageProvided}
          >
            {isInvalid && !uploadingFile ? 'Please provide a valid image' : null}
          </Grid>
        </Grid>
      </div>
    </Box>
  );
};
