import React, { useContext, useState } from "react";
import { gql, useMutation } from "@apollo/react-hooks";
import { Grid, MenuItem, Select, Typography } from "@mui/material";

import MenuOption from "./MenuOption";
import MenuOptionConfirmationModal from "./MenuOptionConfirmationModal";
import { Proposal, ProposalMenuOption } from "@trnsact/trnsact-shared-types";
import _ from "lodash";
import { getURLParameter } from "../../../helpers";


const updateProposal = gql`
  mutation ($dynamicsId: ID, $id: ID, $dynamicsObj: updateProposalCRMFields, $fieldsObj: updateProposalFields) {
    updateProposal(dynamicsId: $dynamicsId, id: $id, dynamicsObj: $dynamicsObj, fieldsObj: $fieldsObj)
  }
`;

interface Props {
  lenderOption: any,
  proposal: Proposal,
  termSelected: number,
  setTermSelected: any
};

const MenuOptions = ({ lenderOption, proposal, setTermSelected, termSelected }: Props) => {


  const [isMenuOptionConfirmationModalOpen, setIsMenuOptionConfirmationModalOpen] = useState<boolean>(false);
  const [selectedMenuOption, setSelectedMenuOption] = useState<any/* ProposalMenuOption */>();

  const [postPaymentSelection, { loading }] = useMutation(updateProposal, {
    onCompleted: () => {
      //setFormSubmit(true);
    },
  });

  const handleCloseMenuOptionConfirmationModal = () => {
    setIsMenuOptionConfirmationModalOpen(false);
    setSelectedMenuOption(null);
  };

  const handleSubmitMenuOptionConfirmationModal = async (menuOption: any) => {
    const proposalId = getURLParameter("proposalId");
    const vendorGUID = getURLParameter("vendorGUID");

    if (!proposalId) {
      return;
    }

    const { paymentCriteria } = lenderOption;
    const monthlyPayment = menuOption.payment.paymentAmountPerPeriod;
    const paymentSelectionObj = {
      monthlyPayment: isNaN(monthlyPayment) ? parseFloat(monthlyPayment.replace(/,/g, "")) : monthlyPayment,
      dcrStage: "Accepted",
      status: "Accepted",
      paymentId: paymentCriteria.paymentId,
      termLength: paymentCriteria.term?.toString(),
      fastTrackStatusId: 220,
      paymentCriteriaSelected: {
        menuOption: {
          name: menuOption.name,
          payment: {
            paymentAmountPerPeriod: menuOption.payment.paymentAmountPerPeriod,
          },
          /*           packages: [
                      {
                        products: [
                          {
          
                          }
                        ]
                      }
                    ] */
        }
      }
    };

    try {
      await postPaymentSelection({
        variables: {
          id: proposalId,
          fieldsObj: paymentSelectionObj,
        }
      });
      if (!proposal.redirect) {
        window.location.href = "/lenderConfirmed?&vendorGUID=" + vendorGUID;
        return;
      } else {
      }
    } catch (error: any) {
      console.error(error);
      return (window.location.href = "/posterror?&vendorGUID=" + vendorGUID);
    }

    setIsMenuOptionConfirmationModalOpen(false);
    setSelectedMenuOption(null);
  };

  const menuOptions = _.get(lenderOption, 'paymentCriteria.menu.menuOptions', []);
  return <>
    <Grid container style={{ marginTop: 28 }}>
      <Grid item container justifyContent="space-between" alignItems="center" style={{ marginBottom: 16 }}>
        <Typography variant="h5"><strong>Package Options for {proposal.businessName}</strong></Typography>
        <Select
          value={termSelected}
          onChange={e => setTermSelected(Number(e.target.value))}
          size="small"
        >
          {proposal?.lenderPaymentOptions?.map((option: any, i: number) => (
            <MenuItem value={i}>{option.term} Months</MenuItem>
          ))}
        </Select>
      </Grid>
      <Grid item container spacing={2} justifyContent="center" /* justifyContent={menuOptions.length < 3 ? "center" : "space-between"} */>
        {menuOptions.map((option: any, i: number) => (
          <MenuOption
            key={i}
            option={option}
            setIsMenuOptionConfirmationModalOpen={setIsMenuOptionConfirmationModalOpen}
            setSelectedMenuOption={setSelectedMenuOption}
          />
        ))}
      </Grid>
    </Grid>

    <MenuOptionConfirmationModal
      isOpen={isMenuOptionConfirmationModalOpen}
      menuOption={selectedMenuOption}
      handleClose={handleCloseMenuOptionConfirmationModal}
      handleSubmit={handleSubmitMenuOptionConfirmationModal}
    />
  </>
};

export default MenuOptions;
