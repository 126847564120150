import { useContext } from 'react';
import FooterStyles from './Footer.module.css';
import Container from '@mui/material/Container';
import { VendorContext } from '../../../context/VendorContext';
import { FormattedMessage } from 'react-intl';
import defaultMessage from '../../../context/Message';

const Footer = (props: any) => {
  const { color: vendorColor, panelTextColor } = useContext(VendorContext);
  return (
    <div
      className={FooterStyles.footer}
      style={{ backgroundColor: vendorColor }}
    >
      <Container maxWidth="lg">
        <div className={FooterStyles.footerFlex}>
          <span style={{ color: panelTextColor }}>
            © Copyright {new Date().getFullYear()} Trnsact Technologies.{' '}
            <FormattedMessage
              id="allRightsReserved"
              defaultMessage={defaultMessage.allRightsReserved}
            />
          </span>
          <br />
          <span style={{ color: panelTextColor }}>
            <a
              href="https://dcr.ai/terms-of-use"
              target="_blank"
              rel="noreferrer"
              style={{ color: panelTextColor, textDecoration: 'none' }}
            >
              <FormattedMessage
                id="termsOfUse"
                defaultMessage={defaultMessage.termsOfUse}
              />
            </a>{' '}
            |{' '}
            <a
              href="https://dcr.ai/privacy/"
              target="_blank"
              rel="noreferrer"
              style={{ color: panelTextColor, textDecoration: 'none' }}
            >
              <FormattedMessage
                id="privacyPolicy"
                defaultMessage={defaultMessage.privacyPolicy}
              />
            </a>
          </span>
        </div>
      </Container>
    </div>
  );
};

export default Footer;
