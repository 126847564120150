import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FormikProps, useFormikContext } from 'formik';
import _ from 'lodash';
import { useCallback, useEffect, useState, useContext } from 'react';
import { useSearchParams } from 'react-router-dom';
import { FieldType } from '../types';
import { VendorContext, CommonDataContext } from '../../context';
import { getURLParameter } from '../../helpers';
import DropdownTreeSelect from 'react-dropdown-tree-select';
import 'react-dropdown-tree-select/dist/styles.css';

interface TreeNode {
  value: number;
  label: string;
  className: string;
  isDefaultValue: boolean;
  children: Array<TreeNode | null>;
  expanded: boolean;
}

const useStyles = (props: any) =>
  makeStyles(() => ({
    v2LocationComponent: {
      '& > div': {
        marginTop: "8px",
      },
      '& .placeholder': {
        color: _.get(props, 'textColor', '#1473E6'),
      },
      '&.invalid .placeholder': {
        color: '#d32f2f',
      },
      '& .tag-remove': {
        display: "none",
      },
      '& .react-dropdown-tree-select .dropdown': {
        display: "block",
      },
      '& .react-dropdown-tree-select .dropdown .dropdown-trigger': {
        border: "none",
        borderBottom: "1px solid #0000006b",
        paddingLeft: "0px",
        display: "flex",
        justifyContent: "space-between",
      },
      '&.invalid .react-dropdown-tree-select .dropdown .dropdown-trigger': {
        borderBottom: "1px solid #d32f2f",
      },
      '&.mobile-view .react-dropdown-tree-select .dropdown .dropdown-trigger': {
        border: "1px solid #0000003b",
        borderRadius: "4px",
        padding: "16px 12px 16px 10px",
      },
      '&.mobile-view.invalid .react-dropdown-tree-select .dropdown .dropdown-trigger': {
        border: "1px solid #d32f2f",
      },
      '&.mobile-view .tag-item:nth-child(2)': {
        top: "-14px",
        left: "6px",
        padding: "0 5px",
        background: "white",
      },
      '&.mobile-view .node': {
        whiteSpace: "normal",
      },
      '& .react-dropdown-tree-select .dropdown .dropdown-content': {
        width: "95%",
        zIndex: 2,
      },
      '& .node': {
        display: "flex",
        alignItems: "center",
        padding: "0",
      },
      '& .node.checked': {
        backgroundColor: "#a13e3e14",
      },
      '& .node>label': {
        width: "100%",
        padding: "5px 0",
        marginLeft: "5px",
      },
      '& .node:hover': {
        backgroundColor: "#0000000a",
      },
      '& .node .node-label:after': {
        marginLeft: "10px",
        border: "1px solid lightgray",
        padding: "1px 4px",
        borderRadius: "8px",
      },
      '& .node.master .node-label:after': {
        content: `"Master"`,
      },
      '& .node.region .node-label:after': {
        content: `"Region"`,
      },
      '& .node.branch .node-label:after': {
        content: `"Branch"`,
      },
      '& .node.division .node-label:after': {
        content: `"Division"`,
      },
      '& .node.department .node-label:after': {
        content: `"Department"`,
      },
      '& .node.state .node-label:after': {
        content: `"State"`,
      },
      '& .toggle': {
        display: "flex",
        margin: "0 5px",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "22px",
      },
      '& .radio-item': {
        display: "none",
      },
      '& .tag': {
        padding: "0",
        backgroundColor: "transparent",
        border: "none",
      },
      '& .tag-item:nth-child(2)': {
        position: "absolute",
        top: "-18px",
        left: "0",
        fontSize: "12px",
      },
      '& .react-dropdown-tree-select .dropdown .dropdown-trigger.arrow.bottom:after, .react-dropdown-tree-select .dropdown .dropdown-trigger.arrow.top:after': {
        color: "#0000008a",
        display: "flex",
        alignItems: "center",
        fontSize: "12px",
      },
      '& .react-dropdown-tree-select .dropdown .dropdown-content .search': {
        padding: "7px",
        width: "97%",
      },
      // The following styles are require to support this logic: To select a parent, must click twice. To select a child only need to select once.
      '& .toggle.collapsed': {
        position: "absolute",
        width: "100%",
      },
      '& .toggle.collapsed+label:not(.toggle.collapsed[style="visibility: hidden;"]+label)': {
        pointerEvents: "none",
        marginLeft: "27px",
      },
      ///////////////////////////////////
    },
  }));

const LocationSelect = ({
  itemData,
  value,
  locale,
  externalData,
  externalCallbacks,
}: {
  itemData: FieldType;
  value: any;
  locale: string;
  externalData: any;
  externalCallbacks: any;
}) => {
  const { id: fieldId, config: fieldConfig } = itemData;
  const [searchParams, setSearchParams] = useSearchParams();
  const [locationList, setLocationList] = useState(
    _.get(externalData, 'locationsList', [])
  );
  const componentVersion: string = _.get(fieldConfig, 'componentVersion', 'v1');
  const isNeedToShowMasterLocation: boolean = _.get(fieldConfig, 'isNeedToShowMasterLocation', true);
  //const preselectedLocationId = getURLParameter('locationId'); - doesn't work
  const [preselectedLocationId, setPreselectedLocationId] = useState(getURLParameter('locationId'));
  const [preselectedLocation, setPreselectedLocation] = useState();
  const [locationIdSelected, setLocationIdSelected] = useState(null);
  const [isInvalid, setIsInvalid] = useState(false);
  const { isMobileView, highlightErrorFieldsState } = useContext(CommonDataContext);
  const formik: FormikProps<any> = useFormikContext();
  const { textColor } = useContext(VendorContext);
  const classes = useStyles({
    textColor
  })();

  const evaluateIfInvalid = (value: string): boolean => {
    const isValueSet = _.some(value);
    const isRequiredAndEmpty = !!fieldConfig.required && !isValueSet;
    return isRequiredAndEmpty;
  };

  const buildTree = (locationList: any, preselectedLocation: any) => {
    const formikSelectedValue = _.get(formik, ['values', fieldConfig.fieldName], null);
    const preselectedLocationShowOnlyChildLocations = _.get(preselectedLocation, 'ocaShowOnlyChildLocations');

    const getTreeNode = (location: any, depth: number): TreeNode | null => {

      const isNeedToHideLocation = location.ocaHidden && !preselectedLocationShowOnlyChildLocations;
      if (isNeedToHideLocation) {
        return null;
      }
      const childLocations = _(locationList)
        .filter(l => l.locationParentId === location.locationId)
        .orderBy('locationName')
        .value();
      return {
        value: location.locationId,
        label: location.locationName,
        className: _.toLower(location.category),
        isDefaultValue: formikSelectedValue === location.locationId,
        children: _.isEmpty(childLocations) ? [] : _.compact(_.map(childLocations, el => getTreeNode(el, depth + 1))),
        expanded: !_.isEmpty(childLocations) && depth === 0 ? true : false  // expand 0 level by default
      };
    }

    locationList = isNeedToShowMasterLocation
      ? locationList
      : _.reject(locationList, location => location.category === 'Master');

    const rootElements = preselectedLocationShowOnlyChildLocations
      ? [preselectedLocation]
      : _(locationList)
        .reject(location => !!_.find(locationList, { locationId: location.locationParentId }))
        .orderBy('locationName')
        .value();
    return _.compact(_.map(rootElements, el => getTreeNode(el, 0)));
  }

  const handleChange = useCallback(
    value => {
      setLocationIdSelected(value);

      formik.setFieldValue(itemData.config.fieldName, value, false);
      formik.setFieldValue('repSelectorQuestion', '', false);
      setIsInvalid(evaluateIfInvalid(value));

      if (value) {
        const { ocaContact: contactData, contactsInfo } = _.find(locationList, {
          locationId: value,
        });

        const setRepList = _.get(externalCallbacks, 'setRepList', null);
        let masterLocation = _.get(externalData, 'locationsList', []).filter(
          (location: any) => location.category === 'Master'
        )[0];
        //1. Refresh Rep Selector content
        setRepList(contactsInfo);

        //2. Reset Question state

        //3. Default to OcaContact
        const setRepData = _.get(externalCallbacks, 'setRepData', null);
        if (!!contactData) {
          setRepData({
            officePhone: contactData.phoneNumber ? contactData.phoneNumber : '',
            mugshot: contactData.mugshot ? contactData.mugshot : '',
            email: contactData.email,
            name: contactData.fullName,
            availability:
              contactData.availability || 'Monday-Friday, 8am-5pm PST',
          });
        }
        searchParams.set('locationId', value);
        setSearchParams(searchParams);
        window.history.replaceState(
          null,
          'Online Credit App',
          `${process.env.PUBLIC_URL}?${searchParams.toString()}`
        );
      }
    },
    [formik]
  );
  const _resetField = (): void => {
    // Check if error is already set to ''
    if (Boolean(_.get(formik.errors, itemData.config.fieldName))) {
      //Remove Error
      formik.setFieldError(itemData.config.fieldName, '');
    }
    if (!_.has(formik.values, itemData.config.fieldName)) { // do not set '' value for untouched fields
      return;
    }

    //Erase value
    formik.setFieldValue(itemData.config.fieldName, '');
  };

  useEffect(() => {
    return () => {
      formik.setFieldError(itemData.config.fieldName, '');
    };
  }, []);

  useEffect(() => {
    setLocationList(_.get(externalData, 'locationsList', []));
    //setPreselectedLocationId(_.get(externalData, 'locationId', null)); // to comment?
    setPreselectedLocation(_.find(externalData.locationsList, { locationId: preselectedLocationId }))
  }, [externalData]);

  useEffect(() => {
    //When there is no need to display the field, we reset it's state to a clean one
    if ((!itemData.config.displayed) || _.isEmpty(locationList)) {
      _resetField();
    } else {
      let isEmpty = false;
      if (itemData.config.required) {
        isEmpty = _.isEmpty(preselectedLocationId) && _.isEmpty(locationIdSelected);
      }
      formik.setFieldError(
        itemData.config.fieldName,
        isEmpty ? 'required' : ''
      );
    }
  }, [itemData.config.displayed, itemData.config.required, locationIdSelected]);

  useEffect(() => {
    if (highlightErrorFieldsState.state) {
      setIsInvalid(!!formik.getFieldMeta(itemData.config.fieldName).error);
    }
  }, [highlightErrorFieldsState]);

  const isLocationSelectorVisible = _.some(locationList) &&
    (_.get(preselectedLocation, 'ocaShowOnlyChildLocations') || !preselectedLocationId);

  if (!isLocationSelectorVisible) {
    return null
  }

  /*   if ((preselectedLocationId && !_.get(preselectedLocation, 'ocaShowOnlyChildLocations')) || _.isEmpty(locationList)) {
      return null;
    } */

  const tree = buildTree(locationList, preselectedLocation);
  return (
    <Box
      component={Grid}
      item
      xl={_.get(fieldConfig, 'columns.xl', 12)}
      lg={_.get(fieldConfig, 'columns.lg', 12)}
      md={_.get(fieldConfig, 'columns.md', 12)}
      sm={_.get(fieldConfig, 'columns.sm', 12)}
      xs={_.get(fieldConfig, 'columns.xs', 12)}
      display={{
        xl: !itemData.config.displayed ? 'none' : 'block',
        lg: !itemData.config.displayed ? 'none' : 'block',
        md: !itemData.config.displayed ? 'none' : 'block',
        sm: !itemData.config.displayed ? 'none' : 'block',
        xs: !itemData.config.displayed ? 'none' : 'block',
      }}
    >
      <Grid container spacing={2}>
        {componentVersion === "v2" && (
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <FormControl
              variant={isMobileView ? "outlined" : "standard"}
              fullWidth={true}
              className={classes.v2LocationComponent + (isMobileView ? ' mobile-view' : '') + (isInvalid ? ' invalid' : '')}
            >
              <DropdownTreeSelect
                data={tree}
                mode="radioSelect"
                inlineSearchInput={true}
                onChange={(currentNode, selectedNodes) => handleChange(currentNode.value)}
                texts={{ placeholder: `${_.get(fieldConfig, `displayLabel.${locale}`, '')}${fieldConfig.required ? ` *` : ``}` }}
                disabled={fieldConfig.disabled}
              />
            </FormControl>
            <FormHelperText>
              {_.get(fieldConfig, `helperText.${locale}`, '')}
            </FormHelperText>
          </Grid>
        )}
        {componentVersion === "v1" && (
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <FormControl variant={isMobileView ? "outlined" : "standard"} fullWidth={true}>
              <InputLabel
                id={fieldConfig.fieldName}
                required={fieldConfig.required}
              >
                {_.get(fieldConfig, `displayLabel.${locale}`, '')}
              </InputLabel>
              <Select
                key={fieldId}
                fullWidth={true}
                name={fieldConfig.fieldName}
                disabled={fieldConfig.disabled}
                value={value ? value : ''}
                onChange={event => handleChange(event.target.value)}
                labelId={fieldConfig.fieldName}
                style={{ maxHeight: '40vh' }}
                required={fieldConfig.required}
                variant={isMobileView ? "outlined" : "standard"}
              >
                {!_.isEmpty(locationList) &&
                  locationList.map((option: any, index: number) => {
                    if (option.category == 'Region') {
                      return (
                        <MenuItem
                          key={`${fieldId}${index}`}
                          value={option.locationId}
                        >
                          {option.locationName}
                        </MenuItem>
                      );
                    } else {
                      return null;
                    }
                  })}
              </Select>
              <FormHelperText>
                {_.get(fieldConfig, `helperText.${locale}`, '')}
              </FormHelperText>
            </FormControl>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export { LocationSelect };
