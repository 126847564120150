import React, { } from "react";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.bubble.css';

interface Props {
  description: string,
};

const FinanceProgramDescription = ({ description }: Props) => {

  const classes = useStyles();

  return (
    <>
      <Typography variant="h6">Finance Program Description</Typography>
      <ReactQuill
        value={description}
        readOnly={true}
        theme="bubble"
        modules={{ toolbar: false }} // Disable toolbar
      />
    </>
  );
};


const useStyles = makeStyles(() => {
  return {

  };
});

export default FinanceProgramDescription;