import React, { useContext } from "react";
import Button from "@mui/material/Button";
import styles from "./ConfirmationModal.module.css";
import HeaderCard from "./HeaderCard";

import { VendorContext } from "../../../context";
import { formatCurrency } from "../../../helpers/stringFormatters";
import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";



const updateProposal = gql`
  mutation ($dynamicsId: ID, $id: ID, $dynamicsObj: updateProposalCRMFields, $fieldsObj: updateProposalFields) {
    updateProposal(dynamicsId: $dynamicsId, id: $id, dynamicsObj: $dynamicsObj, fieldsObj: $fieldsObj)
  }
`;

const ConfirmationModal = ({
  closeModal,
  loanAmount,
  customerBusiness,
  termLength,
  monthlyPayment,
  transactionGUID,
  setFormSubmit,
  formSubmitted,
  vendorGUID,
  propId,
  interestRate,
  docFee,
  rate,
  advancePayment,
  amountDue,
  creditProduct,
  proposalId,
  textAmount,
  hasPaymentSelectorOptions,

}: {
  closeModal: any,
  loanAmount: any,
  customerBusiness: any,
  termLength: any,
  monthlyPayment: any,
  transactionGUID: any,
  setFormSubmit: any,
  formSubmitted: any,
  vendorGUID: any,
  propId: any,
  interestRate: any,
  docFee: any,
  rate: any,
  advancePayment: any,
  amountDue: any,
  creditProduct: any,
  proposalId: any,
  textAmount: any,
  hasPaymentSelectorOptions: any,
}) => {
  console.log(`hasPaymentSelectorOptions::: ${hasPaymentSelectorOptions} ||| advancePayment: ${advancePayment}`);
  const [postPaymentSelection, { loading }] = useMutation(updateProposal, {
    onCompleted: () => {
      setFormSubmit(true);
    },
  });
  const { color: vendorColor } = useContext(VendorContext);
  const paymentSelectionObj = {
    monthlyPayment: parseFloat(monthlyPayment.replace(/,/g, "")),
    termLength: JSON.stringify(termLength),
    interestRate: interestRate * 100,
    docFee: docFee,
    dcrStage: "Accepted",
    status: "Accepted",
    apr: rate * 100,
    numberOfAdvancePayments: advancePayment,
    amountDue: parseFloat(amountDue),
    productSelected: creditProduct,
    netTerm: JSON.stringify(termLength - advancePayment),
    fastTrackStatusId: 220,
  };

  let urlParams = null;
  if (window) {
    urlParams = new URLSearchParams(window.location.search);
  }

  const postPayment = () => {
    if (propId === "5eecc379-1434-ea11-8114-005056b05a0f") {
      if (!vendorGUID) {
        return (window.location.href = "/confirmed");
      }
      return (window.location.href = "/confirmed?&vendorGUID=" + vendorGUID);
    }
    if (propId) {
      postPaymentSelection({
        variables: {
          dynamicsId: propId,
          dynamicsObj: paymentSelectionObj,
        },
      })
        .then(resp => {
          if (!vendorGUID) {
            return (window.location.href = "/confirmed");
          }
          return (window.location.href = "/confirmed?&vendorGUID=" + vendorGUID);
        })
        .catch(error => {

          console.error(error);
          if (!vendorGUID) {
            return (window.location.href = "/posterror");
          }
          return (window.location.href = "/posterror?&vendorGUID=" + vendorGUID);
        });
    }
    if (proposalId) {
      postPaymentSelection({
        variables: {
          id: proposalId,
          fieldsObj: paymentSelectionObj,
        },
      })
        .then(resp => {
          if (!vendorGUID) {
            return (window.location.href = "/confirmed");
          }
          return (window.location.href = "/confirmed?&vendorGUID=" + vendorGUID);
        })
        .catch(error => {

          console.error(error);
          if (!vendorGUID) {
            return (window.location.href = "/posterror");
          }
          return (window.location.href = "/posterror?&vendorGUID=" + vendorGUID);
        });
    }
  };

  return (
    <div className={styles.modalCard}>
      <p style={{ color: vendorColor }} className={[styles.defaultTextColor].join(" ")}>
        Confirm Selection
      </p>
      <div className={styles.headerCardWrapper}>
        <HeaderCard loanAmount={loanAmount} customerBusiness={customerBusiness} textAmount={textAmount} />
      </div>
      <div style={{ height: "36px" }} />
      <div className={styles.termBoxWrapper}>
        <div className={styles.termBox}>
          <div>
            <p className={styles.labelText}>Monthly Payment</p>
            <p>
              <span >{formatCurrency(monthlyPayment)}</span> per month
            </p>
          </div>
          <div>
            <p className={styles.labelText}>Term Length</p>
            <p>
              <span >{termLength}</span> months
            </p>
          </div>
          <div>
            <p className={styles.labelText}>Documents Fee</p>
            <p>{docFee === 0 ? <span >$0.00</span> : <span >{formatCurrency(docFee)}</span>}</p>
          </div>
          <div>
            <p className={styles.labelText}>Amount Due In Advance</p>
            <p>
              <span>{formatCurrency(hasPaymentSelectorOptions ? (advancePayment === 0 ? "0.00" : advancePayment) : amountDue)}</span>{" "}
            </p>
          </div>
        </div>
      </div>
      <div style={{ height: "50px" }} />
      <div className={styles.buttonsBoxWrapper}>
        <div className={styles.buttonsBox}>
          <Button type="button" variant="outlined" color="primary" onClick={closeModal}>
            Cancel
          </Button>
          {!urlParams ||
            (!urlParams.has("viewOnly") && (
              <Button color="primary" disabled={loading || formSubmitted} type="submit" variant="contained" onClick={() => postPayment()}>
                {formSubmitted ? "Submitted" : "Submit"}
              </Button>
            ))}
        </div>
      </div>
      <div style={{ height: "32px" }} />
      <p className={[styles.finePrint].join(" ")}>Amounts shown do not include applicable taxes.</p>
    </div>
  );
};

export default ConfirmationModal;
