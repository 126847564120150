import { gql } from 'apollo-boost';

export const MUTATION_CREATE_CREDIT_APP = gql`
  mutation ($creditApplicationInput: CreateCreditApplicationInput!) {
    createCreditApplication(input: $creditApplicationInput) {
      id
      dynamicsId
      success
      errorMessage
    }
  }
`;
