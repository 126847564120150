export const UPSERT_FILE = `
  mutation ($files: Upload) {
    upsertFile(files: $files)
  }
`;

export const UPSERT_DOCUMENT = `
mutation($input: UpsertDocumentInput!, $file: Upload) {
  upsertDocument(input: $input, file: $file) 
}
`;
