import { FieldType } from '../types';
export const HiddenField = ({
  itemData,
  value,
  locale,
}: {
  itemData: FieldType;
  value: any;
  locale: string;
}) => {
  return null;
};
