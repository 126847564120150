import { gql } from 'apollo-boost';

export const QUERY_PARTNER = gql`
  query partner($dynamicsId: ID!) {
    partner(dynamicsId: $dynamicsId) {
      partnerProfileId
      partnerDynamicsProfileId
      partnerAccountId
      partnerAccountDynamicsId
      parentAccountId
      parentPartnerDynamicsId
      name
      partnerLogo
      psChannel
      styles
    }
  }
`;
