import { APP_ENV, captchaSiteKey } from "../config";

import React, { useContext, useEffect, useState, useRef } from "react";
import { Grid, Button, Typography, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { VendorContext } from '../context';
import styles from "../CreditApplicationFormWrapper.module.css";
import { gql } from "apollo-boost";
import { useMutation } from "@apollo/react-hooks";
import MessageModal from "../components/MessageModal";
import CheckSVG from "../assets/images/Check.svg";
import ReCAPTCHA from "react-google-recaptcha";
import checkFormIdStatus from "../api/checkFormIdStatus";
import { v4 as uuidv4 } from "uuid";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { getURLParameter } from '../helpers';
import { Form, Formik, FormikProps, FormikValues, useFormikContext } from 'formik';
import PropTypes from "prop-types";
import NumberFormat from "react-number-format";
import mixpanel from "../mixpanel";

import * as Yup from "yup";



const SignupSchema = Yup.object().shape({
  contactFirstName: Yup.string().required("Required"),
  contactLastName: Yup.string().required("Requiered"),
  contactEmail: Yup.string().email("Enter a valid email").required("Required"),
  businessName: Yup.string().required("Required"),
  contactPhone: Yup.string().required("Required").min(10, "Please enter the correct phone number"),
});

const useStyles = makeStyles((theme: any) => ({
  equipmentPicContainer: {
    [theme.breakpoints.up("md")]: {
      maxHeight: 257,
      maxWidth: 400,
    },
  },
  equipmentPic: {
    borderTopLeftRadius: 7,
    borderBottomLeftRadius: 7,
    display: "block",
    height: "100%",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      borderTopLeftRadius: 7,
      borderBottomLeftRadius: 0,
      borderTopRightRadius: 7,
    },
  },
  personalInfoStyles: {
    [theme.breakpoints.up("md")]: {
      width: "calc(50% - 30px) !important",
      marginRight: "30px",
      maxWidth: 400,
    },
  },
  successesSentEmailHeader: {
    fontWeight: "300",
    fontSize: "26px",
  },
  iconSuccess: {
    width: "37px",
    height: "37px",
    marginRight: "20px",
  },
  columnHeader: {
    marginBottom: "20px",
    fontWeight: "500",
    fontSize: "20px",
    lineHeight: "23.5px",
    letterSpacing: "-0.00941173px",
  },
  columnText: {
    fontSize: "14px",
    lineHeight: "16px",
    color: "#606060",
  },
  column: {
    borderRight: "1px solid #CCCCCC",
    "@media (max-width: 959px)": {
      border: "none",
      marginBottom: "20px",
    },
  },
  columnContentContainer: {
    justifyContent: "center",
    "@media (max-width: 959px)": {
      paddingTop: "20px",
      border: "none",
    },
  },
  successSentContainer: {
    padding: "50px 90px 30px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    "@media (max-width: 540px)": {
      padding: "50px 30px 30px",
    },
  },
  equipmentInfoContainer: {
    marginBottom: 60,
    borderRadius: 7,
    boxShadow: "0px 0px 30px rgba(0, 0, 0, 0.116852)",
    position: "relative",
  },
  equipmentInfoHeader: {
    fontWeight: 500,
    fontSize: 30,
    lineHeight: "35px",
    marginBottom: 20,
  },
  dealerInfoContainer: {
    padding: 20,
  },
  companyLogoUrl: {
    position: "inherit",
    maxHeight: "50px",
    right: 0,
    top: 0,
    marginRight: 20,
    [theme.breakpoints.up("md")]: {
      position: "absolute",
      marginTop: 20,
    },
  },
  dealerInfoHeader: {
    fontWeight: 700,
    fontSize: 30,
    lineHeight: "35px",
    marginBottom: 20,
  },
  companyInfo: {},
  companyTitle: {
    fontSize: 14,
    fontWeight: 700,
    lineHeight: "16px",
  },
  companyName: {
    margin: "3px 0 5px",
    fontSize: 14,
    fontWeight: 700,
    lineHeight: "16px",
    textTransform: "uppercase",
    color: "#818181",
  },
  equipmentDesHeader: {
    fontSize: 30,
    fontWeight: 700,
    margin: "20px 0 15px",
    lineHeight: "35px",
  },
  URLItem: {
    flexBasis: "auto",
    flex: "1 1",
    fontSize: 14,
    fontWeight: 700,
    lineHeight: "16px",
    maxWidth: "none",
  },
  URLParametrText: {
    margin: 0,
    fontSize: 14,
    fontWeight: 300,
    lineHeight: "16px",
    textTransform: "uppercase",
    color: "#818181",
  },
  partnerFormHeaderText: {
    fontWeight: 500,
    fontSize: 30,
    lineHeight: "35px",
    margin: 0,
  },
  partnerFormInput: {},
  inputContainer: {
    position: "relative",
    marginBottom: "28px !important",
    "& .MuiFormLabel-root": {
      color: "#000",
    },
    "& .MuiInputBase-root": {
      maxHeight: 50,
      borderRadius: 7,
    },
    "& .MuiFormHelperText-root": {
      position: "absolute",
      bottom: -20,
    },
  },
  submitContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  sendEmailButton: {
    borderRadius: 7,
  },
  validationErrorMessage: {
    color: '#f44336',
    margin: "5px 10px"
  },
  captchaContainer: {
    "& > div": {
      marginRight: "30px !important",
    },
    marginBottom: 25,
    marginLeft: 45,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      marginTop: 20,
    },
    [theme.breakpoints.up("xl")]: {
      marginLeft: 0,
      marginTop: 0,
    },
    [theme.breakpoints.only("md")]: {
      marginLeft: 0,
      marginTop: 0,
    },
  },
}));

const CREATE_LEAD = gql`
  mutation ($input: CreateLeadInput!) {
    createLead(input: $input) {
      id
    }
  }
`;

const SEND_LINK = gql`
  mutation ($input: sendContactFormOCALinkInput!) {
    sendContactFormOCALink(input: $input) {
      success
    }
  }
`;

export const ContactForm = ({
  publicAccount,
  vendorGUID,
  partner,
  partnerLink
}: {
  publicAccount: any;
  vendorGUID: string;
  partner: any;
  partnerLink: any
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const classes = useStyles();
  const [formId, setFormId] = useState(getURLParameter('formId'));
  const locationProvider = useLocation();
  let navigate = useNavigate();

  const { accountName } = useContext(VendorContext);
  const partnerId = getURLParameter("partnerId");
  const partnerDealerId = getURLParameter("partnerDealerId");
  const dealerId = getURLParameter("dealerId");
  const equipmentId = getURLParameter("equipId");
  const manufacturer = getURLParameter("manufacturer");
  const model = getURLParameter("model");
  const serialNum = getURLParameter("serialNum");
  const stockNumber = getURLParameter("stockNumber");
  const equipmentCondition = getURLParameter("equipmentCondition");
  const equipmentPictureUrl = getURLParameter("equipmentPictureUrl");
  const companyLogoUrl = getURLParameter("companyLogoUrl");
  const equipmentYear = getURLParameter("equipmentYear");
  const equipmentMake = getURLParameter("equipmentMake");
  const equipmentModel = getURLParameter("equipmentModel");
  const equipmentHours = getURLParameter("equipmentHours");
  const equipmentSN = getURLParameter("equipmentSN"); //Serial Number
  const equipmentDescriptionURL = getURLParameter("equipmentDescription");
  const dealerName = getURLParameter("dealerName");
  const price = getURLParameter("price");
  const machineURL = getURLParameter("machineURL");
  const [openMessageModal, setOpenMessageModal] = useState(false);
  const [submissionMessage, setSubmissionMessage] = useState({ title: '', message: '' });
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [isPassedCaptcha, setIsPassedCaptcha] = useState(false);

  const formikRef = useRef<FormikProps<FormikValues>>(null);

  const formik: FormikProps<any> = useFormikContext();


  useEffect(() => {
    if (formId) {
      //check if formId is available already
      // Using an IIFE

      (async formId => {
        try {
          const { data } = await checkFormIdStatus(formId);
          const used = !!data.data.checkFormIdStatus && !!data.data.checkFormIdStatus.used;

          if (used) {
            const newFormId = uuidv4();
            setFormId(newFormId);
            searchParams.set('formId', newFormId);
            setSearchParams(searchParams);
          }
        } catch (e) {
          //@ts-ignore
          window.NREUM.noticeError(e);
          console.log(e);
          console.log("Failed to reload OCA");
        }
      })(formId);

      try {
        //@ts-ignore
        window.waitForGlobal("_mfq", function () {
          if (formId) {
            //@ts-ignore
            window._mfq.push(...window._mfq, ["setVariable", "FORM_ID", formId]);
          }
        });
      } catch (error) {
        //@ts-ignore
        window.NREUM.noticeError(error);
      }
    }
    if (!formId) {
      setFormId(uuidv4());
    } else {
      if (!searchParams.get("formId")) {
        const url = searchParams ? `${locationProvider.search}&formId=${formId}` : `?formId=${formId}`;
        navigate({
          pathname: locationProvider.pathname,
          search: url,
        });
      }
    }
  }, [formId]);

  useEffect(() => {
    if (!!partnerDealerId && !!partnerId && !!dealerName) {
      mixpanel.register({
        partnerDealerId,
        dealerName,
        partnerId,
        environment: APP_ENV,
      });

      mixpanel.track("Access Dealer Contact Form", { send_immediately: true });
      console.log(`:: TRACKED :: Access Dealer Contact Form`);
    }
  }, [partnerDealerId, partnerId, dealerName]);

  const equipmentDescription = `${equipmentId} ${manufacturer} ${model} ${serialNum} ${stockNumber}`;

  const [createLead] = useMutation(CREATE_LEAD, {
    context: { authRequired: true },
  });

  const [sendLink] = useMutation(SEND_LINK);

  const handleCloseModal = () => setOpenMessageModal(false);
  const handleOpenModal = () => setOpenMessageModal(true);
  const handleSubmissionMessage = (message: any) => setSubmissionMessage(message);

  const handleSubmit = async (values: any) => {
    try {
      handleOpenModal();
      mixpanel.track("Complete Dealer Contact Form", { send_immediately: true });
      console.log(`:: TRACKED :: Complete Dealer Contact Form`);

      handleSubmissionMessage({
        title: "Sending",
        message: "Please wait. Sending the email...",
      });

      let description = "";

      if (equipmentId) {
        description += `equipment id: ${equipmentId}  - `;
      }

      if (manufacturer) {
        description += `manufacturer: ${manufacturer}  - `;
      }

      if (model) {
        description += `model: ${model}  - `;
      }

      if (serialNum) {
        description += `serial number: ${serialNum} - `;
      }

      if (stockNumber) {
        description += `stock number: ${serialNum}`;
      }

      await createLead({
        variables: {
          input: {
            email: values.contactEmail,
            firstName: values.contactFirstName,
            lastName: values.contactLastName,
            fullName: `${values.contactFirstName} ${values.contactLastName}`,
            phone: values.contactPhone,
            partnerId: partnerId,
            partnerDealerId: partnerDealerId,
            emailRemindersEnabled: false,
            source: "Partner Contact Form",
            send: true,
            description,
            userProfile: {},
            businessName: values.businessName,
            amountRequested: parseFloat(price),
            communicationDetail: values.contactQuestion,
            machine: `${manufacturer} ${model}`,
            serialNumber: serialNum,
            stockNumber: stockNumber,
            partnerName: partner.name.slice(5),
            machineURL,
            formId: formId,
          },
        },
      });
      handleSubmissionMessage({
        title: "Success",
        message: "Sent Successfully",
      });
      setFormSubmitted(true);
    } catch (e) {
      console.log(e);
      handleSubmissionMessage({
        title: "Error",
        message: "There was something wrong with your request",
      });
    }
  };

  const handleSendLink = async (values: any) => {
    try {
      handleOpenModal();
      mixpanel.track("Sending DCF Complete OCA later link", { send_immediately: true });
      console.log(`:: TRACKED :: Sending DCF Complete OCA later link`);
      handleSubmissionMessage({
        title: "Sending",
        message: "Please wait. Sending the link...",
      });
      const link = encodeURIComponent(`?partnerId=${partnerId}&partnerDealerId=${partnerDealerId}&formId=${formId}`);
      await sendLink({
        variables: {
          input: {
            email: values.contactEmail,
            fullName: `${values.contactFirstName} ${values.contactLastName}`,
            phone: values.contactPhone,
            link: link,
            vpId: publicAccount.vendorProfile.id,
          },
        },
      });
      handleSubmissionMessage({
        title: "Success",
        message: "Sent Successfully",
      });
      setFormSubmitted(true);
    } catch (e) {
      handleSubmissionMessage({
        title: "Error",
        message: "There was something wrong with your request",
      });
    }
  };

  const disableSubmit = (formik: any) => {
    if (formik && Object.keys(formik.touched).length !== 0 && Object.keys(formik.errors).length === 0 && isPassedCaptcha) {
      return false;
    } else {
      return true;
    }
  };

  const disableSendLink = (formik: any) => {
    if (formik && (!formik.errors.contactEmail && formik.touched.contactEmail) || (!formik.errors.contactPhone && formik.touched.contactPhone)) {
      return false;
    } else {
      return true;
    }
  };

  const handlePrequalify = async (values: any) => {
    mixpanel.track("Click to Prequalify", { send_immediately: true });
    console.log(`:: TRACKED :: Click to Prequalify`);

    return (window.location.href = `
        /credit-app?partnerId=${partnerId}&partnerDealerId=${partnerDealerId}&price=${price}&firstName=${values.contactFirstName}&lastName=${values.contactLastName}&equipmentDescription=${equipmentDescription}
        &firstName=${values.contactFirstName}&lastName=${values.contactLastName}&phone=${values.contactPhone}&email=${values.contactEmail}
        &equipmentCondition=${equipmentCondition}
        &equipmentYear=${equipmentYear}&equipmentMake=${manufacturer}&equipmentModel=${model}&companyLogoUrl=${companyLogoUrl}
        &equipmentHours=${equipmentHours}&equipmentSN=${equipmentSN}&equipmentPictureUrl=${equipmentPictureUrl}&formId=${formId}`);
  };

  const handleTextDealer = async () => {
    mixpanel.track("DCF Text Dealer", { send_immediately: true });
    console.log(`:: TRACKED :: DCF Text Dealer`);
    try {
      handleOpenModal();
      handleSubmissionMessage({
        title: "Error",
        message: "Sorry but this dealer does not have this feature enabled.",
      });
    } catch (e) {
      console.log(e);
      handleSubmissionMessage({
        title: "Error",
        message: "There was something wrong with your request",
      });
    }
  };

  function NumberFormatCustom(props: any) {
    const { inputRef, onChange, ...other } = props;

    return (
      <NumberFormat
        format="+1 (###) ###-####"
        mask="_"
        {...other}
        getInputRef={inputRef}
        onValueChange={(values: any) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
      />
    );
  }

  NumberFormatCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
  };

  return (
    <form className={styles.partnerForm}>
      <Formik
        initialValues={{
          contactFirstName: "",
          contactLastName: "",
          contactEmail: "",
          contactPhone: "",
          contactQuestion: "",
          businessName: "",
        }}
        onSubmit={() => { }}
        innerRef={formikRef}
        validationSchema={SignupSchema}
      >
        {({ errors, values, touched, handleBlur }) => {
          return !formSubmitted ? (
            <>
              <Grid container className={classes.equipmentInfoContainer}>
                {equipmentPictureUrl && equipmentPictureUrl !== "undefined" && (
                  <Grid item xs={12} sm={12} md={6} className={classes.equipmentPicContainer}>
                    <img className={classes.equipmentPic} style={{ width: "100%" }} src={decodeURIComponent(equipmentPictureUrl)} alt="Equipment Pic" />
                  </Grid>
                )}
                <Grid item xs={12} sm={12} md={6} className={classes.dealerInfoContainer}>
                  <Grid container direction="column">
                    <h2 className={classes.dealerInfoHeader} style={{ marginTop: 0 }}>
                      Dealer
                    </h2>
                    {companyLogoUrl && companyLogoUrl !== "undefined" && (
                      <Grid item>
                        <img src={decodeURIComponent(companyLogoUrl)} alt="Equipment Pic" className={classes.companyLogoUrl} />
                      </Grid>
                    )}
                    <Grid item className={classes.companyInfo}>
                      <p className={classes.companyName}>{!!accountName ? accountName : dealerName}</p>
                    </Grid>
                    <Grid item>
                      {(equipmentId || model || serialNum || manufacturer) && (
                        <h2 className={classes.equipmentDesHeader}>
                          Equipment Description
                        </h2>
                      )}
                      {equipmentDescription.length !== 4 && (
                        <Grid container>
                          {equipmentId && (
                            <Grid item className={classes.URLItem} xs={12} md={3} lg={3}>
                              Equip ID: <p className={classes.URLParametrText}>{equipmentId}</p>
                            </Grid>
                          )}
                          {model && (
                            <Grid item className={classes.URLItem} xs={12} md={3} lg={3}>
                              Model: <p className={classes.URLParametrText}>{model}</p>
                            </Grid>
                          )}
                          {serialNum && (
                            <Grid item className={classes.URLItem} xs={12} md={3} lg={3} justifyContent="flex-end">
                              Serial Number: <p className={classes.URLParametrText}>{serialNum}</p>
                            </Grid>
                          )}
                          {manufacturer && (
                            <Grid item className={classes.URLItem} xs={12} md={3} lg={3}>
                              Manufacturer: <p className={classes.URLParametrText}>{manufacturer}</p>
                            </Grid>
                          )}
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <p className={classes.partnerFormHeaderText}>Send an Email</p>
              <Grid container direction="column" spacing={1}>
                <Grid container xs={12} md={12} lg={12} className={styles.partnerFormFieldsContainer}>
                  <Grid container className={classes.personalInfoStyles}>
                    <Grid item xs={12} md={12} lg={12} className={classes.inputContainer}>
                      <TextField
                        fullWidth={true}
                        id="contact-name"
                        name="contactFirstName"
                        value={values.contactFirstName}
                        label="First Name"
                        inputProps={{
                          className: classes.partnerFormInput,
                        }}
                        onChange={(e) => formikRef.current?.setFieldValue("contactFirstName", e.target.value)}
                        onBlur={handleBlur}
                      />
                      {errors.contactFirstName && touched.contactFirstName ? <div className={classes.validationErrorMessage}>{errors.contactFirstName}</div> : null}
                    </Grid>
                    <Grid item xs={12} md={12} lg={12} className={classes.inputContainer}>
                      <TextField
                        fullWidth={true}
                        id="contact-last-name"
                        name="contactLastName"
                        value={values.contactLastName}
                        label="Last Name"
                        inputProps={{
                          className: classes.partnerFormInput,
                        }}
                        onChange={(e) => formikRef.current?.setFieldValue("contactLastName", e.target.value)}
                        onBlur={handleBlur}
                      />
                      {errors.contactLastName && touched.contactLastName ? <div className={classes.validationErrorMessage}>{errors.contactLastName}</div> : null}
                    </Grid>
                    <Grid item xs={12} md={12} lg={12} className={classes.inputContainer}>
                      <TextField
                        fullWidth={true}
                        id="business-name"
                        name="businessName"
                        value={values.businessName}
                        label="Business Name"
                        inputProps={{
                          className: classes.partnerFormInput,
                        }}
                        onChange={(e) => formikRef.current?.setFieldValue("businessName", e.target.value)}
                        onBlur={handleBlur}
                      />
                      {errors.businessName && touched.businessName ? <div className={classes.validationErrorMessage}>{errors.businessName}</div> : null}
                    </Grid>
                    <Grid item xs={12} md={12} lg={12} className={classes.inputContainer}>
                      <TextField
                        fullWidth={true}
                        id="contact-phone"
                        label="Your Phone Number"
                        name="contactPhone"
                        value={values.contactPhone}
                        inputProps={{
                          className: classes.partnerFormInput,
                        }}
                        InputProps={{
                          inputComponent: NumberFormatCustom,
                        }}
                        onChange={(e) => formikRef.current?.setFieldValue("contactPhone", e.target.value)}
                        onBlur={handleBlur}
                      />
                      {errors.contactPhone && touched.contactPhone ? <div className={classes.validationErrorMessage}>{errors.contactPhone}</div> : null}
                    </Grid>
                    <Grid item xs={12} md={12} lg={12} className={classes.inputContainer}>
                      <TextField
                        fullWidth={true}
                        id="contact-email"
                        label="Your Email Address"
                        name="contactEmail"
                        value={values.contactEmail}
                        inputProps={{
                          className: classes.partnerFormInput,
                        }}
                        onChange={(e) => formikRef.current?.setFieldValue("contactEmail", e.target.value)}
                        onBlur={handleBlur}
                      />
                      {errors.contactEmail && touched.contactEmail ? <div className={classes.validationErrorMessage}>{errors.contactEmail}</div> : null}
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={6} lg={6} className={classes.submitContainer} direction="column">
                    <TextField
                      fullWidth={true}
                      id="contact-question"
                      label="Ask Your Question"
                      value={values.contactQuestion}
                      InputLabelProps={{
                        className: styles.questionFieldLabel,
                      }}
                      name="contactQuestion"
                      multiline
                      rows={8}
                      onChange={(e) => formikRef.current?.setFieldValue("contactQuestion", e.target.value)}
                    />
                    <Grid container alignItems="flex-end" className={classes.captchaContainer}>
                      <ReCAPTCHA
                        sitekey={captchaSiteKey}
                        className={styles.captchaStyles}
                        onChange={() => {
                          setIsPassedCaptcha(!isPassedCaptcha);
                        }}
                      />
                      <Button color="primary" className={`${styles.sendEmailButton} ${classes.sendEmailButton}`} onClick={() => handleSubmit(values)} disabled={disableSubmit(formikRef.current)}>
                        Send Email
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </>
          ) : (
            <div className={formSubmitted ? classes.successSentContainer : styles.succesSentContainer}>
              <Grid wrap="nowrap" container alignItems="center" justifyContent="center" className={styles.partnerFormHeaderText} style={{ marginBottom: "70px" }}>
                <img src={CheckSVG} className={classes.iconSuccess} />
                <span className={classes.successesSentEmailHeader}>
                  {` YOUR EMAIL TO ${!!accountName ? accountName : dealerName} WAS SENT
              SUCCESSFULLY`}
                </span>
              </Grid>
              <Grid container>
                <Grid item xs={12} md={4} className={classes.column}>
                  <Grid container justifyContent="center">
                    <div>
                      <Grid item xs={12} className={classes.columnHeader}>
                        Want a faster response?
                      </Grid>
                      <Grid item xs={12} className={classes.columnText}>
                        Text to reach out to dealer faster
                      </Grid>
                      <Button color="primary" className={styles.sendEmailButton} style={{ marginTop: "40px", borderRadius: "7px" }} onClick={() => handleTextDealer()}>
                        Text Dealer
                      </Button>
                    </div>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={4} className={classes.column}>
                  <Grid container className={classes.columnContentContainer}>
                    <div>
                      <Grid item md={12} className={classes.columnHeader}>
                        Prequalify for financing
                      </Grid>
                      <Grid item md={12} className={classes.columnText}>
                        Start your application here
                      </Grid>
                      <Button color="primary" className={styles.sendEmailButton} style={{ marginTop: "40px", borderRadius: "7px" }} onClick={() => handlePrequalify(values)}>
                        Prequalify
                      </Button>
                    </div>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Grid container justifyContent="center" className={classes.columnContentContainer}>
                    <div>
                      <Grid item xs={12} className={classes.columnHeader}>
                        Dont have time now?
                      </Grid>
                      <Grid item xs={12} className={classes.columnText}>
                        Send me a link to prequalify
                      </Grid>
                      <Grid item style={{ marginTop: "20px" }} className={classes.inputContainer}>
                        <TextField
                          id="contact-email"
                          label="Email"
                          name="contactEmail"
                          value={values.contactEmail}
                          style={{ marginBottom: "10px" }}
                          size="small"
                          inputProps={{
                            className: styles.partnerFormInput,
                          }}
                        />
                        <Grid container justifyContent="center" alignItems="center">
                          <Grid item md={5} style={{ backgroundColor: "#CCCCCC", height: "1px" }}></Grid>
                          <Grid item md={1} style={{ padding: "0px 0px 0px 3px", color: "#606060" }}>
                            or
                          </Grid>
                          <Grid item md={5} style={{ backgroundColor: "#CCCCCC", height: "1px" }}></Grid>
                        </Grid>
                        <TextField
                          id="contact-phone"
                          name="contactPhone"
                          value={values.contactPhone}
                          style={{ marginTop: "10px" }}
                          size="small"
                          inputProps={{
                            className: styles.partnerFormInput,
                          }}
                          InputProps={{
                            inputComponent: NumberFormatCustom,
                          }}
                        />
                      </Grid>
                      <Button
                        color="primary"
                        className={styles.sendLinkButton}
                        style={{ marginTop: "40px", borderRadius: "7px" }}

                        onClick={() => handleSendLink(values)}
                        disabled={disableSendLink(formikRef.current)}>
                        SEND ME A LINK
                      </Button>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <MessageModal isOpen={openMessageModal} handleCloseModal={handleCloseModal} title={submissionMessage.title} message={submissionMessage.message} />
            </div>
          )
        }}
      </Formik>
    </form>
  );
};
