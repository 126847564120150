import { useContext } from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';
import { Typography } from '@mui/material';
import Footer from '../Footer';
import FormWindow from '../FormWindow';
import Sidebar from '../Sidebar';
import styles from './Main.module.css';
import backgroundImage from '../../../assets/images/iframeBackground.png';
import { useSearchParams } from 'react-router-dom';
import './Main.module.css';
import MainHeader from '../MainHeader';
import { CommonDataContext } from '../../../context';

const useStyles = makeStyles(() => ({
  iframeMode: {
    padding: 0,
    overflowX: 'hidden',
  },
  financialProposal: {
    paddingTop: '20px',
    paddingBottom: '12px',
    color: 'white',
    backgroundImage: `url(${backgroundImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100%',
    borderRadius: '5px',
  },
  financialProposalText: {
    fontWeight: '300',
    fontSize: '16px',
    lineHeight: '19px',
    marginBottom: '10px',
  },
  financialProposalHeader: {
    fontWeight: '300',
    fontSize: '40px',
    lineHeight: '47px',
  },
  height: {
    height: '20px',
  },
}));

const Main = (props: any) => {
  const classes = useStyles();
  const [searchParams, setSearchParams] = useSearchParams();
  const { children, repData, setRepData, publicAccount, partner, partnerLink, containerMaxWidth } = props;
  const pathName = window.location.pathname;
  const location = window.location.href;
  const isIframe = location.includes('iframeApp=true');
  const isIframeChat = location.includes('chatWithDealer');
  const isPartner = location.includes('/contact/');
  const isTaskPage = location.includes('task-page');
  const monthlyPaymentAmount = searchParams.get('monthlyPaymentAmount');
  const { isLenderDealerCoBranded, coBrandingSettings } = useContext(CommonDataContext);

  return (
    <>
      {!isIframeChat && (
        <>
          <Container
            maxWidth={containerMaxWidth}
            className={isIframe ? classes.iframeMode : ''}
          >
            {isIframe && (
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                className={classes.financialProposal}
              >
                <Grid item xs={12}>
                  <Grid container justifyContent="center" alignItems="center">
                    <Typography
                      component="h6"
                      variant="h6"
                      className={classes.financialProposalText}
                    >
                      Finance it for only
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container justifyContent="center" alignItems="center">
                    <Typography
                      component="h5"
                      variant="h5"
                      className={classes.financialProposalHeader}
                    >
                      $<b>{monthlyPaymentAmount}</b> / Mo.
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            )}
            <div className={isIframe ? '' : classes.height} />
            <Grid
              container
              justifyContent={isPartner ? 'inherit' : 'center'}
              spacing={2}
            >
              <Grid
                item
                xs={12}
                lg={12}
                xl={pathName === `/bankUpload/` ? 12 : 9}
                className={styles.sameHeight}
              >
                {(partner || isLenderDealerCoBranded || isTaskPage) &&
                  <MainHeader
                    partner={partner}
                    isLenderDealerCoBranded={isLenderDealerCoBranded}
                    coBrandingSettings={coBrandingSettings}
                    isTaskPage={isTaskPage}
                    publicAccount={publicAccount}
                  />
                }
                <FormWindow isIframe={isIframe} setRepData={setRepData}>
                  {children}
                </FormWindow>
              </Grid>
              <Grid
                item
                xs={12}
                lg={12}
                xl={pathName === `/bankUpload/` ? 12 : !isPartner ? 3 : 0}
                className={styles.sameHeight}
              >
                <Sidebar
                  repData={repData}
                  publicAccount={publicAccount}
                  setRepData={setRepData}
                  partnerLink={partnerLink}
                />
              </Grid>
            </Grid>
            <div className={classes.height} />
          </Container>
          <Footer />
        </>
      )}
    </>
  );
};

export default Main;
