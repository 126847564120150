import { useLazyQuery } from '@apollo/react-hooks';
import { Button, Checkbox, Container, FormControlLabel, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import { getURLParameter } from '../../../helpers';
import { RecertificationSignaturesPage } from '../RecertificationSignaturesPage/RecertificationSignaturesPage';
import { InsuranceVerificationPage } from '../InsuranceVerificationPage/InsuranceVerificationPage';
import { OwnerAuthorizationPage } from '../OwnerAuthorizationPage/OwnerAuthorizationPage';
import { DocumentSignaturePage } from '../DocumentSignaturePage/DocumentSignaturePage';
import { DocumentESignPage } from '../DocumentESignPage/DocumentESignPage';

import { ApplicantsTaskPage } from '../ApplicantsTaskPage/ApplicantsTaskPage';
import { Task, TaskRegardingValues } from "@trnsact/trnsact-shared-types";
import _ from 'lodash';

import {
  QUERY_TASK,
} from '../../../api';
import { Constants } from '../../../constants';


const useStyles = makeStyles(() => {
  return {
    containerRoot: {
      backgroundColor: 'white',
      marginTop: "20px",
      marginBottom: "20px",
    },
  };
});

export const TaskPage = ({
  publicAccount,
  activeTemplate,
  task,
  setTask,
}: {
  publicAccount: any;
  activeTemplate: any;
  task?: Task,
  setTask: Function,
}) => {
  const classes = useStyles();
  const taskId = getURLParameter('taskId');
  const [taskPageComponent, setTaskPageComponent] = useState<React.ReactNode>();

  const [
    queryTask,
    { data: taskData, refetch: refetchTask },
  ] = useLazyQuery(QUERY_TASK);

  useEffect(() => {
    if (taskId) {
      queryTask({
        variables: { taskId },
      });
    }
  }, [taskId]);

  useEffect(() => {
    const task = _.get(taskData, "publicTask[0]");
    if (!task) {
      return;
    }
    setTask(task);
    //@ts-ignore
    window.waitForGlobal("_mfq", function () {
      const process = () => {
        try {
          //@ts-ignore
          window._mfq.push(["setVariable", "ASSIGNED_BY_USER", _.get(taskData, "publicTask[0].assignedByUser", "")]);
          //@ts-ignore
          window._mfq.push(["setVariable", "ASSIGNED_TO_USER", _.get(taskData, "publicTask[0].assignedToUser", "")]);
          //@ts-ignore
          window._mfq.push(["setVariable", "TASK_REGARDING", _.get(taskData, "publicTask[0].regarding", "")]);
          //@ts-ignore
          window._mfq.push(["setVariable", "STATUS", _.get(taskData, "publicTask[0].status", "")]);
          //@ts-ignore
          window._mfq.push(["setVariable", "VENDOR_GUID", _.get(taskData, "publicTask[0].assignedToAccountId", "")]);
          //@ts-ignore
          window._mfq.push(["setVariable", "ASSIGNED_TO_ACCOUNT_NAME", _.get(taskData, "publicTask[0].assignedToAccount", "")]);
          //@ts-ignore
          window._mfq.push(["setVariable", "ASSIGNED_BY_ACCOUNT_NAME", _.get(taskData, "publicTask[0].assignedByAccount", "")]);
          //@ts-ignore
          window._mfq.push(["setVariable", "CREATED_BY", _.get(taskData, "publicTask[0].createdBy", "")]);
          console.log("Data attached to mouseflow");
        } catch (error) {
          console.error(error);
        }
      };

      window.setTimeout(process, 200);
    });
  }, [taskData]);

  useEffect(() => {
    if (_.isEmpty(task) || _.isEmpty(publicAccount) || _.isEmpty(activeTemplate)) {
      return;
    }
    const taskPageComponent: React.ReactNode = getTaskPageComponent(task || {});
    setTaskPageComponent(taskPageComponent);
  }, [task, publicAccount, activeTemplate]);

  const getTaskPageComponent = (task: Task) => {
    switch (true) {
      case task.regarding === TaskRegardingValues.insuranceVerification:
        return <InsuranceVerificationPage task={task} />
      case [TaskRegardingValues.signatures, TaskRegardingValues.newSignorAndGuarantorSignature].includes(task.regarding as TaskRegardingValues):
        return <OwnerAuthorizationPage
          task={task}
          publicAccount={publicAccount}
          activeTemplate={activeTemplate}
        />
      case task.regarding === TaskRegardingValues.recertificationSignatures:
        return <RecertificationSignaturesPage
          task={task}
          publicAccount={publicAccount}
          activeTemplate={activeTemplate}
        />
      case task.regarding === TaskRegardingValues.catFiAddedndum:
        return <DocumentSignaturePage
          task={task}
          publicAccount={publicAccount}
          activeTemplate={activeTemplate}
        />
      case !!_.find(task.taskItems, { type: Constants.TaskItemTypeValues.documentESign }):
        return <DocumentESignPage
          task={task}
          publicAccount={publicAccount}
          activeTemplate={activeTemplate}
        />
      case task.assignedToApplicant && !task.assignedBySystem:
        return <ApplicantsTaskPage /* Migrated component from OCAv3 */
          task={task}
          publicAccount={publicAccount}
          activeTemplate={activeTemplate}
          refetchTask={refetchTask}
        />
    };
  };


  return (
    <>
      <Container
        maxWidth="lg"
        className={classes.containerRoot}
      >
        {taskPageComponent}
      </Container>
    </>
  );
};
