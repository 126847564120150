import { Button, Grid, Typography } from '@mui/material';

import InfoIcon from '@mui/icons-material/Info';


export const InformationChangedConfirmation = ({
  task,
  creditApplication,
  setHasInformationChanged,
}: {
  task: any;
  creditApplication: any;
  setHasInformationChanged: Function
}) => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography style={{ marginBottom: 15 }}>{task.createdBy} is requesting Recertification for {creditApplication.businessName}'s Credit Application from {creditApplication.createdDateTime}.</Typography>
        <Typography>Hi {task.assignedToUser},</Typography>
      </Grid>
      <Grid item container xs={12} spacing={1}>
        <Grid item container xs={12} spacing={1} alignItems="center">
          <Grid item>
            <Typography>
              To meet compliance requirements and ensure that your application remains valid with {task.assignedByAccount}, please confirm below if there have been any changes since the date of your original application.
            </Typography>
          </Grid>
          <Grid item>
            <Typography>Clicking <strong>Yes</strong> will allow you to review, edit necessary information, and authorize the updated application.</Typography>
            <Typography>Clicking <strong>No</strong> will prompt you to reauthorize the existing application as is.</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Typography>Has any information changed since {creditApplication.createdDateTime}?</Typography>
      </Grid>
      <Grid item container xs={12} justifyContent="center" spacing={1}>
        <Grid item>
          <Button
            variant="outlined"
            color="primary"
            onClick={(e: any) => setHasInformationChanged(false)}
          >
            No
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={(e: any) => setHasInformationChanged(true)}
          >
            Yes
          </Button>
        </Grid>
      </Grid>
    </Grid >
  );
};
