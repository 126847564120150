import { useMutation, useLazyQuery } from '@apollo/react-hooks';
import { Alert, Container, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useEffect, useRef, useState } from 'react';
import { TaskStatuses, SectionTypes, BusinessOwnerInput } from '../../../api/types';
import {
  UPDATE_TASK_STATUS,
  GENERATE_E_SIGN_URL,
  SAVE_E_SIGN_DOCUMENT,
} from '../../../api';
import _ from 'lodash';

import AnvilEmbedFrame from '@anvilco/anvil-embed-frame';

const useStyles = makeStyles(() => {
  return {
    containerRoot: {
      backgroundColor: 'white',
      marginTop: "20px",
      marginBottom: "20px",
    },
  };
});

export const DocumentESignPage = ({
  task,
  publicAccount,
  activeTemplate,
}: {
  task: any;
  publicAccount: any;
  activeTemplate: any;
}) => {

  const [updateTaskStatus] = useMutation(UPDATE_TASK_STATUS, {
    onCompleted() {
      setIsTaskCompleted(true);
    },
    onError() {
      setIsTaskCompleted(false);
    },
  });

  const [generateESignUrl, { data: eSignUrlData }] = useLazyQuery(GENERATE_E_SIGN_URL);
  const [saveESignDocument] = useMutation(SAVE_E_SIGN_DOCUMENT);

  const classes = useStyles();
  const [signURL, setSignURL] = useState<string>('');
  const [isTaskCompleted, setIsTaskCompleted] = useState(task.status === TaskStatuses.Complete);

  useEffect(() => {
    if (!isTaskCompleted) {
      generateESignUrl({ variables: { taskId: task.taskId } });
    }
  }, []);

  useEffect(() => {
    if (_.isEmpty(eSignUrlData)) {
      return;
    }
    setSignURL(eSignUrlData.generateESignUrl);
  }, [eSignUrlData]);

  const onAnvilEventHandler = async (eventObject: any) => {
    try {
      if (eventObject.action === "signerComplete") {
        const result = await saveESignDocument({ variables: { taskId: task.taskId, eid: eventObject.etchPacketEid } });
        if (!!_.get(result, "data.saveESignDocument.documentKey")) {
          await updateTaskStatus({
            variables: {
              taskId: task.taskId,
              status: TaskStatuses.Complete
            },
          });
        }
      }
    } catch (error: any) {
      console.error(error.message);
    }
  };

  return (
    <>
      <Container
        maxWidth="lg"
        className={classes.containerRoot}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h3">Document eSign</Typography>
          </Grid>
          {!isTaskCompleted && signURL && <Grid item xs={12}>
            <AnvilEmbedFrame
              iframeURL={signURL}
              onEvent={onAnvilEventHandler}
              scroll={''}
              style={{ width: "100%", height: "1500px" }}
              className={''}
            />
          </Grid>
          }
          {isTaskCompleted && <Grid item xs={12}>
            <Alert severity="success">Document has been successfully signed!</Alert>
          </Grid>
          }
        </Grid>
      </Container>
    </>
  );
};
