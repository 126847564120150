import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Box, Grid, TextField } from '@mui/material';
import { FormikProps, useFormikContext } from 'formik';
import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { FieldConfigValuesListType, FieldType } from '../types';

const useStyles = makeStyles(() => ({
  radioGroup: {
    flexDirection: 'row',
  },
  radioButton: {
    color: '#818181',
    '& .MuiTypography-root': {
      fontSize: 12,
    },
    maxWidth: '30%',
    float: 'left',
  },
  header: {
    fontSize: 20,
    fontWeight: 500,
  },
  mainContent: {
    position: "relative",
  }
}));

const RadioQuestion = ({
  itemData,
  value,
  locale,
}: {
  itemData: FieldType;
  value: any;
  locale: string;
}) => {
  const { id: fieldId, config: fieldConfig } = itemData;
  const formik: FormikProps<any> = useFormikContext();
  const classes = useStyles();

  // used for backwards compatibility with ssnExempt field (already created fields don't have headerContent and mainContent props)
  const ssnExemptDefaultContents = {
    "headerContent": {
      "en": "Quick Question",
      "es": "Pregunta rápida"
    },
    "mainContent": {
      "en": "<p style=\"margin: 10px 0; color: #606060\">Does this business:</p><p style=\"margin: 15px 0 15px 20px; color: #606060\">1. Generate $10 million or more in total sales, or</p><p style=\"margin: 15px 0 15px 20px; color: #606060\">2. Have more than 6 owners?</p>",
      "es": "<p style=\"margin: 10px 0; color: #606060\">¿Este negocio:</p><p style=\"margin: 15px 0 15px 20px; color: #606060\">1. Generar $10 millones o más en ventas totales, o</p><p style=\"margin: 15px 0 15px 20px; color: #606060\">2. ¿Tienes más de 6 dueños?</p>"
    },
  };

  const handleChange = useCallback(
    (e) => {
      formik.setFieldValue(itemData.config.fieldName, e.target.value, false);
    },
    [formik]
  );

  const _resetField = (): void => {
    // Check if error is already set to ''
    if (Boolean(_.get(formik.errors, itemData.config.fieldName))) {
      //Remove Error
      formik.setFieldError(itemData.config.fieldName, '');
    }

    if (!_.has(formik.values, itemData.config.fieldName)) { // do not set '' value for untouched fields
      return;
    }

    //Erase value
    formik.setFieldValue(itemData.config.fieldName, '');
  };

  useEffect(() => {
    return () => {
      formik.setFieldError(itemData.config.fieldName, '');
    };
  }, []);

  useEffect(() => {
    //When there is no need to display the field, we reset it's state to a clean one
    if (!itemData.config.displayed) {
      _resetField();
    } else {
      let isEmpty = false;
      if (itemData.config.required) {
        isEmpty = _.isEmpty(value);
      }
      formik.setFieldError(
        itemData.config.fieldName,
        isEmpty ? 'required' : ''
      );
    }
  }, [itemData.config.displayed, itemData.config.required, value]);

  return (
    <Box
      component={Grid}
      item
      xl={_.get(fieldConfig, 'columns.xl', 12)}
      lg={_.get(fieldConfig, 'columns.lg', 12)}
      md={_.get(fieldConfig, 'columns.md', 12)}
      sm={_.get(fieldConfig, 'columns.sm', 12)}
      xs={_.get(fieldConfig, 'columns.xs', 12)}
      display={{
        xl: !itemData.config.displayed ? 'none' : 'block',
        lg: !itemData.config.displayed ? 'none' : 'block',
        md: !itemData.config.displayed ? 'none' : 'block',
        sm: !itemData.config.displayed ? 'none' : 'block',
        xs: !itemData.config.displayed ? 'none' : 'block',
      }}
    >
      <FormControl component="fieldset" style={{ padding: 10 }}>
        <Typography variant="h5" component="h5" className={classes.header}>
          {fieldConfig.fieldName === 'ssnExempt' && !fieldConfig.headerContent
            ? _.get(ssnExemptDefaultContents, `headerContent.${locale}`, '')
            : _.get(fieldConfig, `headerContent.${locale}`, '')
          }
        </Typography>
        <div className={classes.mainContent}
          dangerouslySetInnerHTML={{
            __html: (fieldConfig.fieldName === 'ssnExempt' && !fieldConfig.mainContent
              ? _.get(ssnExemptDefaultContents, `mainContent.${locale}`, '')
              : _.get(fieldConfig, `mainContent.${locale}`, '')) +
              (fieldConfig.required ? "<span style=\"position: absolute; top: 5px; right: -10px\"> *</span>" : ""),
          }}
        ></div>
        <RadioGroup
          className={classes.radioGroup}
          onChange={handleChange}
          name={fieldConfig.fieldName}
          value={value ? value : ''}
        >
          {fieldConfig.valuesList?.map(
            (variant: FieldConfigValuesListType, index: number) => (
              <FormControlLabel
                key={`formControl${fieldId}${index}`}
                className={classes.radioButton}
                value={variant.value}
                control={<Radio key={`${fieldId}${index}`} color="primary" />}
                disabled={fieldConfig.disabled}
                label={
                  <FormattedMessage
                    id={variant.label}
                    defaultMessage={variant.label}
                  />
                }
              />
            )
          )}
        </RadioGroup>
      </FormControl>
    </Box>
  );
};
export { RadioQuestion };
