import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Checkbox,
  Select,
  TextField,
} from "@mui/material";
import { FormikProps, useFormikContext } from "formik";
import _ from "lodash";
import { Fragment, useCallback, useEffect, useState, useContext } from "react";
import { CommonDataContext } from '../../context';
import { isDefaultLocale, DEFAULT_LOCALE, getAvailableToSignPersonsFieldOptions } from "../../helpers";
import { FieldOptionsType, FieldType } from "../types";
import { makeStyles } from "@mui/styles";
import { Constants } from "../../constants";
import { MultiselectField } from "./MultiselectField";

const useStyles = makeStyles(() => ({
  selectHeight: {
    maxHeight: "40vh",
  },
  customCheckbox: {
    "& .MuiCheckbox-root": {
      padding: "0",
    },
  },
  formElement: {
    padding: "10px 10px 10px 0",
  },
  checkboxText: {
    cursor: "pointer",
  },
}));
const WhoIsPresentField = ({
  itemData,
  value,
  locale,
  displayLabel,
  isNeedToMoveLabelOutOfInput,
  externalData,
}: {
  itemData: FieldType;
  value: any;
  locale: string;
  displayLabel: string;
  isNeedToMoveLabelOutOfInput: boolean;
  externalData: any;
}) => {
  const { id: fieldId, config: fieldConfig } = itemData;
  const classes = useStyles();
  const formik: FormikProps<any> = useFormikContext();

  const [fieldOptions, setFieldOptions] = useState<FieldOptionsType[] | undefined>();

  const isIndividualApp = _.get(externalData, 'isIndividualApp');

  // we need to depend both on ownersQuantity and on initial render
  const handleSettingFieldOptions = () => {
    const fieldOptionsToSet = getAvailableToSignPersonsFieldOptions(formik, isIndividualApp);
    fieldConfig.fieldOptions = fieldOptionsToSet;
    setFieldOptions(fieldOptionsToSet);
  };

  useEffect(() => {
    handleSettingFieldOptions();
  }, []);

  useEffect(() => {
    if (formik.values.ownersQuantity) {
      handleSettingFieldOptions();
    }
  }, [formik.values.ownersQuantity]);

  return _.some(fieldOptions) &&
    <MultiselectField
      itemData={itemData}
      value={value}
      locale={locale}
      displayLabel={displayLabel}
      isNeedToMoveLabelOutOfInput={isNeedToMoveLabelOutOfInput}
    />
};

export { WhoIsPresentField };
