import dynamicSignatureFieldConfig from "../sample-config/dynamicSignatureFieldConfig.json";
import dynamicSignatureTitleFieldConfig from "../sample-config/dynamicSignatureTitleFieldConfig.json";
import { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Checkbox, FormControl, FormControlLabel, FormGroup, Grid } from '@mui/material';
import { FormikProps, useFormikContext } from 'formik';
import { FieldType, FormGenericValues } from '../types';
import { v4 as uuidv4 } from 'uuid';
import { SignField } from './SignField';
import { Element } from '../Element';
import { Constants } from "../../constants";
import { getOwnerFullNameByWhoIsPresentToSignKey } from "../../helpers";
import { CommonDataContext } from "../../context";
import _ from 'lodash';

const useStyles = makeStyles(() => ({
  requireAgreementsCheckboxLabel: {
    '& .MuiFormControlLabel-label': {
      fontSize: '12px',
    },
  },
}));

export const DynamicSignatureAndTitle = ({
  itemData,
  locale,
  externalData,
}: {
  itemData: FieldType;
  locale: string;
  externalData: any;
}) => {
  const classes = useStyles();
  const { config: fieldConfig } = itemData;
  const formik: FormikProps<any> = useFormikContext();
  const { highlightErrorFieldsState } = useContext(CommonDataContext);
  const [dynamicSignatureFields, setDynamicSignatureFields] = useState<any>([]);
  const [signatureAgreementsState, setSignatureAgreementsState] = useState<any>({});
  const [signatureAgreementsInvalidState, setSignatureAgreementsInvalidState] = useState<any>({});  // use separate object to not highlight error state immediately

  const isPersonalGuaranteeSignaturesSection = _.get(externalData, "sectionConfig.sectionType") === Constants.PersonalGuaranteeSignaturesSectionType;
  const isIndividualApp = _.get(externalData, 'isIndividualApp');
  const activeTemplate = _.get(externalData, 'activeTemplate');
  const isNeedToShowRequireAgreementsCheckbox = fieldConfig.signaturesRequireAgreementsOfElectronicSignature;
  const signatureFieldName = fieldConfig.selectedApiId || 'signature';
  const whoIsPresentToSignKey = isPersonalGuaranteeSignaturesSection
    ? 'whoIsPresentToSignForPersonalGuarantee'
    : 'whoIsPresentToSign';
  const formikErrorKey = `${whoIsPresentToSignKey}-signatureAgreementsInvalidState`;

  const checkIfTitleSelectFieldUsed = () => {
    const titleSelectField = _(activeTemplate.jsonDefinition)
      .flatMap('sections')
      .flatMap('fields')
      .filter(f => f.config.fieldName === 'title')
      .find(f => f.type === 'select-field');
    return {
      titleSelectField,
      result: !!titleSelectField
    };
  };


  useEffect(() => {

    const nullifySignatureForNonSelected = () => {
      const owners = formik.values.owners;
      _.forEach(owners, (owner, ownerIndex) => {
        if (!_.includes(formik.values[whoIsPresentToSignKey], ownerIndex)) {
          formik.setFieldValue(`owners[${ownerIndex}].${signatureFieldName}`, null);
        }
      });
      if (!_.includes(formik.values[whoIsPresentToSignKey], Constants.WhoIsPresentToSignPOCKey)) {
        formik.setFieldValue(signatureFieldName, null);
      }
    };

    const dynamicSignatureFields = _.map(formik.values[whoIsPresentToSignKey], value => {
      const signatureField = {
        ...dynamicSignatureFieldConfig,
        id: uuidv4(),
        config: {
          ...dynamicSignatureFieldConfig.config,
          fieldName: value === Constants.WhoIsPresentToSignPOCKey
            ? `ownerPcOnly.${signatureFieldName}`
            : `owners[${value}].${signatureFieldName}`,
        },
      };

      const titleSelectFieldResult = checkIfTitleSelectFieldUsed();
      const titleField = {
        ...dynamicSignatureTitleFieldConfig,
        id: uuidv4(),
        type: titleSelectFieldResult.result ? 'select-field' : 'text-field',
        config: {
          ...dynamicSignatureTitleFieldConfig.config,
          fieldName: value === Constants.WhoIsPresentToSignPOCKey
            ? `ownerPcOnly.title`
            : `owners[${value}].title`,
          fieldOptions: titleSelectFieldResult.result
            ? titleSelectFieldResult.titleSelectField.config.fieldOptions
            : []
        },
      };
      return [signatureField, titleField];
    });
    setDynamicSignatureFields(dynamicSignatureFields);
    nullifySignatureForNonSelected();
  }, [formik.values[whoIsPresentToSignKey]]);

  useEffect(() => {
    if (!isNeedToShowRequireAgreementsCheckbox) {
      return;
    }
    const signatureAgreementsStateToSet: any = {};
    _.forEach(formik.values[whoIsPresentToSignKey], key => {
      signatureAgreementsStateToSet[key] = _.has(signatureAgreementsState, key)
        ? signatureAgreementsState[key]
        : false;
    });
    setSignatureAgreementsState(signatureAgreementsStateToSet);
  }, [formik.values[whoIsPresentToSignKey]]);

  useEffect(() => {
    if (!isNeedToShowRequireAgreementsCheckbox) {
      return;
    }
    const isEveryChecked = _.every(_.values(signatureAgreementsState));
    formik.setFieldError(formikErrorKey, isEveryChecked ? '' : 'required');
  }, [signatureAgreementsState]);

  useEffect(() => {
    if (!isNeedToShowRequireAgreementsCheckbox) {
      return;
    }
    if (highlightErrorFieldsState.state) {
      const signatureAgreementsInvalidStateToSet: any = {};
      _.forEach(formik.values[whoIsPresentToSignKey], key => {
        signatureAgreementsInvalidStateToSet[key] = !signatureAgreementsState[key];
      });
      setSignatureAgreementsInvalidState(signatureAgreementsInvalidStateToSet);
    }
  }, [highlightErrorFieldsState]);

  useEffect(() => {
    return () => {
      formik.setFieldError(formikErrorKey, '');
    };
  }, []);

  useEffect(() => {
    if (!isNeedToShowRequireAgreementsCheckbox) {
      return;
    }
    if (!itemData.config.displayed) {
      formik.setFieldError(formikErrorKey, '');
    }
  }, [itemData.config.displayed]);

  return (
    dynamicSignatureFields.map((fieldsPair: any, idx: number) => {
      const signatureField = fieldsPair[0];
      const titleField = fieldsPair[1];
      const signatureFieldName = signatureField.config.fieldName;
      const titleFieldName = titleField.config.fieldName;

      const currentWhoIsPresentToSignKey = formik.values[whoIsPresentToSignKey][idx];
      const isSignFieldsHidden = isNeedToShowRequireAgreementsCheckbox && !signatureAgreementsState[currentWhoIsPresentToSignKey];
      const currentWhoIsPresentToSignFullName = getOwnerFullNameByWhoIsPresentToSignKey(currentWhoIsPresentToSignKey, formik);
      const requireAgreementsCheckboxLabel = `${_.replace(fieldConfig.signaturesRequireAgreementsOfElectronicSignatureMessage || '', '{full_name}', currentWhoIsPresentToSignFullName)} *`;
      return (
        <Box
          key={`${signatureFieldName}-${titleFieldName}`}
          component={Grid}
          container
          item
          xs={12}
          spacing={4}
        >
          {isNeedToShowRequireAgreementsCheckbox &&
            <Box
              component={Grid}
              item
              xs={12}
            >
              <FormControl
                component="fieldset"
                required
                fullWidth={true}
                error={signatureAgreementsInvalidState[currentWhoIsPresentToSignKey]}
              >
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        checked={signatureAgreementsState[currentWhoIsPresentToSignKey]}
                        onChange={(event) => {
                          setSignatureAgreementsInvalidState((prev: any) => {
                            return { ...prev, [currentWhoIsPresentToSignKey]: !event.target.checked }
                          });
                          setSignatureAgreementsState((prev: any) => {
                            return { ...prev, [currentWhoIsPresentToSignKey]: !prev[currentWhoIsPresentToSignKey] }
                          });
                        }}
                      />
                    }
                    className={classes.requireAgreementsCheckboxLabel}
                    style={{ color: signatureAgreementsInvalidState[currentWhoIsPresentToSignKey] ? '#d32f2f' : 'inherit' }}
                    label={requireAgreementsCheckboxLabel}
                    labelPlacement="end"
                  />
                </FormGroup>
              </FormControl>
            </Box>
          }
          {!isSignFieldsHidden && <>
            <SignField itemData={signatureField} value={_.get(formik, `values.${signatureFieldName}`)} locale={locale} />
            {(!isIndividualApp && !isPersonalGuaranteeSignaturesSection) && <Element item={titleField} locale={locale} externalData={externalData} />}
          </>
          }
        </Box>
      );
    })
  );
};
