import { Checkbox, FormControlLabel, Grid } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import * as React from 'react';
import { useState, useEffect, useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@mui/styles';
import { FormikProps, useFormikContext } from 'formik';
import { CommonDataContext } from '../../context';
import _ from 'lodash';

const useStyles = makeStyles(() => ({
  iAcceptText: {
    fontWeight: 'lighter',
    margin: '0 4px 0 0 !important',
    display: 'inline-block',
  },
  termsAndConditionText: {
    fontWeight: 'bolder',
    cursor: 'pointer',
    textDecoration: 'underline',
  },
}));

export const TermsAndConditions = ({
  isConsumerCreditApplication,
  customTermsAndConditionsLanguage,
  dcrDisclosureUseDefaultTerms,
  sectionConfig,
}: {
  isConsumerCreditApplication?: boolean;
  customTermsAndConditionsLanguage: string;
  dcrDisclosureUseDefaultTerms: boolean;
  sectionConfig: any;
}) => {
  const classes = useStyles();
  const formik: FormikProps<any> = useFormikContext();
  const [isTermsAndConditionsModalOpen, setIsTermsAndConditionsModalOpen] = useState(false);
  const [value, setValue] = useState(false);
  const { highlightErrorFieldsState } = useContext(CommonDataContext);
  const [isInvalid, setIsInvalid] = useState(false);
  const [isTermsAndConditionsViewed, setIsTermsAndConditionsViewed] = useState(false);

  const requireTermsAndConditionsView = _.get(sectionConfig, 'config.requireTermsAndConditionsView');
  const fieldName = 'acceptedTermsAndConditions';

  const handleAccept = () => {
    const newValue = !value;
    setValue(newValue);
    setIsInvalid(!newValue);
    formik.setFieldError(fieldName, !newValue ? 'required' : '');
  };

  const renderDefaultTermsAndConditionsLanguage = () => {
    return (
      <>
        <FormattedMessage
          id="termsAndConditions1"
          defaultMessage={'termsAndConditions1'}
        />{' '}
        <a
          title="Privacy and Security"
          href="https://trnsact.com/privacy-policy/"
          target="_blank"
        >
          https://trnsact.com/privacy-policy/
        </a>{' '}
        <FormattedMessage
          id="termsAndConditions2"
          defaultMessage={'termsAndConditions2'}
        />
        <FormattedMessage
          id="termsAndConditions3"
          defaultMessage={'termsAndConditions3'}
        />
        {isConsumerCreditApplication ? (
          <div>
            <br />
            Additionally, by signing you agree to the following statement:{' '}
            <br />
            <br />
            I authorize Trnsact and/or Dealer, as my agent, to: (i) submit
            information from this Application to The Lenders on my behalf;
            (ii) certify to The Lenders the accuracy of the information;
            and (iii) convey to The Lenders my consent to any policies,
            notices, agreements or other terms disclosed by The Lenders to
            applicants as part of The Lenders’ application process. I have
            read this Application and everything stated in it is true to
            the best of my ability. I authorize The Lenders to check my
            credit, employment history, or any other information, and to
            report such information, and its credit experience with me, to
            others. I am at least 18 years of age. Alimony, child support
            or separate maintenance income need not be revealed if you do
            not wish to have it considered as a basis for repaying this
            obligation.
            <br />
            I hereby certify that the property purchased pursuant to this
            application is for my personal and/or business use; that I am
            fully responsible for making all payments for such property;
            that such property will be in my possession or under my
            control until the amount financed and all finance charges have
            been paid in full; and that I am not purchasing any property
            financed through The Lenders for the benefit or use of another
            without the prior written approval of The Lenders.
            <br />
            IMPORTANT INFORMATION ABOUT ACCOUNT OPENING PROCEDURES:
            Federal law requires all financial institutions to obtain,
            verify, and record information that identifies each person who
            requests to open an account prior to account opening.
            <br />
            WHAT THIS MEANS TO YOU: When you apply for credit, we will ask
            for your name, address, date of birth, and other information
            that will allow us to identify you. We may also ask to see
            your driver's license or other identifying documents. Failure
            to provide the required information may result in denial of
            your request to open an account.
          </div>
        ) : null}
      </>
    )
  };

  const renderCustomTermsAndConditionsLanguage = () => {
    return (
      <div
        dangerouslySetInnerHTML={{
          __html: customTermsAndConditionsLanguage,
        }}
      ></div>
    )
  };

  useEffect(() => {
    if (isTermsAndConditionsModalOpen) {
      setIsTermsAndConditionsViewed(true);
    }
  }, [isTermsAndConditionsModalOpen]);

  useEffect(() => {
    formik.setFieldError(fieldName, !value ? 'required' : '');
    return () => {
      formik.setFieldError(fieldName, '');
    };
  }, []);

  useEffect(() => {
    if (highlightErrorFieldsState.state) {
      setIsInvalid(!!formik.getFieldMeta(fieldName).error);
    }
  }, [highlightErrorFieldsState]);

  return (
    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
      <React.Fragment>
        <Grid container>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <FormControl required error={isInvalid}>
              <FormControlLabel
                control={
                  <Checkbox
                    value={value}
                    name={fieldName}
                    checked={value}
                    onChange={handleAccept}
                  />
                }
                onClick={() => (requireTermsAndConditionsView && !isTermsAndConditionsViewed) ? setIsTermsAndConditionsModalOpen(true) : ''}
                className={classes.iAcceptText}
                label={
                  <div style={{ color: isInvalid ? '#d32f2f' : 'inherit' }}>
                    <FormattedMessage id={'iAccept'} defaultMessage={'iAccept'} />
                    {' '}
                    <span
                      className={classes.termsAndConditionText}
                      onClick={() => setIsTermsAndConditionsModalOpen(true)}
                    >
                      <FormattedMessage
                        id={'termsAndCondition'}
                        defaultMessage={'termsAndCondition'}
                      />
                    </span>
                    {' *'}
                  </div>
                }
              />
              {/* isInvalid && <FormHelperText>Required</FormHelperText> */}
            </FormControl>
          </Grid>
        </Grid>

        <Dialog
          fullWidth={true}
          maxWidth={'md'}
          open={isTermsAndConditionsModalOpen}
          onClose={() => setIsTermsAndConditionsModalOpen(false)}
        >
          <DialogTitle>
            <FormattedMessage
              id={'dcrTermsAndConditions'}
              defaultMessage={'dcrTermsAndConditions'}
            />
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {dcrDisclosureUseDefaultTerms && renderDefaultTermsAndConditionsLanguage()}
              {customTermsAndConditionsLanguage && renderCustomTermsAndConditionsLanguage()}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant={'outlined'}
              color={'primary'}
              onClick={() => setIsTermsAndConditionsModalOpen(false)}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    </Grid>
  );
};
