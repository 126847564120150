import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import { FormikProps, useFormikContext } from 'formik';
import _ from 'lodash';
import { useCallback, useEffect, useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { FieldType } from '../types';
import { makeStyles } from '@mui/styles';
import { CommonDataContext } from '../../context';

const useStyles = makeStyles(() => ({
  selectHeight: {
    maxHeight: '40vh',
  },
}));
const SignorAvailabilityQuestion = ({
  itemData,
  value,
  locale,
}: {
  itemData: FieldType;
  value: any;
  locale: string;
}) => {
  const { id: fieldId, config: fieldConfig } = itemData;
  const classes = useStyles();
  const formik: FormikProps<any> = useFormikContext();
  const { isMobileView } = useContext(CommonDataContext);

  const handleChange = useCallback(
    (e) => {
      formik.setFieldValue(itemData.config.fieldName, e.target.value, false);
    },
    [formik]
  );
  const _resetField = (): void => {
    // Check if error is already set to ''
    if (Boolean(_.get(formik.errors, itemData.config.fieldName))) {
      //Remove Error
      formik.setFieldError(itemData.config.fieldName, '');
    }

    if (!_.has(formik.values, itemData.config.fieldName)) { // do not set '' value for untouched fields
      return;
    }

    //Erase value
    formik.setFieldValue(itemData.config.fieldName, '');
  };

  useEffect(() => {
    return () => {
      formik.setFieldError(itemData.config.fieldName, '');
    };
  }, []);

  useEffect(() => {
    //When there is no need to display the field, we reset it's state to a clean one
    if (!itemData.config.displayed) {
      _resetField();
    } else {
      let isEmpty = false;
      if (itemData.config.required) {
        isEmpty = _.isEmpty(value);
      }
      formik.setFieldError(
        itemData.config.fieldName,
        isEmpty ? 'required' : ''
      );
    }
  }, [itemData.config.displayed, itemData.config.required, value]);

  const moreThanOneOwner = parseInt(_.get(formik, 'values.owners.length', 0));
  const indexPresentToSign =
    _.get(formik, 'values.whoIsPresentToSign') == '0' ? '1' : '0';

  const ownersFullName = `${_.get(
    formik,
    `values.owners[${indexPresentToSign}].firstName`
  )} ${_.get(formik, `values.owners[${indexPresentToSign}].lastName`)}`;
  const ownerQuestionWithName = (
    <FormattedMessage
      id={'ownerAvailable'}
      defaultMessage={'ownerAvailable'}
      values={{
        name: ownersFullName,
      }}
    />
  );

  if (moreThanOneOwner > 1) {
    return (
      <Box
        component={Grid}
        item
        xl={_.get(fieldConfig, 'columns.xl', 12)}
        lg={_.get(fieldConfig, 'columns.lg', 12)}
        md={_.get(fieldConfig, 'columns.md', 12)}
        sm={_.get(fieldConfig, 'columns.sm', 12)}
        xs={_.get(fieldConfig, 'columns.xs', 12)}
        display={{
          xl: !itemData.config.displayed ? 'none' : 'block',
          lg: !itemData.config.displayed ? 'none' : 'block',
          md: !itemData.config.displayed ? 'none' : 'block',
          sm: !itemData.config.displayed ? 'none' : 'block',
          xs: !itemData.config.displayed ? 'none' : 'block',
        }}
      >
        <FormControl fullWidth={fieldConfig.fullWidth}>
          <InputLabel
            id={fieldConfig.fieldName}
            required={fieldConfig.required}
          >
            {ownerQuestionWithName}
          </InputLabel>
          <Select
            key={fieldId}
            fullWidth={fieldConfig.fullWidth}
            name={fieldConfig.fieldName}
            disabled={fieldConfig.disabled}
            value={value ? value : ''}
            onChange={handleChange}
            labelId={fieldConfig.fieldName}
            style={{ maxHeight: '40vh' }}
            required={fieldConfig.required}
            variant={isMobileView ? "outlined" : "standard"}
          >
            <MenuItem key={`${fieldId}.yes`} value={`yes`}>
              <FormattedMessage id={'yes'} defaultMessage={'yes'} />
            </MenuItem>
            <MenuItem key={`${fieldId}.no`} value={`no`}>
              <FormattedMessage id={'no'} defaultMessage={'no'} />
            </MenuItem>
          </Select>
          <FormHelperText>{ownerQuestionWithName}</FormHelperText>
        </FormControl>
      </Box>
    );
  } else {
    return null;
  }
};

export { SignorAvailabilityQuestion };
