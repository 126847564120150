import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';
import { useContext } from 'react';
import { VendorContext } from '../../context';
import styles from '../../CreditApplicationFormWrapper.module.css';
import _ from 'lodash';
const config = require('../../config');


const useStyles = makeStyles(() => ({
  mainContainer: {
    borderTopLeftRadius: "8px",
    borderTopRightRadius: "8px",
    padding: "30px 0"
  },
  mainLogoContainer: {
    display: "flex",
    justifyContent: "center",
  },
  mainLogoImg: {
    height: "90px",
    maxWidth: "100%",
  },
  taskContainer: {
    backgroundColor: "white",
    position: "static",
    marginBottom: "-6px"
  },
}));

const MainHeader = ({
  partner,
  isLenderDealerCoBranded,
  coBrandingSettings,
  isTaskPage,
  publicAccount
}: {
  partner: any;
  isLenderDealerCoBranded?: boolean;
  coBrandingSettings?: any;
  isTaskPage?: boolean;
  publicAccount?: any;
}) => {
  const { color: vendorColor } = useContext(VendorContext);
  const classes = useStyles({ vendorColor });

  /*   if (_.isEmpty(partner) && !isLenderDealerCoBranded) {
      return null;
    } */
  return (
    <>
      {partner && (
        <Grid container className={classes.mainContainer} style={{ backgroundColor: vendorColor }}>
          {partner && partner.partnerLogo && (
            <Grid item xs={12} className={classes.mainLogoContainer}>
              <img className={classes.mainLogoImg}
                src={`https://${config.S3_BUCKET_NAME}.s3-us-west-2.amazonaws.com/${partner.partnerLogo}`}
                alt="Partner Logo"
              />
            </Grid>
          )}
        </Grid>
      )}
      {isLenderDealerCoBranded && coBrandingSettings && (
        <Grid container
          className={classes.mainContainer}
          style={{ backgroundColor: coBrandingSettings.colorSchema.color }}
        >
          {coBrandingSettings.headerLogo && (
            <Grid item xs={12} className={classes.mainLogoContainer}>
              <img className={classes.mainLogoImg}
                src={`https://${config.S3_BUCKET_NAME}.s3-us-west-2.amazonaws.com/${coBrandingSettings.headerLogo}`}
                alt="Vendor Logo"
              />
            </Grid>
          )}
        </Grid>
      )}
      {isTaskPage && publicAccount?.vendorProfile?.logo &&
        <Grid container
          className={[classes.mainContainer, classes.taskContainer].join(' ')}
        >
          <Grid item xs={12} className={classes.mainLogoContainer}>
            <img className={classes.mainLogoImg}
              src={`https://${config.S3_BUCKET_NAME}.s3-us-west-2.amazonaws.com/${publicAccount.vendorProfile.logo}`}
              alt="VP Logo"
            />
          </Grid>
        </Grid>
      }
    </>
  )
};

export default MainHeader;
