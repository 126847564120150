import Mixpanel from "mixpanel-browser";
import { MIXPANEL_TOKEN, APP_ENV } from "./config";


const noop = () => { };

let mixpanel: any;
// setting Mixpanel for prod env and if the token is set
if (APP_ENV === 'production' && MIXPANEL_TOKEN) {
  Mixpanel.init(MIXPANEL_TOKEN);
  mixpanel = Mixpanel;
} else {
  // else setting a mock to prevent errors
  mixpanel = {
    init: noop,
    track: noop,
    register: noop,
    register_once: noop,
    reset: noop,
    get_property: noop,
    unregister: noop,
  };
}

export default mixpanel;
