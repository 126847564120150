import React, { useCallback, useEffect, useState, useContext } from 'react';
import { Box, Grid, TextField, InputLabel } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FormikProps, useFormikContext } from 'formik';
import { FieldType, FormGenericValues } from '../types';
import { CommonDataContext } from '../../context';
import { upperCaseFirstLetter, getOwnerFullNameByWhoIsPresentToSignKey } from '../../helpers';
import _ from 'lodash';
import { Constants } from '../../constants';

const useStyles = makeStyles(() => ({
  signFieldStyles: {
    '& .MuiInput-root': {
      fontFamily: "'Homemade Apple', cursive",
    },
    '& .MuiInput-input': {
      lineHeight: '100%',
      textIndent: '12px',
      paddingBottom: '6px',
    },
  },
}));

export const SignField = ({
  itemData,
  value,
  locale,
  displayLabel,
  isNeedToMoveLabelOutOfInput,
}: {
  itemData: FieldType;
  value: any;
  locale: string;
  displayLabel?: string;
  isNeedToMoveLabelOutOfInput?: boolean;
}) => {
  const { id: fieldId, config: fieldConfig } = itemData;
  const classes = useStyles();
  const formik: FormikProps<any> = useFormikContext();
  const { isMobileView } = useContext(CommonDataContext);
  const [isInvalid, setIsInvalid] = useState(false);
  displayLabel = displayLabel || _.get(fieldConfig, `displayLabel.${locale}`, '');

  const handleChange = useCallback((e) => {
    const upperCasedValue = upperCaseFirstLetter(e.target.value);
    setFieldValue(upperCasedValue);
  }, []);

  const handleBlur = useCallback((e) => {
    const trimmedValue = _.trim(e.target.value);
    setFieldValue(trimmedValue);
  }, []);

  const setFieldValue = (valueToSet: string) => {
    //e.target.value = valueToSet;
    formik.setFieldValue(itemData.config.fieldName, valueToSet, false);
  };

  const _preloadSignature = () => {
    const isOwnersSignature = _.includes(itemData.config.fieldName, 'owners');
    const whoIsPresentToSignKey = isOwnersSignature
      ? itemData.config.fieldName.replace(/[^0-9]/g, '')
      : Constants.WhoIsPresentToSignPOCKey;
    const signorFullName = getOwnerFullNameByWhoIsPresentToSignKey(whoIsPresentToSignKey, formik);
    formik.setFieldValue(itemData.config.fieldName, signorFullName);
  };

  const _resetField = (): void => {
    // Check if error is already set to ''
    if (Boolean(_.get(formik.errors, itemData.config.fieldName))) {
      //Remove Error
      formik.setFieldError(itemData.config.fieldName, '');
    }

    if (!_.has(formik.values, itemData.config.fieldName)) { // do not set '' value for untouched fields
      return;
    }

    //Erase value
    formik.setFieldValue(itemData.config.fieldName, '');
  };

  useEffect(() => {
    _preloadSignature();
    return () => {
      formik.setFieldError(itemData.config.fieldName, '');
    };
  }, []);

  useEffect(() => {
    //When there is no need to display the field, we reset it's state to a clean one
    if (!itemData.config.displayed) {
      _resetField();
    } else {
      let isEmpty = false;
      if (itemData.config.required) {
        isEmpty = _.isEmpty(value);
        setIsInvalid(isEmpty);
      }
      formik.setFieldError(
        itemData.config.fieldName,
        isEmpty ? 'required' : ''
      );
    }
  }, [itemData.config.displayed, itemData.config.required, value]);

  return (
    <Box
      component={Grid}
      item
      xl={_.get(fieldConfig, 'columns.xl', 12)}
      lg={_.get(fieldConfig, 'columns.lg', 12)}
      md={_.get(fieldConfig, 'columns.md', 12)}
      sm={_.get(fieldConfig, 'columns.sm', 12)}
      xs={_.get(fieldConfig, 'columns.xs', 12)}
      display={{
        xl: !itemData.config.displayed ? 'none' : 'block',
        lg: !itemData.config.displayed ? 'none' : 'block',
        md: !itemData.config.displayed ? 'none' : 'block',
        sm: !itemData.config.displayed ? 'none' : 'block',
        xs: !itemData.config.displayed ? 'none' : 'block',
      }}
    >
      {isNeedToMoveLabelOutOfInput && <InputLabel className='outer-label'>{displayLabel}</InputLabel>}
      <TextField
        key={fieldId}
        name={fieldConfig.fieldName}
        value={value ? value : ''}
        disabled={fieldConfig.disabled}
        className={classes.signFieldStyles}
        fullWidth={fieldConfig.fullWidth}
        variant={isMobileView ? "outlined" : "standard"}
        label={isNeedToMoveLabelOutOfInput ? '' : displayLabel}
        helperText={_.get(fieldConfig, `helperText.${locale}`, '')}
        error={isInvalid}
        required={fieldConfig.required}
        onChange={handleChange}
        onBlur={handleBlur}
      />
    </Box>
  );
};
