import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import { FormikProps, useFormikContext } from 'formik';
import _ from 'lodash';
import { useCallback, useEffect, useState, useContext } from 'react';
import { CommonDataContext } from '../../context';
import { FieldType } from '../types';
import { Constants } from "../../constants";
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  menuItem: {
    '&.MuiMenuItem-root': {
      whiteSpace: 'normal',
    }
  },
}));
const SelectField = ({
  itemData,
  value,
  locale,
  displayLabel,
  isNeedToMoveLabelOutOfInput,
  externalData,
}: {
  itemData: FieldType;
  value: any;
  locale: string;
  displayLabel: string;
  isNeedToMoveLabelOutOfInput: boolean;
  externalData?: any
}) => {
  const { id: fieldId, config: fieldConfig } = itemData;
  const classes = useStyles();
  const formik: FormikProps<any> = useFormikContext();
  const { isMobileView, highlightErrorFieldsState } = useContext(CommonDataContext);
  const [isInvalid, setIsInvalid] = useState(false);
  const visibleOptions = _.reject(fieldConfig.fieldOptions, 'isHidden');

  const checkIfValueSet = (value: string | number | boolean): boolean => _.isBoolean(value) || !!value || value === 0;

  const evaluateIfInvalid = (value: string | number | boolean): boolean => {
    /*     if (itemData.config.fieldName === 'addendum' && _.get(externalData, 'sectionConfig.sectionType') !== Constants.DocumentSignSectionType) {
          return value !== 'accept';
        } */
    if (itemData.config.fieldName === 'addendum' && _.get(externalData, `sectionConfig.config.requireAcceptanceToProceed`)) {
      return value !== 'accept';
    }
    const isValueSet = checkIfValueSet(value);
    const isRequiredAndEmpty = !!fieldConfig.required && !isValueSet;
    return isRequiredAndEmpty;
  };

  const handleChange = useCallback(
    (e) => {
      formik.setFieldValue(itemData.config.fieldName, e.target.value, false);
      setIsInvalid(evaluateIfInvalid(e.target.value));
    },
    [formik]
  );
  const _resetField = (): void => {
    // Check if error is already set to ''
    if (Boolean(_.get(formik.errors, itemData.config.fieldName))) {
      //Remove Error
      formik.setFieldError(itemData.config.fieldName, '');
    }
    if (!_.has(formik.values, itemData.config.fieldName)) { // do not set '' value for untouched fields
      return;
    }
    //Erase value
    formik.setFieldValue(itemData.config.fieldName, null);
  };

  useEffect(() => {
    if (value) {
      setIsInvalid(evaluateIfInvalid(value));
    }
    return () => {
      formik.setFieldError(itemData.config.fieldName, '');
    };
  }, []);

  useEffect(() => {
    //When there is no need to display the field, we reset it's state to a clean one
    if (!itemData.config.displayed) {
      _resetField();
    } else {
      let isEmpty = false;
      if (itemData.config.required) {
        isEmpty = !checkIfValueSet(value)
      }

      formik.setFieldError(
        itemData.config.fieldName,
        isInvalid || isEmpty ? 'required' : ''
      );
    }
  }, [itemData.config.displayed, itemData.config.required, isInvalid, value]);

  useEffect(() => {
    if (highlightErrorFieldsState.state) {
      setIsInvalid(!!formik.getFieldMeta(itemData.config.fieldName).error);
    }
  }, [highlightErrorFieldsState]);

  return (
    <Box
      component={Grid}
      item
      xl={_.get(fieldConfig, 'columns.xl', 12)}
      lg={_.get(fieldConfig, 'columns.lg', 12)}
      md={_.get(fieldConfig, 'columns.md', 12)}
      sm={_.get(fieldConfig, 'columns.sm', 12)}
      xs={_.get(fieldConfig, 'columns.xs', 12)}
      display={{
        xl: !itemData.config.displayed ? 'none' : 'inline-block',
        lg: !itemData.config.displayed ? 'none' : 'inline-block',
        md: !itemData.config.displayed ? 'none' : 'inline-block',
        sm: !itemData.config.displayed ? 'none' : 'inline-block',
        xs: !itemData.config.displayed ? 'none' : 'inline-block',
      }}
    >
      {isNeedToMoveLabelOutOfInput &&
        <InputLabel className='outer-label' id={fieldConfig.fieldName} required={fieldConfig.required}>
          {displayLabel}
        </InputLabel>
      }
      <FormControl variant={isMobileView ? "outlined" : "standard"} fullWidth={fieldConfig.fullWidth} error={isInvalid}>
        {!isNeedToMoveLabelOutOfInput &&
          <InputLabel id={fieldConfig.fieldName} required={fieldConfig.required}>
            {displayLabel}
          </InputLabel>
        }
        <Select
          key={fieldId}
          fullWidth={fieldConfig.fullWidth}
          name={fieldConfig.fieldName}
          disabled={fieldConfig.disabled}
          value={(value || value === false) ? value : ''}
          onChange={handleChange}
          label={isNeedToMoveLabelOutOfInput ? '' : displayLabel}
          labelId={fieldConfig.fieldName}
          required={fieldConfig.required}
          variant={isMobileView ? "outlined" : "standard"}
        >
          {visibleOptions.map((option: any, index: number) => {
            //Preferred label by Lang
            let displayedLabel = _.get(option, `displayLabel.${locale}`, '');
            //If no lang provided, defaults into generic label
            if (!displayedLabel) {
              displayedLabel = _.get(option, 'optionLabel', '');
            }
            return (
              <MenuItem key={`${fieldId}${index}`} value={option.optionValue} className={classes.menuItem}>
                {displayedLabel}
              </MenuItem>
            );
          })}
        </Select>
        <FormHelperText>
          {isInvalid && _.get(fieldConfig, `errorMessage.${locale}`, '')}
          {_.get(fieldConfig, `helperText.${locale}`, '')}
        </FormHelperText>
      </FormControl>
    </Box>
  );
};

export { SelectField };
