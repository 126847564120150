import {
  Box,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
} from '@mui/material';
import { FormikProps, useFormikContext } from 'formik';
import _ from 'lodash';
import { useCallback, useEffect, useContext, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { FieldOptionsType, FieldType } from '../types';
import { makeStyles } from '@mui/styles';
import { Constants } from "../../constants"
import { MultiselectField } from './MultiselectField';
import { CommonDataContext } from '../../context';
import { getAvailableToSignPersonsFieldOptions } from '../../helpers';

const useStyles = makeStyles(() => ({
  selectHeight: {
    maxHeight: '40vh',
  },
}));
const SignatureselectField = ({
  itemData,
  value,
  locale,
  externalData,
  displayLabel,
  isNeedToMoveLabelOutOfInput,
}: {
  itemData: FieldType;
  value: any;
  locale: string;
  externalData: any;
  displayLabel: string;
  isNeedToMoveLabelOutOfInput: boolean;
}) => {
  const { id: fieldId, config: fieldConfig } = itemData;
  const classes = useStyles();
  const formik: FormikProps<any> = useFormikContext();
  const { isMobileView } = useContext(CommonDataContext);
  const [fieldOptions, setFieldOptions] = useState<FieldOptionsType[] | undefined>();
  const [sendSignaturesTasksConfiguration, setSendSignaturesTasksConfiguration] = useState<any>({});
  const [nonSignors, setNonSignors] = useState<FieldOptionsType[]>([]);

  const isDynamicSignaturesSection = _.get(externalData, "sectionConfig.sectionType") === Constants.DynamicSignaturesSectionType;
  const isPersonalGuaranteeSignaturesSection = _.get(externalData, "sectionConfig.sectionType") === Constants.PersonalGuaranteeSignaturesSectionType;
  const isIndividualApp = _.get(externalData, 'isIndividualApp');
  const sendSignaturesTasksMappingKey = isDynamicSignaturesSection
    ? 'sendCreditApplicationSignaturesTasks'
    : 'sendPersonalGuaranteeSignaturesTasks';

  const handleChange = useCallback(
    (e) => {
      formik.setFieldValue(itemData.config.fieldName, e.target.value, false);
    },
    [formik]
  );
  const _resetField = (): void => {
    // Check if error is already set to ''
    if (Boolean(_.get(formik.errors, itemData.config.fieldName))) {
      //Remove Error
      formik.setFieldError(itemData.config.fieldName, '');
    }

    if (!_.has(formik.values, itemData.config.fieldName)) { // do not set '' value for untouched fields
      return;
    }

    //Erase value
    formik.setFieldValue(itemData.config.fieldName, '');
  };

  const checkIfIsValidByRequireToSignSetting = () => {
    const owners = _.get(formik, 'values.owners', []);
    const ownerIndexes = _.map(_.range(0, _.size(owners)), _.toString);
    const isAllOwnersSigned = _.isEmpty(_.difference(ownerIndexes, value));
    if (fieldConfig.signaturesRequiredSetting === 'all-owners-require-to-sign') {
      return isAllOwnersSigned;
    }
    if (fieldConfig.signaturesRequiredSetting === 'one-owner-require-to-sign') {
      return _.some(ownerIndexes, ownerIdx => _.includes(value, ownerIdx));
    }
    if (fieldConfig.signaturesRequiredSetting === 'one-owner-or-poc-require-to-sign') {
      return _.some(ownerIndexes, ownerIdx => _.includes(value, ownerIdx)) || _.includes(value, Constants.WhoIsPresentToSignPOCKey);
    }
    return true;
  };

  // we need to depend both on ownersQuantity and on initial render
  const handleSettingFieldOptions = () => {
    if (isDynamicSignaturesSection || isPersonalGuaranteeSignaturesSection) {
      const fieldOptionsToSet = getAvailableToSignPersonsFieldOptions(formik, isIndividualApp, isPersonalGuaranteeSignaturesSection);
      if (_.isEmpty(fieldOptionsToSet)) {
        return;
      }
      const owners = _.get(formik, 'values.owners', []);
      const whoIsPresentToSign = _.get(formik, fieldConfig.fieldName, []);
      const whoIsPresentToSignAvailableValues = [Constants.WhoIsPresentToSignPOCKey, ..._.keys(owners)]
      formik.setFieldValue(fieldConfig.fieldName, _.filter(whoIsPresentToSign, (v: string) => _.includes(whoIsPresentToSignAvailableValues, v)));
      fieldConfig.fieldOptions = fieldOptionsToSet;
      setFieldOptions(fieldOptionsToSet);
      if (fieldConfig.signaturesSignorsSetting === 'auto-create-signature-task-for-each-signor') {
        const signaturesTasksConfiguration = _.reduce(fieldOptionsToSet, (result: any, fieldOption: any) => {
          return fieldOption.metaData.email
            ? { ...result, [fieldOption.optionValue]: 'open' }
            : result;
        }, {});
        setSendSignaturesTasksConfiguration(signaturesTasksConfiguration);
      }
    }
  };

  useEffect(() => {
    handleSettingFieldOptions();
  }, []);

  useEffect(() => {
    if (formik.values.ownersQuantity) {
      handleSettingFieldOptions();
    }
  }, [formik.values.ownersQuantity]);

  useEffect(() => {
    const nonSignorsToSet = _.isEmpty(value)
      ? fieldOptions || []/* [] */
      : _.reject(fieldOptions, fieldOption => _.includes(value, fieldOption.optionValue));
    setNonSignors(nonSignorsToSet);

    const signaturesTasksConfiguration = _.reduce(fieldOptions, (result: any, option: any) => {
      if (!option.metaData.email) {
        return result;
      }

      const isNonSignor = _.includes(nonSignorsToSet, option);
      let valueToSet;

      if (!isNonSignor) {
        valueToSet = 'complete';
      }
      if (fieldConfig.signaturesSignorsSetting === 'auto-create-signature-task-for-non-signors' && isNonSignor) {
        valueToSet = 'open';
      }
      if (fieldConfig.signaturesSignorsSetting === 'auto-create-signature-task-for-each-signor') {
        valueToSet = isNonSignor ? 'open' : 'complete';
      }
      return valueToSet
        ? { ...result, [option.optionValue]: valueToSet }
        : result;
    }, {});
    setSendSignaturesTasksConfiguration(signaturesTasksConfiguration);

  }, [value]);

  useEffect(() => {
    formik.setFieldValue(sendSignaturesTasksMappingKey, sendSignaturesTasksConfiguration, false);
  }, [sendSignaturesTasksConfiguration]);

  useEffect(() => {
    //When there is no need to display the field, we reset it's state to a clean one
    if (!itemData.config.displayed) {
      _resetField();
    } else {
      let isEmpty = false;
      if (itemData.config.required) {
        isEmpty = _.isEmpty(value);
      }

      const isValidByRequireToSignSetting = checkIfIsValidByRequireToSignSetting();
      formik.setFieldError(
        itemData.config.fieldName,
        (isEmpty || !isValidByRequireToSignSetting) ? 'required' : ''
      );
    }
  }, [itemData.config.displayed, itemData.config.required, value]);

  const getFieldLabelLayout = () => {
    return (
      <InputLabel className='outer-label' id={fieldConfig.fieldName} required={fieldConfig.required}>
        {displayLabel}
      </InputLabel>
    );
  };

  if (!_.every(_.get(formik, 'values.owners'))) {
    return null;  // hotfix - sometimes owners[0] can be null
  }

  const getSendingAuthorizationLinksLayout = () => {
    if (
      !fieldConfig.signaturesSignorsSetting ||
      fieldConfig.signaturesSignorsSetting === 'not-set' ||
      fieldConfig.signaturesSignorsSetting === 'auto-create-signature-task-for-each-signor' ||
      _.isEmpty(nonSignors) ||
      _.isEmpty(value)
    ) {
      return null;
    }
    return (
      <Grid item container style={{ zIndex: 1 }}>
        {_.map(nonSignors, nonSignor => {
          const label = nonSignor.metaData.email
            ? `Send Signor Authorization Link to ${nonSignor.displayLabel.en} (${nonSignor.metaData.email})`
            : `No email provided for ${nonSignor.displayLabel.en} to Send Signor Authorization Link`;
          const isDisabled = fieldConfig.signaturesSignorsSetting === 'auto-create-signature-task-for-non-signors' ||
            !nonSignor.metaData.email;

          return <Grid item xs={12} key={_.toString(nonSignor.optionValue)}>
            <FormGroup row>
              <FormControlLabel
                control={
                  <Switch
                    checked={!!_.get(sendSignaturesTasksConfiguration, _.toString(nonSignor.optionValue))}
                    onChange={(event) => {
                      if (event.target.checked) {
                        const signaturesTasksConfiguration = {
                          ...sendSignaturesTasksConfiguration,
                          [_.toString(nonSignor.optionValue)]: 'open'
                        };
                        setSendSignaturesTasksConfiguration(signaturesTasksConfiguration);
                      } else {
                        const signaturesTasksConfiguration = { ...sendSignaturesTasksConfiguration };
                        //@ts-ignore
                        delete signaturesTasksConfiguration[nonSignor.optionValue];
                        setSendSignaturesTasksConfiguration(signaturesTasksConfiguration);
                      }
                    }}
                    disabled={isDisabled}
                  />
                }
                label={label}
              />
            </FormGroup>
          </Grid>
        })}
      </Grid>
    )
  };

  return (
    (isDynamicSignaturesSection || isPersonalGuaranteeSignaturesSection) && _.some(fieldOptions)
      ? <>
        <MultiselectField
          itemData={itemData}
          value={value}
          locale={locale}
          displayLabel={displayLabel}
          isNeedToMoveLabelOutOfInput={isNeedToMoveLabelOutOfInput}
        />
        {getSendingAuthorizationLinksLayout()}
      </>
      : <Box
        component={Grid}
        item
        xl={_.get(fieldConfig, 'columns.xl', 12)}
        lg={_.get(fieldConfig, 'columns.lg', 12)}
        md={_.get(fieldConfig, 'columns.md', 12)}
        sm={_.get(fieldConfig, 'columns.sm', 12)}
        xs={_.get(fieldConfig, 'columns.xs', 12)}
        display={{
          xl: !itemData.config.displayed ? 'none' : 'block',
          lg: !itemData.config.displayed ? 'none' : 'block',
          md: !itemData.config.displayed ? 'none' : 'block',
          sm: !itemData.config.displayed ? 'none' : 'block',
          xs: !itemData.config.displayed ? 'none' : 'block',
        }}
      >
        {isNeedToMoveLabelOutOfInput && getFieldLabelLayout()}
        <FormControl fullWidth={fieldConfig.fullWidth}>
          {!isNeedToMoveLabelOutOfInput && getFieldLabelLayout()}
          <Select
            key={fieldId}
            fullWidth={fieldConfig.fullWidth}
            name={fieldConfig.fieldName}
            disabled={fieldConfig.disabled}
            value={value ? value : ''}
            onChange={handleChange}
            label={isNeedToMoveLabelOutOfInput ? '' : 'Who is present to sign this application?'}
            labelId={fieldConfig.fieldName}
            style={{ maxHeight: '40vh' }}
            required={fieldConfig.required}
            variant={isMobileView ? "outlined" : "standard"}
          >
            {_.get(formik, 'values.owners', []).map(
              (owner: any, index: number) => {
                return (
                  <MenuItem key={`${fieldId}${index}`} value={`${index}`}>
                    {owner.firstName} {owner.lastName}
                  </MenuItem>
                );
              }
            )}
          </Select>
          <FormHelperText>
            <FormattedMessage
              id={'whoSignsApplication'}
              defaultMessage={'whoSignsApplication'}
            />
          </FormHelperText>
        </FormControl>
      </Box>
  );
};

export { SignatureselectField };
