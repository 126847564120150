import React, { useCallback, useEffect, useState, useContext } from 'react';
import { Box, Grid, TextField, InputLabel } from '@mui/material';
import { FieldType } from '../types';
import { FormikProps, useFormikContext } from 'formik';
import { CommonDataContext } from '../../context';
import _ from 'lodash';
import { isValueBetween } from '../../helpers';

const TypedField = ({
  itemData,
  value,
  locale,
  displayLabel,
  isNeedToMoveLabelOutOfInput,
}: {
  itemData: FieldType;
  value: any;
  locale: string;
  displayLabel: string;
  isNeedToMoveLabelOutOfInput: boolean;
}) => {
  const { id: fieldId, config: fieldConfig } = itemData;
  const { minValue, maxValue } = fieldConfig;
  const [isInvalid, setIsInvalid] = useState(false);
  const formik: FormikProps<any> = useFormikContext();
  const { isMobileView, highlightErrorFieldsState } = useContext(CommonDataContext);

  const evaluateIfInvalid = (value: string): boolean => {
    const isOutOfRange = (value: string): boolean => {
      const intValue = parseInt(value);
      return isNaN(intValue) || !_.isNumber(minValue) || !_.isNumber(maxValue)
        ? false
        : !isValueBetween(intValue, minValue, maxValue);
    };
    const isRequiredAndEmpty = !!fieldConfig.required && _.isEmpty(value);
    return isRequiredAndEmpty || isOutOfRange(value);
  };

  const _resetField = (): void => {
    // Check if error is already set to ''
    if (Boolean(_.get(formik.errors, itemData.config.fieldName))) {
      //Remove Error
      formik.setFieldError(itemData.config.fieldName, '');
    }
    if (!_.has(formik.values, itemData.config.fieldName)) { // do not set '' value for untouched fields
      return;
    }

    //Erase value
    formik.setFieldValue(itemData.config.fieldName, '');
  };

  const handleChange = useCallback((e) => {
    switch (fieldConfig.type) {
      case 'integer':
        formik.setFieldValue(
          itemData.config.fieldName,
          e.target.value.replace(/[^0-9]/g, ''),
          false
        );
        setIsInvalid(evaluateIfInvalid(e.target.value));
        break;
      case 'float':
        formik.setFieldValue(
          itemData.config.fieldName,
          e.target.value.replace(/[^0-9.]/g, '').replace(/\.+/, '.'),
          false
        );
        setIsInvalid(evaluateIfInvalid(e.target.value));
        break;
      default:
        formik.setFieldValue(itemData.config.fieldName, e.target.value, false);
        setIsInvalid(evaluateIfInvalid(e.target.value));
        break;
    }
  }, []);

  useEffect(() => {
    if (value) {
      setIsInvalid(evaluateIfInvalid(value));
    }
    return () => {
      formik.setFieldError(itemData.config.fieldName, '');
    };
  }, []);

  useEffect(() => {
    //When there is no need to display the field, we reset it's state to a clean one
    if (!itemData.config.displayed) {
      _resetField();
    } else {
      let isEmpty = false;
      if (itemData.config.required) {
        isEmpty = _.isEmpty(value);
      }
      formik.setFieldError(
        itemData.config.fieldName,
        isInvalid || isEmpty ? 'required' : ''
      );
    }
  }, [itemData.config.displayed, itemData.config.required, value]);

  useEffect(() => {
    if (highlightErrorFieldsState.state) {
      setIsInvalid(!!formik.getFieldMeta(itemData.config.fieldName).error);
    }
  }, [highlightErrorFieldsState]);

  return (
    <Box
      component={Grid}
      item
      xl={_.get(fieldConfig, 'columns.xl', 12)}
      lg={_.get(fieldConfig, 'columns.lg', 12)}
      md={_.get(fieldConfig, 'columns.md', 12)}
      sm={_.get(fieldConfig, 'columns.sm', 12)}
      xs={_.get(fieldConfig, 'columns.xs', 12)}
      display={{
        xl: !itemData.config.displayed ? 'none' : 'block',
        lg: !itemData.config.displayed ? 'none' : 'block',
        md: !itemData.config.displayed ? 'none' : 'block',
        sm: !itemData.config.displayed ? 'none' : 'block',
        xs: !itemData.config.displayed ? 'none' : 'block',
      }}
    >
      {isNeedToMoveLabelOutOfInput && <InputLabel className='outer-label'>{displayLabel}</InputLabel>}
      <TextField
        key={fieldId}
        disabled={fieldConfig.disabled}
        fullWidth={fieldConfig.fullWidth}
        variant={isMobileView ? "outlined" : "standard"}
        error={isInvalid}
        value={value ? value : ''}
        label={isNeedToMoveLabelOutOfInput ? '' : displayLabel}
        helperText={isInvalid
          ? _.get(fieldConfig, `errorMessage.${locale}`, '')
            .replace('{minRange}', minValue)
            .replace('{maxRange}', maxValue)
          : _.get(fieldConfig, `helperText.${locale}`, '')
        }
        required={fieldConfig.required}
        onChange={handleChange}
      />
    </Box>
  );
};
export { TypedField };
