import { Checkbox, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useState } from 'react';
import { FieldType } from '../types';

import _ from 'lodash';
const useStyles = makeStyles(() => ({
  customCheckbox: {
    '& .MuiCheckbox-root': {
      padding: '0',
    },
  },
  formElement: {
    padding: 10,
  },
  checkboxText: {
    textDecoration: 'underline',
  },
}));

export const CustomCheckbox = ({
  itemData,
  value,
  locale,
}: {
  itemData: FieldType;
  value: any;
  locale: string;
}) => {
  const { id: fieldId, config: fieldConfig } = itemData;
  const classes = useStyles();
  const [checkValue, setCheckValue] = useState(false);
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Grid
      container
      alignItems="center"
      className={`${classes.customCheckbox} ${classes.formElement}`}
    >
      <Checkbox
        color="default"
        key={fieldId}
        checked={checkValue}
        onClick={() => setCheckValue((prevState) => !prevState)}
      />
      <span className={classes.checkboxText} onClick={handleClickOpen}>
        {_.get(fieldConfig, `displayLabel.${locale}`, '')}
      </span>
    </Grid>
  );
};
