import { Box, Grid, TextField, InputLabel } from '@mui/material';
import formatString from 'format-string-by-pattern';
import { FormikProps, useFormikContext } from 'formik';
import _ from 'lodash';
import React, { useCallback, useState, useEffect, useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { CommonDataContext } from '../../context';
import { FieldType } from '../types';
export const SocialsecurityField = ({
  itemData,
  value,
  locale,
  displayLabel,
  isNeedToMoveLabelOutOfInput,
}: {
  itemData: FieldType;
  value: any;
  locale: string;
  displayLabel: string;
  isNeedToMoveLabelOutOfInput: boolean;
}) => {
  const { id: fieldId, config: fieldConfig } = itemData;
  const [isInvalid, setIsInvalid] = useState(false);
  const formik: FormikProps<any> = useFormikContext();
  const { isMobileView, highlightErrorFieldsState } = useContext(CommonDataContext);
  const socialSecurityLength = 11;

  const formattingValue = (value: string) => {
    const formattedValue = value.replace(/[^\d]/g, '');
    if (formattedValue.length > socialSecurityLength) {
      return formattedValue.slice(0, -1);
    }
    return formattedValue;
  };

  const handleChange = useCallback((e) => {
    e.target.value = e.target.value.replace(/[^0-9]/g, '');
    const formattedValue = formattingValue(e.target.value);
    e.target.value = formatString('999-99-9999', formattedValue);
    formik.setFieldValue(itemData.config.fieldName, e.target.value, false);
    setIsInvalid(evaluateIfInvalid(e.target.value));
  }, []);

  const evaluateIfInvalid = (val: string): boolean => {
    const isRequiredAndEmpty = !!fieldConfig.required && _.isEmpty(val);
    return isRequiredAndEmpty || _.size(val) !== socialSecurityLength;
  };

  const _resetField = (): void => {
    // Check if error is already set to ''
    if (Boolean(_.get(formik.errors, itemData.config.fieldName))) {
      //Remove Error
      formik.setFieldError(itemData.config.fieldName, '');
    }

    if (!_.has(formik.values, itemData.config.fieldName)) { // do not set '' value for untouched fields
      return;
    }

    //Erase value
    formik.setFieldValue(itemData.config.fieldName, '');
  };

  useEffect(() => {
    if (value) {
      setIsInvalid(evaluateIfInvalid(value));
    }
    return () => {
      formik.setFieldError(itemData.config.fieldName, '');
    };
  }, []);

  useEffect(() => {
    //When there is no need to display the field, we reset it's state to a clean one
    if (!itemData.config.displayed) {
      _resetField();
    } else {
      let isEmpty = false;
      if (itemData.config.required) {
        isEmpty = _.isEmpty(value);
      }
      formik.setFieldError(
        itemData.config.fieldName,
        isInvalid || isEmpty ? 'required' : ''
      );
    }
  }, [itemData.config.displayed, itemData.config.required, isInvalid, value]);

  useEffect(() => {
    if (highlightErrorFieldsState.state) {
      setIsInvalid(!!formik.getFieldMeta(itemData.config.fieldName).error);
    }
  }, [highlightErrorFieldsState]);

  return (
    <Box
      component={Grid}
      item
      xl={_.get(fieldConfig, 'columns.xl', 12)}
      lg={_.get(fieldConfig, 'columns.lg', 12)}
      md={_.get(fieldConfig, 'columns.md', 12)}
      sm={_.get(fieldConfig, 'columns.sm', 12)}
      xs={_.get(fieldConfig, 'columns.xs', 12)}
      display={{
        xl: !itemData.config.displayed ? 'none' : 'block',
        lg: !itemData.config.displayed ? 'none' : 'block',
        md: !itemData.config.displayed ? 'none' : 'block',
        sm: !itemData.config.displayed ? 'none' : 'block',
        xs: !itemData.config.displayed ? 'none' : 'block',
      }}
    >
      {isNeedToMoveLabelOutOfInput && <InputLabel className='outer-label'>{displayLabel}</InputLabel>}
      <TextField
        key={fieldId}
        name={fieldConfig.fieldName}
        fullWidth={fieldConfig.fullWidth}
        disabled={fieldConfig.disabled}
        variant={isMobileView ? "outlined" : "standard"}
        error={isInvalid}
        helperText={
          isInvalid && (
            <FormattedMessage
              id={'digitsRequiredSSN'}
              defaultMessage={'digitsRequiredSSN'}
              values={{ digits: socialSecurityLength - 2 }}
            />
          )
        }
        label={isNeedToMoveLabelOutOfInput ? '' : displayLabel}
        required={fieldConfig.required}
        value={value ? value : ''}
        onChange={handleChange}
      />
    </Box>
  );
};
