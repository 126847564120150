import React, { useContext } from "react";
import { formatCurrency } from "../../../helpers/stringFormatters";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { makeStyles } from "@mui/styles";
import { getURLParameter } from "../../../helpers";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Modal } from "@mui/material";
import FinanceProgramDescription from "./FinanceProgramDescription";
import ProductLogo from "./ProductLogo";
import ProductCard from "./ProductCard";


interface Props {
  isOpen: boolean,
  handleClose: any,
  menuOption: any,
  handleSubmit: any,
};

const MenuOptionConfirmationModal = ({ isOpen, handleClose, handleSubmit, menuOption }: Props) => {

  const classes = useStyles();
  const viewOnly = getURLParameter("viewOnly");
  const financeProgramDescription = menuOption?.menuOptionDetail;

  if (!menuOption) {
    return null;
  }
  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle >
        <Typography
          variant="h4"
          className={classes.menuOptionName}
          style={{ backgroundColor: menuOption.titleBgColor, color: menuOption.titleColor }}>
          {menuOption.name}
        </Typography>
      </DialogTitle>
      <DialogContent dividers>
        {financeProgramDescription && <FinanceProgramDescription description={financeProgramDescription} />}

        {menuOption.products && (
          <>
            <Typography variant="h6" style={{ marginTop: 16 }}>Products Included</Typography>
            {menuOption.products.map((product: any) => {
              return <Box key={product.proposalProductId} className={classes.productWrapper}>
                <ProductCard product={product} primaryColor={menuOption.titleBgColor} />
              </Box>
            })}
          </>
        )}
        <Grid container /* spacing={1} */>
          <Grid item xs={4} className={classes.conditionContainer}>
            <Typography variant="subtitle2" className={classes.conditionTitle}>Amount Due Today</Typography>
            <Typography variant="h6" className={classes.conditionValue}>$500.00</Typography>
          </Grid>
          <Grid item xs={4} className={classes.conditionContainer}>
            <Typography variant="subtitle2" className={classes.conditionTitle}>Monthly Payment</Typography>
            <Grid container justifyContent="center" alignItems="center" className={classes.conditionValue}>
              <Typography variant="h6">
                {formatCurrency(parseFloat(menuOption.payment.paymentAmountPerPeriod).toFixed(2))}
              </Typography>
              <Typography variant="body2" style={{ marginLeft: 6 }}>per month</Typography>
            </Grid>

          </Grid>
          <Grid item xs={4} className={classes.conditionContainer}>
            <Typography variant="subtitle2" className={classes.conditionTitle}>Term Length</Typography>
            <Grid container justifyContent="center" alignItems="center" className={classes.conditionValue}>
              <Typography variant="h6">
                {menuOption.payment.term}
              </Typography>
              <Typography variant="body2" style={{ marginLeft: 6 }}>per month</Typography>
            </Grid>
          </Grid>
        </Grid>

      </DialogContent>

      <DialogActions className={classes.dialogActions}>
        {!viewOnly && <Button
          variant="contained"
          onClick={() => handleSubmit(menuOption)}
          style={{ color: "white", backgroundColor: "rgba(33, 150, 243, 1)" }}
        >
          Accept Option
        </Button>
        }
        <Button variant="outlined" onClick={handleClose} style={{ color: "rgba(33, 150, 243, 1)", borderColor: "rgba(33, 150, 243, 1)" }}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};


const useStyles = makeStyles(() => {
  return {
    menuOptionName: {
      borderRadius: 4,
      textAlign: "center"
    },
    productWrapper: {
      margin: "12px -4px",
    },
    conditionContainer: {
      background: "#F5F5F5",
      padding: "8px 16px",
    },
    conditionTitle: {
      textAlign: "center",
      borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
      paddingBottom: 8,
    },
    conditionValue: {
      textAlign: "center",
      paddingTop: 8,
    },
    dialogActions: {
      "&.MuiDialogActions-root": {
        justifyContent: "flex-start",
        padding: "16px 24px",
      }
    }
  };
});

export default MenuOptionConfirmationModal;