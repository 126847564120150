import React, { useContext } from "react";
import styles from "./OptionCard.module.css";
import { formatCurrency } from "../../../helpers/stringFormatters";
import { VendorContext } from "../../../context";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CheckCircle from "@mui/icons-material/CheckCircle";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { getContrastColor } from "../../../helpers";

const GBBCard = ({ monthlyPayments, aftermarketName, handleClick, header, term, downPayment, advancePayments, backgroundColor, packages, titleFontColor }: { monthlyPayments: any, aftermarketName?: any, handleClick: any, header?: any, term: any, downPayment: any, advancePayments: any, backgroundColor?: any, packages?: any, titleFontColor?: any }) => {
  const { color: vendorColor } = useContext(VendorContext);

  const aftermarketNameSplit = !!aftermarketName ? aftermarketName.split(", ") : null;

  let primaryColor = backgroundColor || vendorColor;
  if (primaryColor) {
    primaryColor = primaryColor.includes("#") ? primaryColor : `#${primaryColor}`;
  }

  let headerFontColor = titleFontColor ? (titleFontColor.includes("#") ? titleFontColor : `#${titleFontColor}`) : getContrastColor(primaryColor);

  return (
    <React.Fragment>
      <Grid item xs={12} sm={6} md={4} lg={3} xl={3} style={{ display: "flex", justifyContent: "center", paddingTop: 24 }}>
        <Card
          className={styles.gbbCard}
          style={{ minHeight: 350, boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px", color: primaryColor, display: "flex", justifyContent: "space-between", flexDirection: "column" }}>
          <CardContent style={{ height: "100%", padding: "0", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Typography variant="h4" component="h2" style={{ backgroundColor: primaryColor, padding: "16px", color: headerFontColor, fontWeight: header ? 200 : 400 }}>
                {header || formatCurrency(parseFloat(monthlyPayments).toFixed(2))}
                {!header && <span style={{ fontSize: 16, marginTop: -4 }}>/mo.</span>}
              </Typography>
              <div style={{ display: "flex", flexDirection: "column", padding: "12px 24px 16px" }}>
                {packages && packages.length > 0 ? (
                  <>
                    {packages.map((item: any) => (
                      <div style={{ display: "flex", fontSize: 14, marginBottom: 8 }}>
                        <CheckCircle style={{ color: primaryColor, marginRight: 4, fontSize: 18 }} />
                        <p style={{ color: "rgba(0,0,0,0.85)", margin: "2px 0 0 4px", textAlign: "start" }}>{item.name}</p>
                      </div>
                    ))}
                  </>
                ) : (
                  <p style={{ color: "rgba(0,0,0,0.85)", margin: "4px 0 0" }}>{downPayment ? formatCurrency(downPayment.toFixed(2)) : "$0.00"} down</p>
                )}
              </div>
            </div>
            <div style={{ marginTop: "auto" }}>
              {header && (
                <p style={{ display: "flex", flexDirection: "column", margin: 0 }}>
                  <span style={{ fontWeight: "bold", color: "rgba(0,0,0,0.85)", fontSize: 24 }}>{formatCurrency(parseFloat(monthlyPayments).toFixed(2))}</span>
                  <span className={styles.greyText} style={{ fontSize: 14, margin: "2px 0 8px" }}>
                    per month
                  </span>
                </p>
              )}
              {!header && <p className={styles.greyText}>{term} months</p>}
            </div>
          </CardContent>
          <CardActions style={{ justifyContent: "center" }}>
            <Button style={{ color: primaryColor, border: `2px solid ${primaryColor}`, borderRadius: 8, width: "100%" }} onClick={handleClick}>
              view Details
            </Button>
          </CardActions>
        </Card>
      </Grid>
    </React.Fragment>
  );
};

export default GBBCard;