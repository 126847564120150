import { createContext } from 'react';
import { Constants } from "../constants";
import { Task } from "@trnsact/trnsact-shared-types";

type defaultContextType = {
	isMobileView: boolean;
	highlightErrorFieldsState: { state: boolean };
	setHighlightErrorFieldsState: Function;
	containerMaxWidth: string | boolean;
	setContainerMaxWidth: Function;
	isLenderDealerCoBranded: boolean;
	coBrandingSettings: any;
	accountTasksTemplates?: Task[];
	publicAccount: any;
	isLenderDealerOCA: boolean;
	task?: Task;
};
const defaultContext: defaultContextType = {
	isMobileView: false,
	highlightErrorFieldsState: { state: false },
	setHighlightErrorFieldsState: () => { },
	containerMaxWidth: Constants.DefaultContainerMaxWidth,
	setContainerMaxWidth: () => { },
	isLenderDealerCoBranded: false,
	coBrandingSettings: null,
	accountTasksTemplates: [],
	publicAccount: {},
	isLenderDealerOCA: false,
	task: undefined,
};
const CommonDataContext = createContext(defaultContext);

export { CommonDataContext };
