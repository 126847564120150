import axios from "axios";
import { apiURL } from "../config";

const checkFormIdStatus = formId => {
  return axios.post(apiURL, {
    operationName: null,
    variables: { formId },
    query: `{
        checkFormIdStatus(formId: "${formId}")
    }`,
  });
};

export default checkFormIdStatus;
