import { useLazyQuery } from '@apollo/react-hooks';
import formatString from 'format-string-by-pattern';
import gql from 'graphql-tag';
import _ from 'lodash';
import { useContext, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import defaultMessage from '../../../context/Message';
import { VendorContext, CommonDataContext } from '../../../context';
import { getURLParameter } from '../../../helpers';
import fonts from './fonts.module.css';
import SidebarStyles from './Sidebar.module.css';

const config = require('../../../config');

const GET_CONTACT = gql`
  query contact($dynamicsId: ID!) {
    contact(dynamicsId: $dynamicsId) {
      email
      firstName
      lastName
      fullName
      title
      phoneNumber
      mugshot
      availability
      vendorContactRole
    }
  }
`;

const SidebarRender = (props: any) => {
  const { repData, vendorContact, publicAccount, setRepData, partnerLink } = props;
  const { color: vendorColor, panelTextColor } = useContext(VendorContext);
  const { task } = useContext(CommonDataContext);
  const locationId = getURLParameter('locationId');
  const isTaskPage = window.location.href.includes('task-page');


  useEffect(() => {
    const getRepDataToSet = () => {
      const getLocationContact = () => {
        if (!locationId || _.isEmpty(publicAccount.locations)) {
          return;
        }
        const locationInfo = _.find(publicAccount.locations, { locationId });
        return _.get(locationInfo, 'ocaContact');
      };

      if (isTaskPage && task) {
        return task.createdByUserProfile || _.get(publicAccount, 'primaryContact');
      }

      return _.get(partnerLink, 'dealerContact') ||
        vendorContact ||
        getLocationContact() ||
        _.get(publicAccount, 'primaryContact');
    };

    const repDataToSet = getRepDataToSet();
    if (_.isEmpty(repDataToSet)) {
      return;
    }
    setRepData({
      officePhone: repDataToSet.phoneNumber || '',
      mugshot: repDataToSet.mugshot || '',
      email: repDataToSet.email || '',
      name: repDataToSet.fullName,
      availability: repDataToSet.availability || 'Monday-Friday, 8am-5pm PST',
    });
  }, [publicAccount, vendorContact, locationId, partnerLink]);

  const { email, mugshot, name, fullName, officePhone, phoneNumber } = repData;

  return (
    <div
      className={SidebarStyles.root}
      style={{ backgroundColor: vendorColor }}
    >
      <div className={SidebarStyles.body}>
        <div className={SidebarStyles.mdColumn}>
          <h3 className={fonts.h3} style={{ color: panelTextColor, margin: '15px' }}>
            <FormattedMessage
              id="needHelp"
              defaultMessage={defaultMessage.needHelp}
            />
          </h3>
          <p style={{ color: panelTextColor, textAlign: 'center' }}>
            <FormattedMessage
              id="representativesHelp"
              defaultMessage={defaultMessage.representativesHelp}
            />
          </p>
        </div>
        <div className={SidebarStyles.mdColumn}>
          <img
            src={
              mugshot && mugshot.includes('/')
                ? `https://${config.S3_BUCKET_NAME}.s3-us-west-2.amazonaws.com/${mugshot}`
                : 'https://credit-app-images.s3-us-west-2.amazonaws.com/avatar-placeholder.png'
            }
            alt="agent"
            className={SidebarStyles.photo}
          />
        </div>
        <div className={SidebarStyles.mdColumn}>
          <h3 className={fonts.h5} style={{ color: panelTextColor }}>
            {name || fullName}
          </h3>
          <a
            href={`mailto:${email}`}
            style={{
              color: panelTextColor,
              textDecoration: 'none',
              wordBreak: 'break-word',
            }}
          >
            {email}
          </a>
          <p style={{ color: panelTextColor }}>
            {formatString('(999) 999-9999', officePhone || phoneNumber)}
          </p>
        </div>
      </div>
    </div>
  );
};

const repDataStore = {
  '2F0610CC-E91B-E811-80EA-005056B05A0F': {
    email: 'm.whittier@Dealercreditresources.com',
    mugshot: 'michael-whittier.jpeg',
    name: 'Michael Whittier',
    officePhone: '7146899573',
    availability: 'Monday-Friday, 8am-5pm PST',
  },
  '2A61AAB9-1EE1-E811-80FA-005056B05A0F': {
    name: 'Ross Stites',
    email: 'RStites@dealercreditresources.com',
    officePhone: '7146899417',
    mugshot: 'ross.png',
    availability: 'Monday-Friday, 8am-5pm PST',
  },
  'B3468F86-B4D5-E011-BC70-005056A20000': {
    name: 'Ross Stites',
    email: 'RStites@dealercreditresources.com',
    officePhone: '7146899417',
    mugshot: 'ross.png',
    availability: 'Monday-Friday, 8am-5pm PST',
  },
  '2067558D-EE75-E011-94BF-005056A20000': {
    name: 'Beckham Thomas',
    email: 'BThomas@dealercreditresources.com',
    mugshot: 'beckham-thomas.jpg',
    officePhone: '7146899554',
    availability: 'Monday-Friday, 8am-5pm PST',
  },
  '5e2e05a9-3873-e911-8108-005056b05a0f': {
    name: 'Pond Charan',
    email: 'PCharan@mainstbusiness.capital',
    mugshot: 'pond-charan.png',
    officePhone: '7146899414',
    availability: 'Monday-Friday, 8am-5pm PST',
  },
  'B69F6A03-5302-E611-80C5-005056B05A0F': {
    name: 'Pond Charan',
    email: 'PCharan@mainstbusiness.capital',
    mugshot: 'pond-charan.png',
    officePhone: '7146899414',
    availability: 'Monday-Friday, 8am-5pm PST',
  },
  '5b1ed757-3b5d-e911-8108-005056b05a0f': {
    name: 'Chris Martin',
    email: 'CMartin@mainstbusiness.capital',
    mugshot: 'chris-martin.png',
    officePhone: '7146899562',
    availability: 'Monday-Friday, 8am-5pm PST',
  },
  '280aae84-7e84-e211-95fd-005056a20000': {
    name: 'Colleen Janis',
    email: 'CJanis@mainstbusiness.capital',
    mugshot: 'avatar-placeholder.png',
    officePhone: '7146899560',
    availability: 'Monday-Friday, 8am-5pm PST',
  },
  default: {
    mugshot: 'avatar-placeholder.png',
    name: (
      <FormattedMessage
        id="customerService"
        defaultMessage={defaultMessage.customerService}
      />
    ),
    email: 'info@dealercreditresources.com',
    officePhone: '7146899573',
    availability: 'Monday-Friday, 8am-5pm PST',
  },
};

const Sidebar = (props: any) => {
  const { repData, setRepData, publicAccount, partnerLink } = props;
  const vendorContactId = getURLParameter('vendorcontactId');
  const [vendorContact, setVendorContact] = useState();
  const { isLenderDealerOCA } = useContext(CommonDataContext);

  const [getContact, { data: contactData }] = useLazyQuery(GET_CONTACT);

  useEffect(() => {
    //no need to set specific contact by vendorContactId for LD OCA. Contact will be taken from publicAccount.primaryContact
    if (vendorContactId && !isLenderDealerOCA) {
      getContact({
        variables: { dynamicsId: vendorContactId }
      });
    }

  }, [vendorContactId]);

  useEffect(() => {
    if (contactData) {
      setVendorContact(contactData.contact);
    }
  }, [contactData]);

  return (
    <SidebarRender
      vendorContact={vendorContact}
      publicAccount={publicAccount}
      setRepData={setRepData}
      repData={repData}
      partnerLink={partnerLink}
    />
  );
};
export default Sidebar;
