import { Box, Grid, InputAdornment, TextField, InputLabel } from '@mui/material';
import { FormikProps, useFormikContext } from 'formik';
import _ from 'lodash';
import React, { useCallback, useEffect, useState, useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { CommonDataContext } from '../../context';
import { FieldType } from '../types';
import { BusinessOwnerInput } from '../../api/types';

const PercentField = ({
  itemData,
  value,
  locale,
  displayLabel,
  isNeedToMoveLabelOutOfInput,
}: {
  itemData: FieldType;
  value: any;
  locale: string;
  displayLabel: string;
  isNeedToMoveLabelOutOfInput: boolean;
}) => {
  const { id: fieldId, config: fieldConfig } = itemData;
  const [isInvalid, setIsInvalid] = useState(false);
  const [minValuePerOwnerError, setMinValuePerOwnerError] = useState(false);
  const [minValueTotalError, setMinValueTotalError] = useState(false);
  const [maxValueError, setMaxValueError] = useState(false);
  const formik: FormikProps<any> = useFormikContext();
  const { isMobileView, highlightErrorFieldsState } = useContext(CommonDataContext);

  const formattingValue = (value: string) => {
    const formattedValue = fieldConfig.type === 'float'
      ? value.replace(/[^0-9.]/g, '').replace(/\.+/, '.')
      : value.replace(/[^\d]/g, '');
    if (Number(formattedValue) > Number(fieldConfig.maxValue)) {
      return formattedValue.slice(0, -1);
    }
    return formattedValue;
  };

  const evaluateIfInvalid = (val: string): boolean => {

    const getTotalValue = (val: string): number => {
      const ownersQuantity = _.get(formik, 'values.ownersQuantity', 1);
      let totalSum = 0;
      let sumWOCurrentOwner = 0;
      for (let i = 0; i < ownersQuantity; i++) {
        const thisKey: string = `values.owners[${i}].percOwner`;
        if (thisKey === `values.${itemData.config.fieldName}`) {
          continue; // percOwner is not set yet for current owner. add it separatly further on the code
        }
        const value = parseInt(_.get(formik, thisKey, '0'));
        sumWOCurrentOwner += value;
      }
      return sumWOCurrentOwner + parseInt(formattedValue);
    };

    const isMinValueInvalid = (formattedValue: string, fieldConfigKey: 'minValue' | 'minValuePerOwner') => {
      if (formattedValue && !!fieldConfig[fieldConfigKey]) {
        return parseInt(formattedValue) < parseInt(fieldConfig[fieldConfigKey]);
      }
      return false;
    };

    const isMinValuePerOwnerInvalid = (val: string): boolean => {
      return isMinValueInvalid(formattedValue, 'minValuePerOwner');
    };

    const isMinValueTotalInvalid = (totalValue: number): boolean => {
      return isMinValueInvalid(totalValue.toString(), 'minValue');
    };

    setMinValuePerOwnerError(false);
    setMinValueTotalError(false);
    setMaxValueError(false);

    const formattedValue = val.replace(/[^\d]/g, '');
    const totalValue = getTotalValue(val);

    if (isMinValuePerOwnerInvalid(formattedValue)) {
      setMinValuePerOwnerError(true);
      return true;
    }
    if (isMinValueTotalInvalid(totalValue)) {
      setMinValueTotalError(true);
      return true;
    }
    if (totalValue > 100) {
      setMaxValueError(true);
      return true;
    }
    return false;
  };

  const handleChange = useCallback(
    (e) => {
      const formattedValue = formattingValue(e.target.value);
      e.target.value = formattedValue;
      formik.setFieldValue(itemData.config.fieldName, formattedValue, false);

      setIsInvalid(evaluateIfInvalid(formattedValue));
    },
    [formik]
  );

  const _resetField = (): void => {
    // Check if error is already set to ''
    if (Boolean(_.get(formik.errors, itemData.config.fieldName))) {
      //Remove Error
      formik.setFieldError(itemData.config.fieldName, '');
    }

    if (!_.has(formik.values, itemData.config.fieldName)) { // do not set '' value for untouched fields
      return;
    }

    //Erase value
    formik.setFieldValue(itemData.config.fieldName, '');
  };

  /*   useEffect(() => {
      const hasError = value && !!_.get(formik, `errors.${itemData.config.fieldName}`);
  
      if (hasError) {
        setIsInvalid(true);
      } else {
        setIsInvalid(false);
        setMinValuePerOwnerError(false);
        setMinValueTotalError(false);
        setMaxValueError(false);
      }
    }, [formik.errors]); */

  useEffect(() => {
    if (highlightErrorFieldsState.state) {
      setIsInvalid(!!formik.getFieldMeta(itemData.config.fieldName).error);
    }
  }, [highlightErrorFieldsState]);

  useEffect(() => {
    if (value) {
      setIsInvalid(evaluateIfInvalid(value));
    }
    return () => {
      formik.setFieldError(itemData.config.fieldName, '');
    };
  }, []);

  useEffect(() => {
    if (value) {
      console.log(itemData.config.fieldName)
      setIsInvalid(evaluateIfInvalid(value));
    }
  }, [formik.values.owners]);

  useEffect(() => {
    //When there is no need to display the field, we reset it's state to a clean one
    if (!itemData.config.displayed) {
      _resetField();
    } else {
      let isEmpty = false;
      if (itemData.config.required) {
        isEmpty = _.isEmpty(value);
      }
      formik.setFieldError(
        itemData.config.fieldName,
        isInvalid || isEmpty ? 'required' : ''
      );
    }
  }, [itemData.config.displayed, itemData.config.required, isInvalid, value]);

  const getErrorHelperText = () => {
    if (minValuePerOwnerError) {
      return (
        <FormattedMessage
          id={'percentMinValuePerOwnerError'}
          defaultMessage={'percentMinValuePerOwnerError'}
          values={{ minValueRequired: fieldConfig.minValuePerOwner }}
        />
      );
    }

    if (minValueTotalError) {
      return (
        <FormattedMessage
          id={'percentMinValueRequired'}
          defaultMessage={'percentMinValueRequired'}
          values={{ minValueRequired: fieldConfig.minValue }}
        />
      );
    }

    if (maxValueError) {
      return (
        <FormattedMessage id={'percentError'} defaultMessage={'percentError'} />
      );
    }

    return '';
  };

  return (
    <Box
      component={Grid}
      item
      xl={_.get(fieldConfig, 'columns.xl', 12)}
      lg={_.get(fieldConfig, 'columns.lg', 12)}
      md={_.get(fieldConfig, 'columns.md', 12)}
      sm={_.get(fieldConfig, 'columns.sm', 12)}
      xs={_.get(fieldConfig, 'columns.xs', 12)}
      display={{
        xl: !itemData.config.displayed ? 'none' : 'block',
        lg: !itemData.config.displayed ? 'none' : 'block',
        md: !itemData.config.displayed ? 'none' : 'block',
        sm: !itemData.config.displayed ? 'none' : 'block',
        xs: !itemData.config.displayed ? 'none' : 'block',
      }}
    >
      {isNeedToMoveLabelOutOfInput && <InputLabel className='outer-label'>{displayLabel}</InputLabel>}
      <TextField
        key={fieldId}
        name={fieldConfig.fieldName}
        fullWidth={fieldConfig.fullWidth}
        disabled={fieldConfig.disabled}
        variant={isMobileView ? "outlined" : "standard"}
        label={isNeedToMoveLabelOutOfInput ? '' : displayLabel}
        required={fieldConfig.required}
        helperText={getErrorHelperText()}
        error={isInvalid}
        value={value ? value : ''}
        InputProps={{
          endAdornment: <InputAdornment position="end">%</InputAdornment>,
        }}
        onChange={handleChange}
      />
    </Box>
  );
};

export { PercentField };
