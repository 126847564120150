export namespace Constants {
  export const MobileViewBreakpoint = 900;
  export const DynamicReferenceSectionType = 'dynamic-reference';
  export const DynamicSignaturesSectionType = 'dynamic-signatures';
  export const PersonalGuaranteeSignaturesSectionType = 'personal-guarantee-signatures';
  export const DocumentSignSectionType = 'document-sign';
  export const WhoIsPresentToSignPOCKey = 'poc';
  export const DefaultContainerMaxWidth = 'lg';
  export const TaskItemTypeValues = {
    documentESign: "documentESign",
  };
  export const ApplicantFields = [
    'fileInput', // to map driver license file for Primary Contact
    'firstName',
    'lastName',
    'middleName',
    'nameSuffix',
    'relationship',
    'dlStateIssued',
    'driverLicenseExp',
    'driverLicenseNum',
    'homeAddress',
    'homeCity',
    'address2',
    'homeState',
    'homePostalCode',
    'homePhone',
    'addressCounty',
    'homeType',
    'timeAtAddressYears',
    'timeAtAddressMonths',
    'employerName',
    'timeAtJobYears',
    'timeAtJobMonths',
    'employmentStatus',
    'employerAddress',
    'employerAddress2',
    'employerCity',
    'employerState',
    'employerZip',
    'grossMonthlyIncome',
    'employerPhone',
    'address',
    'phone',
    'city',
    'state',
    'postalCode',
    'email',
    'percOwner',
    'yearsWithCdl',
    'yearsAsOwnerOp',
    'mobilePhone',
    'ssn',
    'netWorth',
    'monthlyHousingPayment',
    'uploadDLQuestion',
    'dateOfBirth',
    'signature',
    'signatureDate',
    'employerEmail',
    'title',
    'countryOfResidence',
    'ownershipType',
    'usCitizen',
    'commercialDriverMonths',
    'commercialDriverYears',
    'residentStatus',
    'providingGuaranty',
    'maritalStatus',
    'companyDriverYears',
    'ownerOperatorYears',
    'firstTimeFinancing',
    'nearestRelativeFirstName',
    'nearestRelativeLastName',
    'nearestRelativePhone',
    'nearestRelativeCity',
    'nearestRelativeZip',
    'prevAddress',
    'prevAddress2',
    'prevCity',
    'prevState',
    'prevCounty',
    'prevPostalCode',
    'reasonForDeclining',
    'personalGuaranteeSignature',
    'personalGuaranteeSignatureDate',
    'addendum',
  ];

  export const pagesAvailableDirections = {
    next: 'next',
    prev: 'prev',
  };
  export const maskedFieldType = 'masked-value-field';
  export const maskedFieldNameSuffix = '-masked';
}
