import React, { useContext } from "react";
import { formatCurrency } from "../../../helpers/stringFormatters";
import { VendorContext } from "../../../context";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import styles from "../PaymentSelector.module.css";
import { makeStyles } from "@mui/styles";
import { Box, Step, StepLabel, Stepper } from "@mui/material";
import { S3_BUCKET_NAME } from "../../../config";
import FinanceProgramDescription from "./FinanceProgramDescription";
import _ from "lodash";



interface Props {
  proposal: any,
};

const ProposalHeader = ({ proposal }: Props) => {

  const classes = useStyles();
  const { color: vendorColor, logo } = useContext(VendorContext);
  const stepLabels = ["Credit Application", "Payment Selection", "Sign Your Docs"];
  const financeProgramDescription = proposal?.lenderPaymentOptions[0]?.paymentCriteria?.menu?.menuOptions[0]?.menuOptionDetail;

  return (
    <>
      {logo ? (
        <>
          <h2 style={{ color: "rgba(0,0,0,0.85)", textAlign: "center", fontWeight: "bold" }}>Prepared Exclusively For</h2>
          <div className={styles.vendorLogoWrapper}>
            <img
              src={logo && logo.includes("/") ? `https://${S3_BUCKET_NAME}.s3-us-west-2.amazonaws.com/${logo}` : `https://credit-app-images.s3-us-west-2.amazonaws.com/${logo}`}
              alt="Vendor Logo"
            />
          </div>
          <hr className={styles.horizontalRule} style={{ borderColor: vendorColor, marginTop: "24px" }} />
        </>
      ) : null}
      <p className={[styles.stepperTitle].join(" ")}>Modern Finance Process</p>
      <Stepper activeStep={1} alternativeLabel>
        {stepLabels.map(label => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>


      <Grid container style={{ marginTop: 48 }}>
        <Grid item container xs={proposal.equipmentPictureUrl ? 8 : 12}>

          <Grid item container /* spacing={2} */ className={classes.equipmentDescriptionContainer}>
            <Typography variant="h6" style={{ marginBottom: 12 }}>{proposal.year} {proposal.make} {proposal.model}</Typography>

            <Grid item container className={classes.equipmentInfoTopContainer} spacing={1}>
              <Grid item className={classes.equipmentInfoItem}>
                <Typography variant="subtitle2">Quantity</Typography>
                <Typography variant="subtitle1" className={classes.equipmentInfoValue}>{proposal.quantity}</Typography>
              </Grid>
              <Grid item className={classes.equipmentInfoItem}>
                <Typography variant="subtitle2">Condition</Typography>
                <Typography variant="subtitle1" className={classes.equipmentInfoValue}>{proposal.newUsed}</Typography>
              </Grid>
              <Grid item className={classes.equipmentInfoItem}>
                <Typography variant="subtitle2">Equipment</Typography>
                <Typography variant="subtitle1" className={classes.equipmentInfoValue}>{proposal.year} {proposal.make} {proposal.model}</Typography>
              </Grid>
            </Grid>
            <Grid item container spacing={1}>
              <Grid item className={classes.equipmentInfoItem}>
                <Typography variant="subtitle2">Serial/VIN#</Typography>
                <Typography variant="subtitle1" className={classes.equipmentInfoValue}>{proposal.serialNumberVin}</Typography>
              </Grid>
              <Grid item className={classes.equipmentInfoItem}>
                <Typography variant="subtitle2">Mileage</Typography>
                <Typography variant="subtitle1" className={classes.equipmentInfoValue}>{proposal.mileage}</Typography>
              </Grid>
              <Grid item className={classes.equipmentInfoItem}>
                <Typography variant="subtitle2">Price</Typography>
                <Typography variant="subtitle1" className={classes.equipmentInfoValue}>{formatCurrency(proposal.equipmentRetailPrice.toFixed(2))}</Typography>
              </Grid>
            </Grid>
          </Grid>

          {financeProgramDescription && <Grid item container className={classes.equipmentDescriptionContainer} style={{ marginTop: 12 }}>
            <FinanceProgramDescription description={financeProgramDescription} />
          </Grid>
          }
        </Grid>
        {proposal.equipmentPictureUrl && <Grid item xs={4}>
          <img src={proposal.equipmentPictureUrl} alt="Equipment Photo" className={classes.equipmentDescriptionImage} />
        </Grid>}
      </Grid>

    </>
  );
};

const useStyles = makeStyles(() => {
  return {
    equipmentDescriptionContainer: {
      backgroundColor: '#F5F5F5',
      padding: "12px 24px",
      borderRadius: 8,
    },
    equipmentInfoTopContainer: {
      borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
      marginBottom: "10px !important",
    },
    equipmentInfoItem: {
      width: "33%"
    },
    equipmentInfoValue: {
      wordWrap: "break-word",
    },
    equipmentDescriptionImage: {
      width: "100%",
      marginLeft: 8,
      borderRadius: 8,
    },
  };
});

export default ProposalHeader;