import { gql } from 'apollo-boost';

export const MUTATION_CREATE_CREDIT_APP_RAW_VALUES = gql`
  mutation ($createCreditApplicationRawValuesInput: CreateCreditApplicationRawValuesInput!) {
    createCreditApplicationRawValues(input: $createCreditApplicationRawValuesInput) {
      creditApplicationRawValuesId
      errorMessage
      success
    }
  }
`;
