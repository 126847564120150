import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Checkbox,
  Select,
  TextField,
} from "@mui/material";
import { FormikProps, useFormikContext } from "formik";
import _ from "lodash";
import { Fragment, useCallback, useEffect, useState, useContext } from "react";
import { CommonDataContext } from '../../context';
import { isDefaultLocale, DEFAULT_LOCALE } from "../../helpers";
import { FieldType } from "../types";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  selectHeight: {
    maxHeight: "40vh",
  },
  customCheckbox: {
    "& .MuiCheckbox-root": {
      padding: "0",
    },
  },
  formElement: {
    padding: "10px 10px 10px 0",
  },
  checkboxText: {
    cursor: "pointer",
  },
}));
const MultiselectField = ({
  itemData,
  value,
  locale,
  displayLabel,
  isNeedToMoveLabelOutOfInput,
}: {
  itemData: FieldType;
  value: any;
  locale: string;
  displayLabel: string;
  isNeedToMoveLabelOutOfInput: boolean;
}) => {
  const { id: fieldId, config: fieldConfig } = itemData;
  const classes = useStyles();
  const formik: FormikProps<any> = useFormikContext();
  const [isInvalid, setIsInvalid] = useState(false);
  const { isMobileView, highlightErrorFieldsState } = useContext(CommonDataContext);
  const [multiCheckboxesLabel, setMultiCheckboxesLabel] = useState("");

  const visibleOptions = _.reject(fieldConfig.fieldOptions, 'isHidden');

  const evaluateIfInvalid = (value: Array<string | boolean>): boolean => {
    const isValueSet = !_.isEmpty(value);
    const isRequiredAndEmpty = !!fieldConfig.required && !isValueSet;
    return isRequiredAndEmpty;
  };

  const handleChange = useCallback(
    (e) => {
      formik.setFieldValue(itemData.config.fieldName, e.target.value, false);
      setIsInvalid(evaluateIfInvalid(e.target.value));
    },
    [formik]
  );
  const _resetField = (): void => {
    if (!_.has(formik.values, itemData.config.fieldName)) { // do not set '' value for untouched fields
      return;
    }
    //Remove Error
    formik.setFieldError(itemData.config.fieldName, "");
    //Erase value
    formik.setFieldValue(itemData.config.fieldName, "");
  };

  const getDisplayLabel = (options: any, locale: string) =>
    _.get(options, `displayLabel.${locale}`, _.get(options, "optionLabel", ""));

  const getSelectLabel = (values: Array<string>) => {
    if (_.isEmpty(values)) return "";

    locale = isDefaultLocale(locale) ? locale : DEFAULT_LOCALE;

    const selectLabels: Array<string> = [];
    values.map((val: string) => {
      const options = fieldConfig.fieldOptions?.find(
        (option) => option.optionValue === val
      );
      if (options) selectLabels.push(getDisplayLabel(options, locale));
    });
    return selectLabels.join(", ");
  };

  useEffect(() => {
    setMultiCheckboxesLabel(getSelectLabel(value));
    return () => {
      setMultiCheckboxesLabel("");
      formik.setFieldError(itemData.config.fieldName, "");
    };
  }, []);

  useEffect(() => {
    //When there is no need to display the field, we reset it's state to a clean one
    if (!itemData.config.displayed) {
      _resetField();
    } else {
      let isEmpty = false;
      if (itemData.config.required) {
        isEmpty = _.isEmpty(value);
      }

      formik.setFieldError(
        itemData.config.fieldName,
        isEmpty ? "required" : ""
      );
    }
  }, [itemData.config.displayed, itemData.config.required, value]);

  useEffect(() => {
    if (highlightErrorFieldsState.state) {
      setIsInvalid(!!formik.getFieldMeta(itemData.config.fieldName).error);
    }
  }, [highlightErrorFieldsState]);

  const toggleOption = (optionValue: string) => {
    let newValue: Array<string> = value ? [...value] : [];
    if (_.isEmpty(newValue)) {
      newValue.push(optionValue);
    } else {
      if (newValue?.indexOf(optionValue) === -1) {
        newValue.push(optionValue);
      } else {
        newValue = newValue.filter((val: string) => val !== optionValue);
      }
    }
    formik.setFieldValue(itemData.config.fieldName, newValue, false);
    setMultiCheckboxesLabel(getSelectLabel(newValue));
    setIsInvalid(evaluateIfInvalid(newValue));
  };

  return (
    <Box
      component={Grid}
      item
      xl={_.get(fieldConfig, "columns.xl", 12)}
      lg={_.get(fieldConfig, "columns.lg", 12)}
      md={_.get(fieldConfig, "columns.md", 12)}
      sm={_.get(fieldConfig, "columns.sm", 12)}
      xs={_.get(fieldConfig, "columns.xs", 12)}
      display={{
        xl: !itemData.config.displayed ? "none" : "inline-block",
        lg: !itemData.config.displayed ? "none" : "inline-block",
        md: !itemData.config.displayed ? "none" : "inline-block",
        sm: !itemData.config.displayed ? "none" : "inline-block",
        xs: !itemData.config.displayed ? "none" : "inline-block",
      }}
    >
      {isNeedToMoveLabelOutOfInput &&
        <InputLabel className='outer-label' id={fieldConfig.fieldName} required={fieldConfig.required}>
          {displayLabel}
        </InputLabel>
      }
      <FormControl variant={isMobileView ? "outlined" : "standard"} fullWidth={fieldConfig.fullWidth} error={isInvalid}>
        {fieldConfig?.useDropdown ? (
          <Fragment>
            {!isNeedToMoveLabelOutOfInput &&
              <InputLabel
                id={fieldConfig.fieldName}
                required={fieldConfig.required}
              >
                {displayLabel}
              </InputLabel>
            }
            <Select
              fullWidth={fieldConfig.fullWidth}
              name={fieldConfig.fieldName}
              disabled={fieldConfig.disabled}
              value={value ? value : []}
              renderValue={(values) => getSelectLabel(values)}
              onChange={handleChange}
              label={isNeedToMoveLabelOutOfInput ? '' : displayLabel}
              labelId={fieldConfig.fieldName}
              required={fieldConfig.required}
              multiple
              variant={isMobileView ? "outlined" : "standard"}
            >
              {visibleOptions?.map((option: any, index: number) => {
                const displayedLabel = getDisplayLabel(option, locale);
                return (
                  <MenuItem
                    key={`${fieldId}${index}`}
                    value={option.optionValue}
                  >
                    <Checkbox
                      checked={
                        value && value?.indexOf(option.optionValue) !== -1
                      }
                    />
                    {displayedLabel}
                  </MenuItem>
                );
              })}
            </Select>
          </Fragment>
        ) : (
          <Fragment>
            <TextField
              name={fieldConfig.fieldName}
              disabled={fieldConfig.disabled}
              fullWidth={fieldConfig.fullWidth}
              variant={isMobileView ? "outlined" : "standard"}
              label={_.get(fieldConfig, `displayLabel.${locale}`, "")}
              helperText={_.get(fieldConfig, `helperText.${locale}`, "")}
              required={fieldConfig.required}
              value={multiCheckboxesLabel}
              error={isInvalid}
            />
            {visibleOptions?.map((option: any, index: number) => {
              const displayedLabel = getDisplayLabel(option, locale);
              return (
                <Grid
                  key={`option-${index}`}
                  container
                  alignItems="center"
                  className={`${classes.customCheckbox} ${classes.formElement}`}
                >
                  <Checkbox
                    color="primary"
                    checked={!!(value && value.indexOf(option.optionValue) !== -1)}
                    title={displayedLabel}
                    onChange={() => toggleOption(option.optionValue)}
                  />
                  <span
                    className={classes.checkboxText}
                    onClick={() => toggleOption(option.optionValue)}
                  >
                    {displayedLabel}
                  </span>
                </Grid>
              );
            })}
          </Fragment>
        )}
        <FormHelperText>
          {_.get(fieldConfig, `helperText.${locale}`, "")}
        </FormHelperText>
      </FormControl>
    </Box>
  );
};

export { MultiselectField };
