import { gql } from 'apollo-boost';

export const QUERY_PARTNER_LINK = gql`
 query partnerLink($partnerDealerId: ID!) {
  partnerLink(partnerDealerId: $partnerDealerId) {
    partnerLinkId
    dynamicsPartnerId
    name
    partnerAccountId
    dynamicsPartnerAccountId
    partnerProfileId
    dynamicsPartnerProfileId
    partnerContactId
    dynamicsPartnerContactId
    psChannel
      contact {
        fullName
        phoneNumber
        email
        mugshot
        availability
        id
      }
    dealerContact {
      fullName
      phoneNumber
      email
      mugshot
      availability
      id
    }
  }
 }
`;
