import { Box, Grid, TextField, InputLabel } from '@mui/material';
import { FormikProps, useFormikContext } from 'formik';
import _ from 'lodash';
import { useCallback, useEffect, useState, useContext } from 'react';
import { FieldType } from '../types';
import { isValueBetween, safeParseToInt } from '../../helpers';
import { CommonDataContext } from '../../context';

const AmountField = ({
  itemData,
  value,
  locale,
  displayLabel,
  isNeedToMoveLabelOutOfInput,
}: {
  itemData: FieldType;
  value: any;
  locale: string;
  displayLabel: string;
  isNeedToMoveLabelOutOfInput: boolean;
}) => {
  const { id: fieldId, config: fieldConfig } = itemData;
  const [isInvalid, setIsInvalid] = useState(false);
  const maxAmountValue = 100000000000;
  const formik: FormikProps<any> = useFormikContext();
  const { isMobileView, highlightErrorFieldsState } = useContext(CommonDataContext);

  const formatCurrency = (formattedValue: string) => {
    if (formattedValue && formattedValue !== '$') {
      formattedValue = formattedValue.replace(/\$/g, '');
      formattedValue = formattedValue.replace(/,/g, '');
      if (formattedValue.includes('.')) {
        const decimalLocation = formattedValue.indexOf('.');

        let digitsBeforeDecimal = formattedValue.slice(0, decimalLocation);
        digitsBeforeDecimal = Number(digitsBeforeDecimal).toLocaleString();

        const trailingDigits = formattedValue.slice(decimalLocation + 1);
        if (trailingDigits.length <= 2) {
          return `$${digitsBeforeDecimal}.${trailingDigits}`;
        } else {
          return `$${digitsBeforeDecimal}.${trailingDigits.slice(0, 2)}`;
        }
      } else {
        const valueWithCommas = Number(formattedValue).toLocaleString();
        return `$${valueWithCommas}`;
      }
    } else return '';
  };

  const evaluateIfInvalid = (value: string): boolean => {
    const parsedIntValue = safeParseToInt(value);
    const minValue = parseInt(fieldConfig.minValue);
    const maxValue = parseInt(fieldConfig.maxValue);
    return _.every([parsedIntValue, minValue, maxValue], _.isFinite) &&
      !isValueBetween(parsedIntValue, minValue, maxValue);
  };

  const handleChange = useCallback((e) => {
    let parsedStrValue = e.target.value.replace(/[^\d.]/g, '');
    const parsedIntValue = safeParseToInt(e.target.value);
    if (_.isFinite(parsedIntValue) && parsedIntValue > maxAmountValue) {
      parsedStrValue = _.toString(maxAmountValue);
    }
    const currencyValue = formatCurrency(parsedStrValue);
    //e.target.value = currencyValue;
    formik.setFieldValue(itemData.config.fieldName, currencyValue, false);
    setIsInvalid(evaluateIfInvalid(parsedStrValue));
  }, []);

  const _resetField = (): void => {
    // Check if error is already set to ''
    if (Boolean(_.get(formik.errors, itemData.config.fieldName))) {
      //Remove Error
      formik.setFieldError(itemData.config.fieldName, '');
    }

    if (!_.has(formik.values, itemData.config.fieldName)) { // do not set '' value for untouched fields
      return;
    }

    //Erase value
    formik.setFieldValue(itemData.config.fieldName, '');
  };

  useEffect(() => {
    if (value) {
      setIsInvalid(evaluateIfInvalid(value));
    }
    return () => {
      formik.setFieldError(itemData.config.fieldName, '');
    };
  }, []);

  useEffect(() => {
    if (highlightErrorFieldsState.state) {
      setIsInvalid(!!formik.getFieldMeta(itemData.config.fieldName).error);
    }
  }, [highlightErrorFieldsState]);

  useEffect(() => {
    if (!formik.initialValues.amountRequested) {
      return;
    }
    // Needs to format value and set it to formik in case if initialValue for amountRequested passed (e.g. as query param in the url). 
    if (!formik.dirty && itemData.config.fieldName === "amountRequested") {
      handleChange({ target: { value: formik.initialValues.amountRequested } });
    }
  }, [formik.initialValues.amountRequested]);

  useEffect(() => {
    //When there is no need to display the field, we reset it's state to a clean one
    if (!itemData.config.displayed) {
      _resetField();
    } else {
      let isEmpty = false;
      if (itemData.config.required) {
        isEmpty = _.isEmpty(value);
      }
      formik.setFieldError(
        itemData.config.fieldName,
        isInvalid || isEmpty ? 'required' : ''
      );
    }
  }, [itemData.config.displayed, itemData.config.required, isInvalid, value]);

  return (
    <Box
      component={Grid}
      item
      xl={_.get(fieldConfig, 'columns.xl', 12)}
      lg={_.get(fieldConfig, 'columns.lg', 12)}
      md={_.get(fieldConfig, 'columns.md', 12)}
      sm={_.get(fieldConfig, 'columns.sm', 12)}
      xs={_.get(fieldConfig, 'columns.xs', 12)}
      display={{
        xl: !itemData.config.displayed ? 'none' : 'block',
        lg: !itemData.config.displayed ? 'none' : 'block',
        md: !itemData.config.displayed ? 'none' : 'block',
        sm: !itemData.config.displayed ? 'none' : 'block',
        xs: !itemData.config.displayed ? 'none' : 'block',
      }}
    >
      {isNeedToMoveLabelOutOfInput && <InputLabel className='outer-label'>{displayLabel}</InputLabel>}
      <TextField
        key={fieldId}
        name={fieldConfig.fieldName}
        fullWidth={fieldConfig.fullWidth}
        disabled={fieldConfig.disabled}
        variant={isMobileView ? "outlined" : "standard"}
        label={isNeedToMoveLabelOutOfInput ? '' : displayLabel}
        required={fieldConfig.required}
        error={isInvalid}
        helperText={
          !isInvalid
            ? ''
            : _.get(fieldConfig, `errorMessage.${locale}`, '')
              .replace('{minRange}', fieldConfig.minValue)
              .replace('{maxRange}', fieldConfig.maxValue)
        }
        value={value ? formatCurrency(value) : ''}
        onChange={handleChange}
      />
    </Box>
  );
};

export { AmountField };
