import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import React, { useContext, useEffect, useState } from "react";
import { VendorContext } from "../../context";
import styles from "./PaymentSelector.module.css";

import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import { convertToDate, getContrastColor, getURLParameter } from "../../helpers";
import ConfirmationModal from "./components/ConfirmationModal";
import HeaderCard from "./components/HeaderCard";
import HighlightCard from "./components/HighlightCard";
import OptionCard from "./components/OptionCard";

import { useMutation, useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { calculateRatesAndPayments } from "../../helpers/paymentSelectorMath";
import GBBCard from "./components/GBBCard";

import Grid from "@mui/material/Grid";
import sslLogo from "../../assets/images/ssl-logo-300x166.png";
import trnsactLogo from "../../assets/images/trnsact-logo-powered-by-light.png";
import defaultMessage from "../../context/Message";
import { formatCurrency } from "../../helpers/stringFormatters";
import LenderOptionConfirmationModal from "./components/LenderOptionConfirmationModal";


import _ from "lodash";
import { FormattedMessage } from "react-intl";
import { makeStyles } from "@mui/styles";
import { Typography } from "@mui/material";
import MenuOptions from "./V2/MenuOptions";
import FinanceProgramDescription from "./V2/FinanceProgramDescription";
import ProposalHeader from "./V2/ProposalHeader";

const config = require("../../config");

const GET_PROPOSAL = gql`
  query proposal($dynamicsId: ID, $id: ID) {
    proposal(dynamicsId: $dynamicsId, id: $id) {
      productId
      rate
      riskTier
      amountRequested
      markupInternal
      markupDealer
      bizName
      proposalStage
      businessName
      creditProductIds
      vendorOpportunityId
      accountId
      status
      createdDateTime
      gbbCalculatedOptions
      gbbProposedTerms
      residual
      type
      equipmentId
      quantity
      newUsed
      year
      make
      model
      additionalDescription
      equipmentPictureUrl
      equipmentRetailPrice
      serialNumberVin
      stockId
      mileage
      gbbProposedApr
      createdDateTime
      lenderType
      maxApprovedAmount
      financedAmount
      redirect
      equipmentDescription
      fastTrackId
      category
      leadSheetFormId
      paymentSelectorOptions {
        paymentSelectorOptionsId
        lenderProfileId
        lenderProgramId
        textHeader
        textPaymentOptions
        textPaymentOptionsFinePrint
        textAmount
        showInterestRate
        showBestInterestRate
        createdDateTime
        modifiedDateTime
      }
      paymentCards {
        productId
        productName
        productHeader
        term
        rate
        payment
        rateFactor
        riskTier
        amountMin
        amountMax
        docFee
        advancePayment
        markupInternal
        lender
        calculatedPayment {
          paymentAmountPerPeriod
        }
        lenderProgramId
      }
      lenderPaymentOptions {
        paymentId
        proposalId
        lenderId
        term
        residual
        lenderType
        payment
        paymentGroupId
        paymentCriteria
      }
      vendorOpportunity {
        equipmentDescription
      }
      contact {
        email
        firstName
        lastName
        fullName
        phoneNumber
        mugshot
        title
        availability
      }
      vendorProfile {
        logo
        styles
      }
    }
  }
`;

const updateProposal = gql`
  mutation ($dynamicsId: ID, $id: ID, $dynamicsObj: updateProposalCRMFields, $fieldsObj: updateProposalFields) {
    updateProposal(dynamicsId: $dynamicsId, id: $id, dynamicsObj: $dynamicsObj, fieldsObj: $fieldsObj)
  }
`;

const numberWithCommas = (x: number) => x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

// const calculateMonthlyOptions = (obj: any) => {
//   if (!obj) {
//     return null;
//   }
//   for (let i = 0; i < obj.length; i++) {
//     obj[i].amountRequested = proposalDataResponse.proposal.amountRequested;
//     if (isNaN(obj[i].advancePayment) && obj[i].advancePayment.charAt(0) === "$") {
//       obj[i].advancePayment = Math.floor(obj[i].advancePayment.replace("$", ""));
//     }
//     if (isNaN(obj[i].docFee) && obj[i].docFee.charAt(0) === "$") {
//       obj[i].docFee = Math.floor(obj[i].docFee.replace("$", ""));
//     }

//     if (obj[i].calculatedPayment && obj[i].calculatedPayment.paymentAmountPerPeriod) {
//       obj[i].interestRate = obj[i].rate;
//       obj[i].monthlyPayments = numberWithCommas(obj[i].calculatedPayment.paymentAmountPerPeriod);
//     } else {
//       obj[i].monthlyPayments = obj[i].amountRequested * obj[i].rateFactor;
//       obj[i].mpWithPoints = obj[i].monthlyPayments * (1 + (obj[i].markupInternal / 100 + proposalDataResponse.proposal.markupDealer / 100));
//       obj[i].monthlyPayments = obj[i].mpWithPoints;
//       obj[i].monthlyPayments = obj[i].monthlyPayments.toFixed(2);
//       const ratesandpay = calculateRatesAndPayments(obj[i].amountRequested, obj[i].term, obj[i].rate, obj[i].markupInternal / 100 + proposalDataResponse.proposal.markupDealer / 100);
//       obj[i].interestRate = obj[i].rate === 0 || obj[i].rate === null ? 0 : ratesandpay.simpleInterest;
//     }

//     if (obj[i].advancePayment > 0) {
//       obj[i].amountDue = obj[i].advancePayment * obj[i].monthlyPayments + obj[i].docFee;
//       obj[i].amountDue = (Math.round(obj[i].amountDue * 100) / 100).toFixed(2);
//     } else {
//       obj[i].amountDue = obj[i].monthlyPayments + obj[i].docFee;
//       obj[i].amountDue = (Math.round(obj[i].amountDue * 100) / 100).toFixed(2);
//     }
//     obj[i].creditProduct = obj[i].productId + " - " + obj[i].productName;
//     obj[i].monthlyPayments = numberWithCommas(obj[i].monthlyPayments);
//   }
// };

const groupBy = function (xs: any, key: any) {
  if (!xs) {
    return null;
  }
  return xs.reduce(function (rv: any, x: any) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

const PSHeader = (props: any) => {

  const proposalProps = props.proposalDetails;
  const logo = props.logo || _.get(proposalProps, "data.proposal.vendorProfile.logo", null);
  const textHeader = props.textHeader;

  return (
    <>
      {logo ? (
        <>
          <h2 style={{ color: "rgba(0,0,0,0.85)", textAlign: "center", fontWeight: "bold" }}>Prepared Exclusively For</h2>
          <div className={props.isPH ? styles.phVendorLogoWrapper : styles.vendorLogoWrapper}>
            <img
              src={logo && logo.includes("/") ? `https://${config.S3_BUCKET_NAME}.s3-us-west-2.amazonaws.com/${logo}` : `https://credit-app-images.s3-us-west-2.amazonaws.com/${logo}`}
              alt="Vendor Logo"
            />
          </div>
          <hr className={styles.horizontalRule} style={{ borderColor: props.vendorColor, marginTop: "24px" }} />
        </>
      ) : null}
      <p className={[styles.stepperTitle].join(" ")}>{textHeader ? textHeader : "Modern Finance Process"}</p>
      <Stepper activeStep={1} alternativeLabel>
        {stepLabels.map(label => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div style={{ display: "flex", justifyContent: "center" }}>
        {!!props.loanAmount && !!props.customerBusiness && <HeaderCard loanAmount={props.loanAmount} customerBusiness={props.customerBusiness} textAmount={props.textAmount} />}
        {!!props.loanAmount && !!props.type && !!props.maxApproved && <HeaderCard loanAmount={props.loanAmount} maxApproved={props.maxApproved} type={props.type} textAmount={props.textAmount} />}
      </div>
      <div className={styles.centerText}>
        {!!proposalProps.equipmentPictureUrl && (
          <>
            <div style={{ height: "28px" }} />
            <div className={styles.vehiclePhotoWrapper} style={{ display: "inline-block" }}>
              <img src={proposalProps.equipmentPictureUrl} alt="Equipment Photo" />
            </div>
            <div className={styles.vehiclePhotoWrapper} style={{ display: "inline-block", alignItems: "center", textAlign: "center", verticalAlign: "top" }}>
              <span style={{ fontSize: "12px" }}>
                {proposalProps.newUsed} {proposalProps.year} {proposalProps.make} {proposalProps.model}
              </span>
              <br />
              <div style={{ textAlign: "center", fontWeight: "bold" }}>Vehicle Description</div>
              {proposalProps.additionalDescription}
              <div style={{ textAlign: "center", fontWeight: "bold" }}>Quote Date:</div>
              {convertToDate(proposalProps.createdDateTime)}
            </div>
            <hr className={styles.horizontalRule} style={{ borderColor: props.vendorColor, marginTop: "30px" }} />
          </>
        )}
        {!!proposalProps.vendorOpportunity && !!proposalProps.vendorOpportunity.equipmentDescription && (
          <>
            <div className={styles.vehiclePhotoWrapper} style={{ display: "inline-block", alignItems: "center", textAlign: "center", verticalAlign: "top" }}>
              <h2>Equipment Description</h2>
              <div style={{ fontSize: "18px" }}>{proposalProps.vendorOpportunity.equipmentDescription}</div>
            </div>
            <hr className={styles.horizontalRule} style={{ borderColor: props.vendorColor, marginTop: "30px" }} />
          </>
        )}
      </div>
    </>
  );
};

const stepLabels = ["Credit Application", "Payment Selection", "Sign Your Docs"];


const PaymentSelector = (props: any) => {
  const [propId] = useState(getURLParameter("propId"));
  const [proposalId] = useState(getURLParameter("proposalId"));
  const [vendorGUID] = useState(getURLParameter("vendorGUID"));
  const setRepData = props.setRepData;
  const setVendorObj = props.setVendorObj;
  const { loading: requestLoading, error, data: proposalDataResponse } = useQuery(GET_PROPOSAL, {
    variables: { dynamicsId: propId, id: proposalId }
  });
  const [fastTrackStatusUpdated, setFastTrackStatusUpdated] = useState(false);
  const [updateFastTrackStatus] = useMutation(updateProposal);
  const queryParams = new URLSearchParams(window.location.search);
  const [applicantIsViewingThisProposal] = useState(queryParams.get("av") === "" || queryParams.get("av.") === "");
  const [loading, setLoading] = useState(true);
  const [bizName, setBizName] = useState('');
  const [proposalStage, setProposalStage] = useState('');
  const [proposalType, setProposalType] = useState("");
  const [maxApprovedAmount, setMaxApprovedAmount] = useState(null);
  const [thisProposalIsFastTracked, setThisProposalIsFastTracked] = useState(false);
  const [paymentSelectorOptions, setPaymentSelectorOptions] = useState([]);
  const [textHeader, setTextHeader] = useState(null);
  const [textPaymentOptions, setTextPaymentOptions] = useState(null);
  const [textPaymentOptionsFinePrint, setTextPaymentOptionsFinePrint] = useState(null);
  const [textAmount, setTextAmount] = useState(null);
  const [showInterestRate, setShowInterestRate] = useState(true);
  const [showBestInterestRate, setShowBestInterestRate] = useState(true);
  const [hasPaymentSelectorOptions, setHasPaymentSelectorOptions] = useState(false);
  const [allPaymentOptions, setAllPaymentOptions] = useState<Array<any>>([]);


  useEffect(() => {
    console.log("algo cambio");
    if (proposalId) {
      if (applicantIsViewingThisProposal) {
        if (!fastTrackStatusUpdated) {
          console.log("Applicant is viewing, updating FT status!");
          updateFastTrackStatus({
            variables: {
              id: proposalId,
              fieldsObj: {
                fastTrackStatusId: 215,
              },
            },
          });
        }
        setFastTrackStatusUpdated(true);
      } else {
        console.log("Applicant is NOT viewing, skipping update of FT status!");
      }
    }
  }, []);


  useEffect(() => {

    setLoading(requestLoading);
    if (proposalDataResponse) {
      if (proposalDataResponse.proposal) {
        const proposalTypeValue = proposalDataResponse.proposal.type;
        setBizName(proposalDataResponse.proposal.bizName || proposalDataResponse.proposal.businessName);
        setProposalStage(proposalDataResponse.proposal.proposalStage || proposalDataResponse.proposal.status);
        setProposalType(proposalTypeValue);
        setMaxApprovedAmount(proposalDataResponse.proposal.maxApprovedAmount);
        setThisProposalIsFastTracked(!!proposalDataResponse.proposal.fastTrackId);
        setPaymentSelectorOptions(proposalDataResponse.proposal.paymentSelectorOptions || []);
        setAllPaymentOptions(proposalDataResponse.proposal.paymentCards);
        if (proposalDataResponse.proposal.paymentSelectorOptions && proposalDataResponse.proposal.paymentSelectorOptions.length > 0) {
          const options = proposalDataResponse.proposal.paymentSelectorOptions[0];
          setTextHeader(options.textHeader);
          setTextPaymentOptions(options.textPaymentOptions);
          setTextPaymentOptionsFinePrint(options.textPaymentOptionsFinePrint);
          setTextAmount(options.textAmount);
          setShowBestInterestRate(options.showBestInterestRate);
          setShowInterestRate(options.showInterestRate);
          setHasPaymentSelectorOptions(true);
        } else {
          setHasPaymentSelectorOptions(false);
        }
      } else {
        console.log("redirection to ps error page")
        window.location.href = "/pserror/"
      }
    }
  }, [requestLoading, proposalDataResponse]);






  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;



  switch (proposalType) {
    case "base":
    case "select":
      const bestInterest = allPaymentOptions
        ? allPaymentOptions.reduce(function (prev: any, curr: any) {
          return prev.interestRate < curr.interestRate ? prev : curr;
        })
        : null;
      const lowestPayment = allPaymentOptions
        ? allPaymentOptions.reduce(function (prev: any, curr: any) {
          if (prev.calculatedPayment && curr.calculatedPayment) {
            return prev.calculatedPayment.paymentAmountPerPeriod < curr.calculatedPayment.paymentAmountPerPeriod ? prev : curr;
          }
          return prev.mpWithPoints < curr.mpWithPoints ? prev : curr;
        })
        : null;
      return (
        <PaymentSelectorV2
          bizName={bizName}
          bestInterestRate={bestInterest}
          lowestPayment={lowestPayment}
          allPaymentOptionsCards={groupBy(allPaymentOptions, "productHeader")}
          proposalStage={proposalStage}
          type={proposalType}
          vendorGUID={vendorGUID}
          setRepData={setRepData}
          thisProposalIsFastTracked={thisProposalIsFastTracked}
          paymentSelectorOptions={paymentSelectorOptions}
          textHeader={textHeader}
          textPaymentOptions={textPaymentOptions}
          textPaymentOptionsFinePrint={textPaymentOptionsFinePrint}
          textAmount={textAmount}
          showInterestRate={showInterestRate}
          showBestInterestRate={showBestInterestRate}
          hasPaymentSelectorOptions={hasPaymentSelectorOptions}
        />
      );
    default:
      return <PaymentSelectorLenderOptions
        bizName={bizName}
        proposalStage={proposalStage}
        type={proposalType}
        vendorGUID={vendorGUID}
        maxApproved={maxApprovedAmount}
        setRepData={setRepData}
        setVendorObj={setVendorObj}
        thisProposalIsFastTracked={thisProposalIsFastTracked}
        textHeader={textHeader}
        textPaymentOptions={textPaymentOptions}
        textPaymentOptionsFinePrint={textPaymentOptionsFinePrint}
        textAmount={textAmount}
        showInterestRate={showInterestRate}
        showBestInterestRate={showBestInterestRate}
        proposalProps={proposalDataResponse}
      />
  }


};


const PaymentSelectorV2 = (props: any) => {
  const [propId] = useState(getURLParameter("propId"));
  const [proposalId] = useState(getURLParameter("proposalId"));
  const productNames: any[] = [];
  const proposalProps = useQuery(GET_PROPOSAL, { variables: { dynamicsId: propId, id: proposalId } });
  const [customerBusiness] = useState(props.bizName);
  const [transactionGUID] = useState(getURLParameter("TUId"));
  const vendorGUID = props.vendorGUID;
  const [showModal, setShowModal] = useState(false);
  const [loanAmount, setLoanAmount] = useState("$XX.XX");
  const [submitted, setSubmitted] = useState(false);
  const [selectedProductIndex, setSelectedProductIndex] = useState();
  const [isQuote, setIsQuote] = useState(false);
  const [formId, setFormId] = useState("");
  //@ts-ignore
  const { color: vendorColor, logo } = useContext(VendorContext);
  const paymentOptions = props.allPaymentOptionsCards;
  const bestRate = props.bestInterestRate ? props.bestInterestRate : {};
  const lowestMonthlyPayment = props.lowestPayment ? props.lowestPayment : {};
  const type = props.type;
  const [postPaymentSelection] = useMutation(updateProposal);
  const thisProposalIsFastTracked = props.thisProposalIsFastTracked;
  const paymentSelectorOptions = props.paymentSelectorOptions;
  const textHeader = props.textHeader;
  const textPaymentOptions = props.textPaymentOptions;
  const textPaymentOptionsFinePrint = props.textPaymentOptionsFinePrint;
  const textAmount = props.textAmount;
  const showInterestRate = props.showInterestRate;
  const showBestInterestRate = props.showBestInterestRate;
  const hasPaymentSelectorOptions = props.hasPaymentSelectorOptions;
  const paymentSelectionObj = {
    dcrStage: "Presented",
    status: "Viewed by customer",
  };

  console.log(`
    PaymentSelectorV2
      textHeader =  ${textHeader}
      textPaymentOptions =  ${textPaymentOptions}
      textPaymentOptionsFinePrint = ${textPaymentOptionsFinePrint}
      textAmount =  ${textAmount}
      showInterestRate =  ${showInterestRate}
      showBestInterestRate =  ${showBestInterestRate}

      lowestMonthlyPayment = ${JSON.stringify(lowestMonthlyPayment)}
      bestRate = ${JSON.stringify(bestRate)}
      bestInterestRate = ${JSON.stringify(props.bestInterestRate)}
  `);

  for (const prop in paymentOptions) {
    productNames.push(prop);
  }

  const setRepData = props.setRepData;

  useEffect(() => {
    if (!!proposalProps.data.proposal.contact) {
      setRepData({
        officePhone: proposalProps.data.proposal.contact.phoneNumber,
        email: proposalProps.data.proposal.contact.email,
        name: proposalProps.data.proposal.contact.fullName,
        availability: proposalProps.data.proposal.contact.availability || "Monday-Friday, 8am-5pm PST",
        mugshot: proposalProps.data.proposal.contact.mugshot,
      });
    }
  }, [proposalProps.data.proposal.contact]);

  useEffect(() => {
    if (!!proposalProps.data.proposal.category) {
      setIsQuote(proposalProps.data.proposal.category === "QUOTE");
    }
    if (!!proposalProps.data.proposal.leadSheetFormId) {
      setFormId(proposalProps.data.proposal.leadSheetFormId);
    }
  }, [proposalProps.data.proposal]);

  useEffect(() => {
    const postPayment = () => {
      if (propId) {
        postPaymentSelection({
          variables: {
            dynamicsId: propId,
            dynamicsObj: paymentSelectionObj,
          },
        });
      }
      if (proposalId) {
        postPaymentSelection({
          variables: {
            id: proposalId,
            fieldsObj: paymentSelectionObj,
          },
        });
      }
    };
    const vendorGUID = getURLParameter("vendorGUID");
    if (vendorGUID === "4af87cff-d949-e911-8107-005056b05a0f") {
      setPH(true);
    }
    const loanAmount = proposalProps.data.proposal.amountRequested.toFixed(2);
    if (loanAmount) setLoanAmount(loanAmount);
    if (props.proposalStage === "Accepted") {
      window.location.href = "/psconfirmed/";
    } else if (props.proposalStage === "" || props.proposalStage == null || props.proposalStage === "Created" || props.proposalStage === "Proposal Sent") {
      postPayment();
    }
  }, []);
  const [isPH, setPH] = useState(false);

  const handleCardClick = (productIndex: any) => {
    setSelectedProductIndex(productIndex);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };
  const labelText = () => {
    if (bestRate.interestRate > 0.1) {
      return "Lowest Payment";
    }
    if (isNaN(bestRate.interestRate)) {
      return "Lowest Monthly Payment";
    }
    return `Lowest Payment${showBestInterestRate ? " and Best Interest Rate" : ""}`;
  };
  return (
    <>
      <PSHeader
        logo={logo}
        loanAmount={loanAmount}
        customerBusiness={customerBusiness}
        proposalDetails={proposalProps}
        isPH={isPH}
        vendorColor={vendorColor}
        textHeader={textHeader}
        textAmount={textAmount}
      />
      <div className={styles.centerText}>
        <h4 style={{ color: vendorColor }} className={[styles.defaultTextColor].join(" ")}>
          Payment Options
        </h4>
        <p >
          {textPaymentOptions
            ? textPaymentOptions
            : `
          Based off of your credit, we would like to offer you the following finance options. Please click to review any one of the following offers. If you have any questions, please give us a call.
          These options are subject to change and are based on a Pre-Approval only.`}
        </p>
        <div style={{ height: "10px" }} />
        {type !== "gbb" && (
          <>
            {bestRate === lowestMonthlyPayment ? (
              <HighlightCard
                label={labelText()}
                payment={lowestMonthlyPayment.monthlyPayments}
                months={lowestMonthlyPayment.term}
                rate={(lowestMonthlyPayment.interestRate * 100).toFixed(2)}
                rateCheck={(lowestMonthlyPayment.interestRate * 100).toFixed(2)}
                handleClick={() => {
                  handleCardClick(lowestMonthlyPayment);
                }}
                thisProposalIsFastTracked={thisProposalIsFastTracked}
                paymentSelectorOptionsForThisCard={paymentSelectorOptions.find((pso: any) => {
                  return pso.lenderProgramId === lowestMonthlyPayment.lenderProgramId;
                })}
              />
            ) : (
              <React.Fragment>
                <HighlightCard
                  label={"Lowest Payment"}
                  payment={lowestMonthlyPayment.monthlyPayments}
                  months={lowestMonthlyPayment.term}
                  rate={(lowestMonthlyPayment.interestRate * 100).toFixed(2)}
                  rateCheck={(lowestMonthlyPayment.interestRate * 100).toFixed(2)}
                  handleClick={() => {
                    handleCardClick(lowestMonthlyPayment);
                  }}
                  thisProposalIsFastTracked={thisProposalIsFastTracked}
                  paymentSelectorOptionsForThisCard={paymentSelectorOptions.find((pso: any) => {
                    return pso.lenderProgramId === lowestMonthlyPayment.lenderProgramId;
                  })}
                />
              </React.Fragment>
            )}
            {bestRate === lowestMonthlyPayment || bestRate.interestRate > 0.1 || !showBestInterestRate ? (
              ""
            ) : (
              <HighlightCard
                label={"Best Interest Rate"}
                payment={bestRate.monthlyPayments}
                months={bestRate.term}
                rate={parseFloat(String(bestRate.interestRate * 100)).toFixed(2)}
                handleClick={() => {
                  handleCardClick(bestRate);
                }}
                thisProposalIsFastTracked={thisProposalIsFastTracked}
              />
            )}
            <React.Fragment>
              {productNames[0] ? (
                <h5 style={{ color: vendorColor }} className={[styles.defaultTextColor].join(" ")}>
                  {productNames[0]}
                </h5>
              ) : (
                ""
              )}
              {paymentOptions[productNames[0]].map(({ term, monthlyPayments, interestRate }: { term: any, monthlyPayments: any, interestRate: any }, i: number) => (
                <OptionCard
                  rate={parseFloat(String(interestRate * 100)).toFixed(2)}
                  payment={monthlyPayments}
                  term={term}
                  rateCheck={(lowestMonthlyPayment.interestRate * 100).toFixed(2)}
                  handleClick={() => {
                    handleCardClick(paymentOptions[productNames[0]][i]);
                  }}
                  thisProposalIsFastTracked={thisProposalIsFastTracked}
                  key={i}
                  paymentSelectorOptionsForThisCard={paymentSelectorOptions.find((pso: any) => {
                    return pso.lenderProgramId === paymentOptions[productNames[0]][i].lenderProgramId;
                  })}
                />
              ))}
            </React.Fragment>
            {productNames[1] ? (
              <h5 style={{ color: vendorColor }} className={[styles.defaultTextColor].join(" ")}>
                {productNames[1]}
              </h5>
            ) : (
              ""
            )}
            <React.Fragment>
              {!productNames[1]
                ? ""
                : paymentOptions[productNames[1]].map(({ term, monthlyPayments, interestRate }: { term: any, monthlyPayments: any, interestRate: any }, i: number) => (
                  <OptionCard
                    rate={parseFloat(String(interestRate * 100)).toFixed(2)}
                    payment={monthlyPayments}
                    term={term}
                    rateCheck={(lowestMonthlyPayment.interestRate * 100).toFixed(2)}
                    handleClick={() => {
                      handleCardClick(paymentOptions[productNames[1]][i]);
                    }}
                    key={i}
                    paymentSelectorOptionsForThisCard={paymentSelectorOptions.find((pso: any) => {
                      return pso.lenderProgramId === paymentOptions[productNames[1]][i].lenderProgramId;
                    })}
                  />
                ))}
            </React.Fragment>
          </>
        )}

        <hr className={styles.horizontalRule} style={{ borderColor: vendorColor, marginTop: "30px" }} />
        <p className={[styles.finePrint].join(" ")}>
          {textPaymentOptionsFinePrint
            ? textPaymentOptionsFinePrint
            : `Rates shown represent simple interest. Simple interest is a rate of interest that is calculated by dividing the Total Interest by the principal amount of the loan; it represents the total
          amount of interest that you will pay as a percentage of the amount borrowed. It does not include the Origination Fee. It is not annualized and you should use caution in comparing it to
          interest rates expressed in annualized terms or to loans with different durations.`}
        </p>
      </div>
      <Modal
        open={showModal}
        onClose={closeModal}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}>
        <ConfirmationModal
          customerBusiness={customerBusiness}
          loanAmount={loanAmount}
          closeModal={closeModal}
          termLength={_.get(selectedProductIndex, 'term')}
          monthlyPayment={_.get(selectedProductIndex, 'monthlyPayments')}
          transactionGUID={transactionGUID}
          formSubmitted={submitted}
          setFormSubmit={setSubmitted}
          vendorGUID={vendorGUID}
          propId={propId}
          proposalId={proposalId}
          interestRate={_.get(selectedProductIndex, 'interestRate')}
          docFee={_.get(selectedProductIndex, 'docFee')}
          rate={_.get(selectedProductIndex, 'rate')}
          advancePayment={_.get(selectedProductIndex, 'advancePayment')}
          amountDue={_.get(selectedProductIndex, 'amountDue')}
          creditProduct={_.get(selectedProductIndex, 'creditProduct')}
          textAmount={textAmount}
          hasPaymentSelectorOptions={hasPaymentSelectorOptions}
        />
      </Modal>
    </>
  );
};

const PaymentSelectorLenderOptions = (props: any) => {
  const proposal = props.proposalProps.proposal;

  const [propId] = useState(getURLParameter("propId"));
  const [proposalId] = useState(getURLParameter("proposalId"));
  const productNames = [];

  const [customerBusiness] = useState(props.bizName);
  const [transactionGUID] = useState(getURLParameter("TUId"));
  const [showModal, setShowModal] = useState(false);
  const [loanAmount, setLoanAmount] = useState("$XX.XX");
  const [submitted, setSubmitted] = useState(false);
  const [selectedProductIndex, setSelectedProductIndex] = useState();
  const [termSelected, setTermSelected] = useState(0);
  const [lenderOption, setLenderOption] = useState({});
  const { color: vendorColor, logo } = useContext(VendorContext);
  const paymentOptions = props.lenderPaymentOptions;
  const type = props.type;
  const vendorGUID = props.vendorGUID;
  const [redirectToSigning, setRedirectToSigning] = useState();
  const [voId, setVoId] = useState();
  const [postPaymentSelection] = useMutation(updateProposal);
  const textHeader = props.textHeader;
  const textPaymentOptions = props.textPaymentOptions;
  const textPaymentOptionsFinePrint = props.textPaymentOptionsFinePrint;
  const textAmount = props.textAmount;
  const showInterestRate = props.showInterestRate;
  const showBestInterestRate = props.showBestInterestRate;
  const [amountDueProposalV2, setAmountDueProposalV2] = useState(0);
  const [sortedLenderPaymentOptions, setSortedLenderPaymentOptions] = useState([]);
  const [lenderType, setLenderType] = useState(null);
  const [isQuote, setIsQuote] = useState(false);
  const [formId, setFormId] = useState("");
  const queryParams = new URLSearchParams(window.location.search);

  const [applicantIsViewingThisProposal] = useState(queryParams.get("av") === "" || queryParams.get("av.") === "");

  console.log(`
    PaymentSelectorLenderOptions
      textHeader =  ${textHeader}
      textPaymentOptions =  ${textPaymentOptions}
      textPaymentOptionsFinePrint = ${textPaymentOptionsFinePrint}
      textAmount =  ${textAmount}
      showInterestRate =  ${showInterestRate}
      showBestInterestRate =  ${showBestInterestRate}
  `);
  const paymentSelectionObj = {
    dcrStage: "Presented",
    status: "Viewed by customer",
  };

  for (const prop in paymentOptions) {
    productNames.push(prop);
  }

  const setRepData = props.setRepData;
  const setVendorObj = props.setVendorObj;



  useEffect(() => {
    if (!!proposal) {
      const redirectToSigning = proposal.redirect;
      setRedirectToSigning(redirectToSigning);
      setVoId(proposal.vendorOpportunityId);
      setLenderType(proposal.lenderType);
      setIsQuote(proposal.category === "QUOTE");
      setFormId(proposal.leadSheetFormId);
      if (!!proposal.vendorProfile) {
        setVendorObj(null, proposal.vendorProfile);
      }
      if (!!proposal.contact) {
        setRepData({
          officePhone: proposal.contact.phoneNumber,
          email: proposal.contact.email,
          name: proposal.contact.fullName,
          availability: proposal.contact.availability || "Monday-Friday, 8am-5pm PST",
          mugshot: proposal.contact.mugshot,
        });
      }
      if (!_.isEmpty(proposal.lenderPaymentOptions)) {
        setSortedLenderPaymentOptions(_.cloneDeep(proposal.lenderPaymentOptions).sort((a: any, b: any) => a.payment - b.payment))
      }
    }
  }, [proposal]);

  useEffect(() => {
    const postPayment = () => {
      if (propId) {
        postPaymentSelection({
          variables: {
            dynamicsId: propId,
            dynamicsObj: paymentSelectionObj,
          },
        });
      }
      if (proposalId) {
        postPaymentSelection({
          variables: {
            id: proposalId,
            fieldsObj: paymentSelectionObj,
          },
        });
      }
    };
    const vendorGUID = getURLParameter("vendorGUID");
    if (vendorGUID === "4af87cff-d949-e911-8107-005056b05a0f") {
      setPH(true);
    }
    const loanAmount = proposal.amountRequested.toFixed(2);
    if (loanAmount) setLoanAmount(loanAmount);
    if (props.proposalStage === "Accepted" || props.proposalStage === "accepted - pending bank") {
      window.location.href = "/psconfirmed/";
    } else if (props.proposalStage === "" || props.proposalStage == null || props.proposalStage === "Created" || props.proposalStage === "Proposal Sent") {
      postPayment();
    }
  }, []);
  const [isPH, setPH] = useState(false);

  const handleCardClick = (productIndex: any) => {
    setSelectedProductIndex(productIndex);

    console.log(`productIndex: ${JSON.stringify(productIndex)}`);

    let amountDue = 0;

    try {
      if (productIndex.paymentCriteria) {
        amountDue = 0;
        if (productIndex.paymentCriteria.downPaymentAmount) {
          amountDue += productIndex.paymentCriteria.downPaymentAmount;
        }
        if (productIndex.paymentCriteria.numberOfAdvancePayments && productIndex.paymentCriteria.numberOfAdvancePayments > 0) {
          amountDue += parseFloat((productIndex.paymentCriteria.numberOfAdvancePayments * productIndex.payment).toFixed(2));
        }
      }
      setAmountDueProposalV2(amountDue);
    } catch (e) {
      console.log("Problem calculating amountDue on for proposalV2");
    }

    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };
  const redirectToOCA = () => {
    window.location.href = `/oca/?vendorGUID=${vendorGUID}&formId=${formId}`;
  };
  useEffect(() => {
    if (proposal && sortedLenderPaymentOptions?.length) {
      setLenderOption(sortedLenderPaymentOptions[termSelected]);
    }
  }, [proposal, termSelected, sortedLenderPaymentOptions]);

  const totalPaymentsAmount = proposal.financedAmount || proposal.lenderPaymentOptions[0].paymentCriteria.amountFinanced;
  const GBBArray = proposal.lenderPaymentOptions
    .map((option: any) => {
      if (option.paymentCriteria && option.paymentCriteria.menu && option.paymentCriteria.menu.menuOptions && option.paymentCriteria.menu.menuOptions.length > 0) return option;
    })
    .filter((i: any) => i);
  const isGBB = GBBArray.length > 0;
  if (proposal)
    return (
      <>
        <ProposalHeader proposal={proposal} />
        {(isGBB && lenderOption)
          ? <MenuOptions
            lenderOption={lenderOption}
            proposal={proposal}
            termSelected={termSelected}
            setTermSelected={setTermSelected}
          />
          : (
            <div className={styles.centerText} style={{ marginTop: 48 }}>
              <Grid container style={{ margin: "32px 0" }} justifyContent={"center"}>
                {sortedLenderPaymentOptions && sortedLenderPaymentOptions
                  .map(({ term, payment, residual, paymentCriteria, paymentGroupId }: { term: any, payment: any, residual: any, paymentCriteria: any, paymentGroupId: any }, i: number) => {
                    let paymentCriteriaHeader = null;
                    const card = (
                      <GBBCard
                        monthlyPayments={payment}
                        term={term}
                        handleClick={() => {
                          handleCardClick(proposal.lenderPaymentOptions[i]);
                        }}
                        key={i}
                        downPayment={paymentCriteria.downPaymentAmount}
                        advancePayments={paymentCriteria.numberOfAdvancePayments}
                      />
                    );
                    if (paymentCriteria) {
                      if (i === 0 || (i > 0 && paymentGroupId !== proposal.lenderPaymentOptions[i - 1].paymentGroupId)) {
                        paymentCriteriaHeader = (
                          <>
                            <Grid item xs={12} style={{ color: "rgba(0,0,0,0.7)", paddingTop: "24px", paddingBottom: "8px" }}>
                              <span style={{ fontSize: "26px" }}>{paymentCriteria.financeProgramName}</span>
                            </Grid>
                          </>
                        );
                      }
                    }
                    return (
                      <React.Fragment>
                        {paymentCriteriaHeader}
                        {card}
                      </React.Fragment>
                    );
                  })}
              </Grid>
            </div>
          )
        }
        {isQuote && applicantIsViewingThisProposal ? (
          <Grid lg={12} md={12} sm={12} xs={12}>
            <div style={{ textAlign: "center" }}>
              <Button
                style={{
                  backgroundColor: vendorColor,
                  borderColor: vendorColor,
                  color: getContrastColor(vendorColor || "#FFFFFF"),
                  height: "3rem",
                  width: "9rem",
                }}
                type="submit"
                variant="contained"
                onClick={() => redirectToOCA()}>
                Apply Now
              </Button>
            </div>
          </Grid>
        ) : null}
        <hr className={styles.horizontalRule} style={{ borderColor: vendorColor, width: "100%", marginTop: 108 }} />
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6} md={6} lg={6} className={styles.sslLogoContainer}>
            <img src={sslLogo} alt="SSL Logo" className={styles.sslLogo} />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} className={styles.poweredByDCRLogoContainer}>
            <a href="http://trnsact.com/" target="_blank">
              <img src={trnsactLogo} className={styles.poweredByDCRLogo} alt="Powered By Trnsact" />
            </a>
          </Grid>
        </Grid>
        <Grid container justifyContent="space-between" alignItems="flex-start">
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <p className={[styles.footerText].join(" ")} style={{ width: "100%", marginBottom: 32 }}>
              <FormattedMessage id="sslDisclaimer" defaultMessage={defaultMessage.sslDisclaimer} />
            </p>
            <p >
              <FormattedMessage id="fundingInquiryDisclaimer" defaultMessage={defaultMessage.fundingInquiryDisclaimer} />
            </p>
          </Grid>
        </Grid>
        <Modal
          open={showModal}
          onClose={closeModal}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            overflow: "auto",
          }}>
          <LenderOptionConfirmationModal
            customerBusiness={customerBusiness}
            loanAmount={loanAmount}
            maxApproved={props.maxApproved}
            closeModal={closeModal}
            termLength={_.get(selectedProductIndex, 'term')}
            monthlyPayment={_.get(selectedProductIndex, 'payment')}
            transactionGUID={transactionGUID}
            formSubmitted={submitted}
            setFormSubmit={setSubmitted}
            vendorGUID={vendorGUID}
            propId={propId}
            proposalId={proposalId}
            interestRate={_.get(selectedProductIndex, 'simpleInterest')}
            paymentId={_.get(selectedProductIndex, 'paymentId')}
            docFee={_.get(selectedProductIndex, 'docFee')}
            advancePayment={_.get(selectedProductIndex, 'advancePayment')}
            amountDue={_.get(selectedProductIndex, 'amountDue')}
            creditProduct={selectedProductIndex}
            redirectToSigning={redirectToSigning}
            voId={voId}
            amountDueProposalV2={amountDueProposalV2}
            lenderType={lenderType}
            isQuote={isQuote}
            formId={formId}
            menuOption={
              _.get(selectedProductIndex, 'isMenuOption')
                ? {
                  titleBgColor: _.get(selectedProductIndex, 'titleBgColor'),
                  titleColor: _.get(selectedProductIndex, 'titleColor'),
                  packages: _.get(selectedProductIndex, 'packages'),
                  payment: _.get(selectedProductIndex, 'payment'),
                  name: _.get(selectedProductIndex, 'name'),
                }
                : null
            }
          />
        </Modal>
      </>
    );

  return null
};




const useStyles = makeStyles(() => {
  return {

  };
});


export default PaymentSelector;