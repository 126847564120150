import { useQuery, useLazyQuery, useMutation } from '@apollo/react-hooks';
import { Alert, Button, Checkbox, Container, FormControlLabel, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { LenderProfile, Task } from "../../types";
import React, { SyntheticEvent, useEffect, useState } from 'react';
import { getURLParameter } from '../../../helpers';
import { apiURL } from '../../../config';
import {
  QUERY_LENDER_PROFILE_BY_DYNAMICS_ID,
  UPSERT_DOCUMENT,
  UPDATE_TASK_STATUS,
} from '../../../api';

import _ from 'lodash';
import { TaskStatuses } from '../../../api/types';


const useStyles = makeStyles(() => {
  return {
    containerRoot: {
      backgroundColor: 'white',
      marginTop: "20px",
      marginBottom: "20px",
    },
    noImageProvided: {
      color: '#fd3b3b',
      marginTop: '6px',
    },
  };
});

export const InsuranceVerificationPage = ({
  task,
}: {
  task: any;
}) => {
  const taskItem: any = _.find(task.taskItems, { docType: 'insurance certificate' });
  const uploadedDocumentIdToSet = _.get(taskItem, 'documents[0].documentId');

  const classes = useStyles();
  const [lenderProfile, setLenderProfile] = useState<LenderProfile>();
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(!!uploadedDocumentIdToSet);
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadedDocumentId, setUploadedDocumentId] = useState(uploadedDocumentIdToSet);
  const lenderProfileDynamicsId = getURLParameter('lenderProfileDynamicsId');

  const [
    queryLenderProfileByDynamicsId,
    { data: lenderProfileData },
  ] = useLazyQuery(QUERY_LENDER_PROFILE_BY_DYNAMICS_ID);

  const [updateTaskStatus] = useMutation(UPDATE_TASK_STATUS);

  useEffect(() => {
    if (lenderProfileDynamicsId) {
      queryLenderProfileByDynamicsId({
        variables: { lenderProfileDynamicsId },
      });
    }
  }, [lenderProfileDynamicsId]);

  useEffect(() => {
    if (lenderProfileData && lenderProfileData.getLenderProfileByDynamicsId) {
      setLenderProfile(lenderProfileData.getLenderProfileByDynamicsId);
    }
  }, [lenderProfileData]);

  const uploadButtonHandler = (elementId: string) => {
    const file = document.getElementById("insurance-certificate");
    if (!!file) {
      file.click();
    }
  };

  const uploadFileChanged = async (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const fileToUpload: any = _.get(event, 'currentTarget.files[0]', false);
      setSelectedFile(fileToUpload as any);
      const formData = new FormData();
      formData.append(
        "operations",
        JSON.stringify({
          query: UPSERT_DOCUMENT,
          variables: {
            input: {
              vendorOpportunityId: task.vendorOpportunityId,
              creditSubId: task.creditSubId,
              source: "Task Documents Page",
              createdBy: "Applicant",
              docDescription: "Uploaded By Insurance Broker",
              docType: taskItem.docType,
              docName: taskItem.docType,
              taskItemId: taskItem.taskItemId,
            },
            fileToUpload,
          },
        })
      );
      if (fileToUpload) {
        formData.append(
          "map",
          JSON.stringify({
            0: ["variables.file"],
          })
        );
        formData.append("0", fileToUpload);
      }

      const response = await fetch(apiURL, {
        method: "POST",
        body: formData,
      });
      const documentId = _.get(await response.json(), "data.upsertDocument.document");
      if (documentId) {
        setUploadedDocumentId(documentId);
        await updateTaskStatus({
          variables: {
            taskId: task.taskId,
            status: TaskStatuses.Complete
          },
        });
      }
    } catch (error) {
      console.error(`Error: ${error}`);
    }
  };

  return (
    <>
      <Container
        maxWidth="lg"
        className={classes.containerRoot}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h3">Insurance Verification</Typography>
          </Grid>
          {lenderProfile?.name &&
            <Grid item xs={12}>
              <Typography variant="h5">Lender: {lenderProfile.name}</Typography>
            </Grid>
          }
          {lenderProfile?.insuranceRequirements &&
            <Grid item container>
              <Grid item xs={12}>
                <Typography variant="h5">Insurance Requirements</Typography>
              </Grid>
              <Grid item xs={12}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: lenderProfile.insuranceRequirements,
                  }}
                ></div>
              </Grid>
            </Grid>
          }
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isCheckboxChecked}
                  onChange={() => setIsCheckboxChecked(!isCheckboxChecked)}
                  color="primary"
                  disabled={!!uploadedDocumentId}
                />
              }
              label="Insured party’s coverage meets the above coverage requirements"
            />
          </Grid>

          <Grid item container spacing={2}>
            {!!selectedFile && (
              <img
                src={URL.createObjectURL(selectedFile)}
                style={{
                  margin: '15px 15px 15px 0',
                  maxWidth: '100%',
                  maxHeight: '190px',
                  display: 'block',
                }}
              />
            )}
            <input
              id="insurance-certificate"
              type="file"
              style={{ display: 'none' }}
              onChange={uploadFileChanged}
              name="insurance-certificate"
            />
            <Grid item xs={12}>
              {!uploadedDocumentId && <Button
                variant="outlined"
                onClick={(e: SyntheticEvent) => {
                  uploadButtonHandler("insurance-certificate");
                }}
                disabled={!isCheckboxChecked}
              >
                Upload Insurance Certificate
              </Button>
              }
            </Grid>
          </Grid>
          {uploadedDocumentId && <Grid item xs={12}>
            <Alert severity="success">The certificate has been successfully uploaded!</Alert>
          </Grid>
          }
        </Grid>
      </Container>
    </>
  );
};
