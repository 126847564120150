import { useMutation } from '@apollo/react-hooks';
import { Grid, LinearProgress, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Form, Formik, FormikProps, FormikValues } from 'formik';
import _ from 'lodash';
import { useEffect, useState, forwardRef, useContext, useRef } from 'react';
import { Beforeunload } from 'react-beforeunload';
import { FormattedMessage } from 'react-intl';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  apiMapping,
  MUTATION_CREATE_CREDIT_APP,
  MUTATION_CREATE_CREDIT_APP_RAW_VALUES,
  getCreditAppStatus,
  SEND_SLACK_NOTIFICATION,
} from '../api';
import {
  SectionTypes,
  SectionEquipmentTypes,
  CreditAppResponseType,
} from '../api/types';
import { Message, VendorContext, CommonDataContext } from '../context';
import {
  destroyLocalStorage,
  restoreSaveFormData,
  setLocalStorageWithExpiry,
} from '../context/SaveAndReturn';
import { getURLParameter, checkIfRulesSatisfied, hasFieldsErrors } from '../helpers';
import { LinearProgressWithLabel } from './elements';
import { OCAv4Page } from './OCAv4Page';
import ProcessingScreen from './ProcessingScreen';
import { SaveAndReturn } from './SaveAndReturn';

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { RuleEvaluatorWrapper } from './RuleEvaluationWrapper';
import { Constants } from "../constants"
import mixpanel from '../mixpanel';
import { EquipmentViewOnly } from './sections';
import { PagesWizard } from './PagesWizard';

const useStyles = (props: any) =>
  makeStyles((theme) => ({
    buttonsContainer: {
      marginTop: '30px',
    },
    prevButton: {
      marginRight: '40px',
      padding: '8px 40px',
      height: 42,
      width: 110,
    },
    nextButton: {
      padding: '8px 40px',
      height: 42,
      width: 110,
    },
    ocaPage: {
      '& .MuiFormLabel-root': {
        color: _.get(props, 'textColor', '#1473E6'),
      },
      '& .outer-label': {
        whiteSpace: 'normal',
        marginBottom: '5px',
      },
    },
  }));

const Alert = forwardRef(function Alert(props: any, ref: any) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const OCAv4 = ({
  config,
  vendorContactId,
  locationId,
  vendorGUID,
  publicData,
  formId,
  setRepData,
  setRepList,
  activeTemplate,
}: {
  config: any;
  vendorGUID: string;
  vendorContactId: string;
  locationId: string;
  publicData: any;
  formId: string;
  setRepData: any;
  setRepList: any;
  activeTemplate: any;
}) => {
  const navigate = useNavigate();
  const lenderProfileId = getURLParameter('lenderProfileId') || null;
  const [dynamicsAccountId, setDynamicsAccountId] = useState(vendorGUID);
  const [searchParams, setSearchParams] = useSearchParams();

  const { color, textColor, partner, partnerLink } = useContext(VendorContext);
  let classes = useStyles({
    color,
    textColor
  })();

  const verifyURLSourceParam = (param: string) => {
    if (!param) return undefined;

    const acceptedValues = ["PP", "ILP", "FP"]

    return _.includes(acceptedValues, param) ? param : undefined;
  }

  const getEquipmentDataFromUrlParams = () => {
    return {
      make: getURLParameter('make') || getURLParameter('equipmentMake'),
      model: getURLParameter('model') || getURLParameter('equipmentModel'),
      serialNumberVin: getURLParameter('serialNumber') || getURLParameter('serialNum') || getURLParameter('VIN'),
      stockId: getURLParameter('stockId') || getURLParameter('stockNumber') || getURLParameter('SN'),
      retailPrice: getURLParameter('retailPrice') || getURLParameter('price'),
      equipmentPictureUrl: getURLParameter('pictureURL') || getURLParameter('pictureUrl') || getURLParameter('equipmentPictureUrl'),
      newUsed: getURLParameter('condition') || getURLParameter('equipmentCondition'),
      year: getURLParameter('year') || getURLParameter('equipmentYear'),
      hours: getURLParameter('hours') || getURLParameter('equipmentHours'),
      city: getURLParameter('city') || getURLParameter('equipmentCity'),
      postalCode: getURLParameter('postalCode') || getURLParameter('equipmentPostalCode'),
      state: getURLParameter('state') || getURLParameter('equipmentState'),
      quantity: getURLParameter('quantity'),
      mileage: getURLParameter('mileage'),
      tradeInValue: getURLParameter('tradeInValue'),
      amountOwedOnTradeIn: getURLParameter('amountOwedOnTradeIn'),
      additionalDescription: getURLParameter('additionalDescription'),
    };
  };

  const source = verifyURLSourceParam(getURLParameter('source'));

  const [pageIndex, setPageIndex] = useState(0);  // page index from config
  const [pageNumber, setPageNumber] = useState(1);  // page number from visible pages
  const [visiblePagesCount, setVisiblePagesCount] = useState(0);  // total visible pages count
  const [open, setOpen] = useState(false);
  const [isPSChannelProcessing, setIsPSChannelProcessing] = useState(false);
  const [isCreditAppSubmitted, setIsCreditAppSubmitted] = useState(false);
  const [isCreateDraftCreditAppInProgress, setIsCreateDraftCreditAppInProgress] = useState(false);

  const formikRef = useRef<FormikProps<FormikValues>>(null);

  const equipmentDataFromUrlParams = getEquipmentDataFromUrlParams();
  const { year, make, model, equipmentPictureUrl } = equipmentDataFromUrlParams;
  const isEnoughDataForEquipment = [year, make, model, equipmentPictureUrl].every(val => !!val);

  const [
    createCreditApplication,
    {
      data: createCreditApplicationResponse,
      loading: loadingCreateCreditApplication,
    },
  ] = useMutation(MUTATION_CREATE_CREDIT_APP);

  const [createCreditApplicationRawValues] = useMutation(MUTATION_CREATE_CREDIT_APP_RAW_VALUES);

  const [sendSlackNotification] = useMutation(SEND_SLACK_NOTIFICATION);

  useEffect(() => {
    if (!vendorGUID) {
      setDynamicsAccountId(_.get(publicData, 'dynamicsAccountId', null));
    }
  }, [publicData]);

  const handleClose = (event: any, reason: any) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  /**
    * Get TypedSections (References, Equipments) Initial Values for rormik.
    * Formik then map inputs values to appropriate object.
    * @param config
    * @param sectionType
    * @returns Object where key - is a key to set to formik values, value - object with initial section config.
    */
  const getTypedSectionsInitialValues = (sectionType: SectionTypes, config: any) => {
    const sectionsSettingsByType = {
      [SectionTypes.DynamicReference]: {
        getInitialSection: (section: any) => {
          return {
            referenceType: section.referenceType,
            referenceIdx: section.referenceIdx,
            ownerIdx: section.config.ownerIdx === 'not-set' ? null : section.config.ownerIdx,
          };
        },
        key: 'referenceIdx',
      },
      [SectionTypes.Equipment]: {
        getInitialSection: (section: any) => {
          const initialSection = {
            type: section.equipmentType,
            equipmentIdx: section.equipmentIdx,
          };
          const isNeedToMergeDataFromUrlParams = section.equipmentType === SectionEquipmentTypes.Purchase &&
            _.some(_.values(equipmentDataFromUrlParams));
          if (isNeedToMergeDataFromUrlParams) {
            _.merge(initialSection, equipmentDataFromUrlParams);
          }
          return initialSection;
        },
        key: 'equipmentIdx',
      },
    };

    const typedSections = _(config)
      .flatMap("sections")
      .filter({ sectionType: sectionType })
      .reject({ equipmentType: SectionEquipmentTypes.ViewOnly })  // plan is to remove ViewOnly sections from OCA configs
      .value();

    // a hotfix to create an equipment record if there are no equipments sections, but equipment Data in URL exists
    if (sectionType === SectionTypes.Equipment && _.isEmpty(typedSections) && isEnoughDataForEquipment) {
      return {
        0: {
          ...equipmentDataFromUrlParams,
          equipmentIdx: 0,
          type: SectionEquipmentTypes.Purchase
        }
      }
    }

    const sectionSettings = _.get(sectionsSettingsByType, sectionType);
    if (!sectionSettings) { // to fix 'sectionSettings' is possibly 'undefined' error
      return;
    }
    return _.reduce(typedSections, (result, section) => {
      const initialSection = sectionSettings.getInitialSection(section);
      _.set(result, section[sectionSettings.key], initialSection);
      return result;
    }, {});
  };

  /**
   * Grabs all elements
   * @param formId
   * @returns Object
   */
  const stubInitialValues = (formId: string) => {
    //Grabs elements from URL and push it into localStorage (Save And Return)
    const amountRequested = getURLParameter('price');
    const equipmentFinancing = amountRequested;
    const applyingSelect = (amountRequested || amountRequested === '0') ? "equipmentFinancing" : null;
    const bizPhone = getURLParameter('phone');
    const contactEmail = getURLParameter('contactEmail');
    const businessName = getURLParameter('businessName');
    const equipmentId = getURLParameter('equipId');
    const manufacturer = getURLParameter('manufacturer');
    const ccg_transaction_equipmentdescription = getURLParameter('description') || getURLParameter('equipmentDescription');
    const firstName = getURLParameter('firstName');
    const lastName = getURLParameter('lastName');
    const email = getURLParameter('email');
    const locationId = getURLParameter('locationId');
    const references = getTypedSectionsInitialValues(SectionTypes.DynamicReference, config);
    const equipments = getTypedSectionsInitialValues(SectionTypes.Equipment, config);


    const saveAndReturnData = restoreSaveFormData(
      {
        amountRequested,
        equipmentFinancing,
        applyingSelect,
        contactEmail,
        bizPhone,
        businessName,
        equipmentId,
        manufacturer,
        ccg_transaction_equipmentdescription,
        firstName,
        lastName,
        email,
        locationId,
        references,
        equipments,
      },
      formId
    );
    return saveAndReturnData;
  };

  useEffect(() => {
    if (isCreditAppSubmitted && !isCreateDraftCreditAppInProgress) {
      submitToAPI(formikRef.current?.values);
    }
  }, [isCreditAppSubmitted, isCreateDraftCreditAppInProgress]);

  const processPSChannel = async (psChannel: string, values: any, searchParams: any, creditAppResult: CreditAppResponseType) => {
    let retry = (() => {
      let count = 0;
      return (max: number, timeout: number, next: any) => {
        //@ts-ignore
        getCreditAppStatus(creditAppResult.id).then((response: any, error: any, body: any) => {
          const creditApplication = _.get(response, 'data.data.creditApplication');
          if (!creditApplication) {
            window.location.href = `/error?${searchParams.toString()}`;
          }
          if (error || response.status !== 200 ||
            (!creditApplication.prequalStatus &&
              creditApplication.lenderStatus !== "PAYMENTS_GENERATED" &&
              creditApplication.lenderStatus !== "PROPOSAL")
          ) {
            if (count++ < max) {
              return setTimeout(() => {
                retry(max, timeout, next);
              }, timeout);
            } else {
              if (creditApplication.lenderStatus === "DECLINED") {
                return (window.location.href = `/declined?${searchParams.toString()}`);
              }
              if (creditApplication.lenderStatus === "EXTERNAL_API_ERROR" ||
                (creditApplication.lenderStatus === "APPROVED" && !creditApplication.proposal.lenderPaymentOptions)
              ) {
                return (window.location.href = `/apierr?${searchParams.toString()}`);
              }
              window.location.href = `/apierr?${searchParams.toString()}`;
              return next(new Error("max retries reached"));
            }
          }
          next(response, body);
        });
      };
    })();
    if (!creditAppResult.id) {
      return;
    }
    setIsPSChannelProcessing(true);
    const maxTries = 21;
    const timeInterval = 5000;
    retry(maxTries, timeInterval, function (response: any, body: any) {
      const creditApplication = _.get(response, 'data.data.creditApplication');
      let { prequalStatus, paymentSelectorURLV2, lenderStatus } = creditApplication;
      if (psChannel !== "postgres") {
        if (values.owners[0].ssn === "941-18-9841") {
          prequalStatus = "approved";
        }
        if (prequalStatus === "probation_review" || prequalStatus === "pending" || prequalStatus === "declined" || prequalStatus === "review_required") {
          return (window.location.href = `/receipt?${searchParams.toString()}`);
        } else if (prequalStatus === "approved" || prequalStatus === "pre_approved") {
          return (window.location.href = paymentSelectorURLV2);
        }
        if (psChannel === "both") {
          let proposalId = creditApplication.proposal.id;
          if (proposalId) {
            return (window.location.href = `/ps?${searchParams.toString()}&proposalId=${proposalId}&av`);
          }
        }
      } else {
        let proposalId = creditApplication.proposal.id;
        if (proposalId) {
          return (window.location.href = `/ps?${searchParams.toString()}&proposalId=${proposalId}&av`);
        }
      }
    });
  };

  /**
   * Submits to API via GraphQL
   * Transforms data shape into expected by schema
   * @param values
   */
  const submitToAPI = async (values: any) => {

    const createRawValues = async (errorMessages: string[]) => {
      try {
        await createCreditApplicationRawValues({
          variables: {
            createCreditApplicationRawValuesInput: {
              rawValues: JSON.stringify(JSON.stringify(values)), // Call JSON.stringify twice to ensure proper escaping to a string.
            }
          }
        });
      } catch (error: any) {
        const networkError = _.get(error, 'networkError.result.errors[0].message');
        const errorMessage = `Create Credit App Raw Values resulted in an error: ${error.message}. ${networkError ? networkError : ''}`;
        errorMessages.push(errorMessage);
      }
    };

    const setAttemptToSubmit = async (
      vendorContactId: string | undefined,
      locationId: string | undefined,
      errorMessages: string[]
    ) => {
      try {
        // Set attemptToSubmit separatly to be able to track any failers during main createCreditApplication call. Call createCreditApplication with minimum set of data
        const attemptToSubmitPayload = apiMapping({
          formId,
          locationId,
          vendorContactId,
          vendorGUID: dynamicsAccountId,
          values: _.pick(values, ['firstName', 'lastName', 'email']),
          draft: true,
          attemptToSubmit: true,
        });
        await createCreditApplication({
          variables: {
            creditApplicationInput: attemptToSubmitPayload,
          },
        });
      } catch (error: any) {
        const networkError = _.get(error, 'networkError.result.errors[0].message');
        const errorMessage = `Set Attempt To Submit Request resulted in an error: ${error.message}. ${networkError ? networkError : ''}`;
        errorMessages.push(errorMessage);
      }
    };

    const submitCreditApplication = async (
      vendorContactId: string | undefined,
      locationId: string | undefined,
      errorMessages: string[]
    ) => {
      try {
        const payload = apiMapping({
          formId,
          locationId,
          vendorContactId,
          vendorGUID: dynamicsAccountId,
          values,
          draft: false,
          activeTemplate,
          lenderProfileId,
          attemptToSubmit: true,
        });
        await createCreditApplication({
          variables: {
            creditApplicationInput: payload,
          },
        });
      } catch (error: any) {
        const networkError = _.get(error, 'networkError.result.errors[0].message');
        const errorMessage = `Submit Credit App resulted in an error: ${error.message}. ${networkError ? networkError : ''}`;
        errorMessages.push(errorMessage);
      }
    };

    const handleErrorMesagesAlert = (errorMessages: string[]) => {
      let message = _.join(errorMessages, ' | ');
      message += ` formId: ${formId}, email: ${values.email}, url: ${window.location.href}`;
      sendSlackNotification({
        variables: {
          message
        }
      });
    };

    if (!!partner && !!partnerLink) {
      mixpanel.track("OCA Submitted", { send_immediately: true });
      console.log(`:: TRACKED :: OCA Submitted`);
    }

    try {
      const vendorContactIdValue = vendorContactId || values.vendorContactId || undefined;
      const locationIdValue = values.locationId || undefined;

      const errorMessages: string[] = [];

      await createRawValues(errorMessages);
      await setAttemptToSubmit(vendorContactIdValue, locationIdValue, errorMessages);
      await submitCreditApplication(vendorContactIdValue, locationIdValue, errorMessages);

      _.some(errorMessages)
        ? handleErrorMesagesAlert(errorMessages)
        : destroyLocalStorage(formId);  // destroy only on success
    } catch (error: any) {
      console.error(`Create Credit App resulted in an error: ${error.message}`);
      const networkError = _.get(error, 'networkError.result.errors[0].message');
      const message = `Create Credit App resulted in an error: ${error.message}. ${networkError ? networkError : ''}`;
      handleErrorMesagesAlert([message]);
    }
    finally {
      // redirect regardless of the try result to avoid duplicated submission
      const customLinkToRedirect = _.get(activeTemplate, "jsonSettings.confirmationPage.redirectLink", null);
      if (customLinkToRedirect) {
        window.location.href = customLinkToRedirect;
        return;
      }
      navigate(`/receipt?${searchParams.toString()}`);
    }


    //navigate(urlToRedirect);
    /*     try {
          const { data }: any = await createCreditApplication({
            variables: {
              creditApplicationInput: payload,
            },
          });
          const creditAppResult: CreditAppResponseType = data.createCreditApplication;
          if (creditAppResult.success) {
            // process psChannel - logic copied from OCAv3
            const psChannel = _.get(partnerLink, "psChannel") || _.get(partner, "psChannel");
            if (psChannel === "crm" || psChannel === "postgres" || psChannel === "both") {
              return processPSChannel(psChannel, values, searchParams, creditAppResult);
            }
            const customLinkToRedirect = _.get(activeTemplate, "jsonSettings.confirmationPage.redirectLink", null);
            const urlToRedirect = customLinkToRedirect || `/receipt?${searchParams.toString()}`;
            //@ts-ignore
            window.top.location = urlToRedirect;
          } else {
            if (creditAppResult.errorMessage === 'credit_app_already_submitted') {
              //@ts-ignore
              window.top.location = `/dedupe?${searchParams.toString()}`;
            }
          }
        } catch (error: any) {
          setOpen(true);
          console.log(`%c${error.message}`, 'color:red');
        } */
  };

  return (
    <>
      {isPSChannelProcessing
        ? <ProcessingScreen />
        : (
          <>
            <LinearProgressWithLabel
              totalPages={visiblePagesCount}
              pageNumber={pageNumber}
            />
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
              <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                There was an issue submitting your application, please try again later
              </Alert>
            </Snackbar>

            {/*             {pageIndex >= 1 && !isLastPage ? (
              <Beforeunload onBeforeunload={(event: any) => event.preventDefault()} />
            ) : null} */}

            {pageIndex === 0 && isEnoughDataForEquipment &&
              <EquipmentViewOnly equipmentData={equipmentDataFromUrlParams} />
            }

            <Formik
              validateOnChange={false}
              validateOnBlur={false}
              validate={() => { }}
              initialValues={stubInitialValues(formId)}
              onSubmit={() => { }}
              innerRef={formikRef}
            >
              {({ errors, values }) => {
                //On Values changed update the Store
                setLocalStorageWithExpiry(values, formId);

                //Update LocalStorage so we can save the form values for later
                return (
                  <Grid className={classes.ocaPage} container spacing={5}>
                    <SaveAndReturn
                      values={values}
                      errors={errors}
                      formId={formId}
                      vendorContactId={vendorContactId}
                      vendorGUID={dynamicsAccountId}
                      locationId={locationId}
                      activeTemplate={activeTemplate}
                      source={source}
                      isCreditAppSubmitted={isCreditAppSubmitted}
                      setIsCreateDraftCreditAppInProgress={setIsCreateDraftCreditAppInProgress}
                    />
                    <Grid item lg={12}>
                      <Grid container>
                        <Grid item lg={12}>
                          {formikRef.current && <Form>
                            <RuleEvaluatorWrapper
                              rules={config[pageIndex].rules}
                              elementType={'page'}
                              rulesSatisfactionType={config[pageIndex].rulesSatisfactionType}
                              children={
                                <OCAv4Page
                                  vendorContactId={vendorContactId}
                                  locationId={locationId}
                                  config={config[pageIndex]}
                                  setRepData={setRepData}
                                  setRepList={setRepList}
                                  template={activeTemplate}
                                />
                              }
                            />
                          </Form>
                          }
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                      {!isCreditAppSubmitted && <PagesWizard
                        formikRef={formikRef}
                        pageNumber={pageNumber}
                        pageIndex={pageIndex}
                        config={config}
                        setPageNumber={setPageNumber}
                        setPageIndex={setPageIndex}
                        setVisiblePagesCount={setVisiblePagesCount}
                        setIsCreditAppSubmitted={setIsCreditAppSubmitted}
                      />}
                      {isCreditAppSubmitted && <LinearProgress />}
                    </Grid>
                  </Grid>
                );
              }}
            </Formik>
          </>
        )
      }
    </>
  );
};
