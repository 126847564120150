import { Container, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useFormikContext } from 'formik';
import { OCAv4Section } from '../OCAv4Section';
import { RuleEvaluatorWrapper } from '../RuleEvaluationWrapper';
import { SectionType } from '../types';
import MainHeader from '../../layouts/components/MainHeader';
import { ContactForm } from '../ContactForm';
import Footer from '../../layouts/components/Footer';


const useStyles = makeStyles(() => {
  return {
    containerRoot: {
      //backgroundColor: 'white',
      marginTop: "20px",
      marginBottom: "20px",
    },
  };
});

export const ContactPage = ({
  publicAccount,
  vendorGUID,
  partner,
  partnerLink
}: {
  publicAccount: any;
  vendorGUID: string;
  partner: any;
  partnerLink: any
}) => {
  const classes = useStyles();
  const formik: any = useFormikContext();

  return (
    <>
      <Container
        maxWidth="lg"
        className={classes.containerRoot}
      >
        <MainHeader partner={partner} />
        <ContactForm
          publicAccount={publicAccount}
          vendorGUID={vendorGUID}
          partner={partner}
          partnerLink={partnerLink}
        />
      </Container>
      <Footer />
    </>
  );
};
