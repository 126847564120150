import { createContext } from 'react';

type defaultContextType = {
	logo?: string;
	color?: string;
	secColor?: string;
	accountName?: string;
	textColor?: string;
	panelTextColor?: string;
	partner?: any;
	partnerLink?: any;
};
const defaultContext: defaultContextType = {};
const VendorContext = createContext(defaultContext);
const VendorProvider = VendorContext.Provider;
const VendorConsumer = VendorContext.Consumer;

export { VendorContext, VendorProvider, VendorConsumer };
