import { Button, Card, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FormattedMessage } from 'react-intl';
import { CommonDataContext, Message } from '../context';
import { Constants } from "../constants"
import { SyntheticEvent, useContext, useEffect, useState } from 'react';
import _ from 'lodash';
import { checkIfRulesSatisfied, hasFieldsErrors } from '../helpers';

const useStyles = makeStyles(() => ({
  buttonsContainer: {
    marginTop: '30px',
  },
  prevButton: {
    marginRight: '40px',
    padding: '8px 40px',
    height: 42,
    width: 110,
  },
  nextButton: {
    padding: '8px 40px',
    height: 42,
    width: 110,
  },
}));

interface Props {
  formikRef: any,
  pageNumber: number,
  pageIndex: number,
  config: any,
  setPageNumber: Function,
  setPageIndex: Function,
  setVisiblePagesCount: Function,
  setIsCreditAppSubmitted: Function,
};

export const PagesWizard = ({ formikRef, pageNumber, pageIndex, config, setPageNumber, setPageIndex, setVisiblePagesCount, setIsCreditAppSubmitted }: Props) => {
  const classes = useStyles();

  const [isLastPage, setIsLastPage] = useState(false);
  const [direction, setDirection] = useState(Constants.pagesAvailableDirections.next);

  const { setHighlightErrorFieldsState } = useContext(CommonDataContext);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    const isCurrentPageHiddedByRules = _.get(config, [pageIndex, 'config', 'displayed'], null) === false;
    if (isCurrentPageHiddedByRules) {
      setPageIndex((prevState: number) => direction === Constants.pagesAvailableDirections.next
        ? (prevState + 1)
        : (prevState - 1)
      );
      return;
    }
    const visiblePages = getVisiblePages();
    const pageNumber = _.indexOf(visiblePages, config[pageIndex]) + 1;
    const visiblePagesCount = _.size(visiblePages);
    setPageNumber(pageNumber);
    setVisiblePagesCount(visiblePagesCount);
    setIsLastPage(pageNumber === visiblePagesCount);

    try {
      //@ts-ignore
      window.waitForGlobal('_mfq', function () {
        //@ts-ignore
        window._mfq.push([
          'newPageView',
          `${window.location.host}/page/${pageIndex + 1}`,
        ]);
        //@ts-ignore
      });
    } catch (error) { }
  }, [pageIndex]);

  const getVisiblePages = (): Array<any> => {
    if (!formikRef.current) {
      return config;
    }
    return _.filter(config, page => {
      const pageRules = _.get(page, 'rules', []);
      return checkIfRulesSatisfied(formikRef.current?.values, pageRules, page.rulesSatisfactionType);
    });
  };

  const nextButtonHandler = (e: SyntheticEvent) => {
    setHighlightErrorFieldsState({ state: false });
    if (hasFieldsErrors(formikRef.current?.errors)) {
      console.log(`in hasFieldsErrors: ${JSON.stringify(formikRef.current?.errors)}`);
      setHighlightErrorFieldsState({ state: true });
      return;
    }
    setDirection(Constants.pagesAvailableDirections.next);
    isLastPage
      ? setIsCreditAppSubmitted(true)
      : setPageIndex((prevState: number) => prevState + 1);
  };

  return (
    <Grid
      container
      justifyContent="center"
      spacing={4}
      className={classes.buttonsContainer}
    >
      {pageNumber !== 1 && (
        <Grid item>
          <Button
            className={classes.prevButton}
            variant="outlined"
            color="primary"
            onClick={() => {
              setDirection(Constants.pagesAvailableDirections.prev);
              setPageIndex((prevState: number) => prevState - 1);
            }}
          >
            <FormattedMessage
              id="previous"
              defaultMessage={Message.previous}
            />
          </Button>
        </Grid>
      )}
      <Grid item>
        <Button
          className={classes.nextButton}
          variant="contained"
          color="primary"
          onClick={nextButtonHandler}
        >
          <FormattedMessage
            id={`${isLastPage ? 'submit' : 'next'}`}
            defaultMessage={`${isLastPage ? 'submit' : 'next'}`}
          />
        </Button>
      </Grid>
    </Grid>
  )
}