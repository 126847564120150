import { Card } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  cardContainer: {
    display: 'flex',
    margin: "16px 0",
    padding: 16,
    gap: 24,
    flexWrap: "wrap"
  },
  equipmentImage: {
    height: 224,
    width: 224,
    objectFit: "cover"
  },
  equipmentInfo: {
    display: 'flex',
    flexDirection: 'column',
    "& h2": {
      margin: "8px 0"
    }
  },
  detailsContainer: {
    display: "flex",
    marginTop: 8,
    height: "100%",
    columnGap: 64,
    flexWrap: "wrap"
  },
  detailsList: {
    listStyleType: "none",
    margin: 0,
    paddingLeft: 8,
    "& li": {
      marginBottom: 16
    }
  }
}));

const priceFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0
});

interface EquipmentData {
  make: string,
  model: string,
  serialNumberVin: string,
  stockId: string,
  retailPrice: string,
  equipmentPictureUrl: string,
  newUsed: string,
  year: string,
  hours: string,
  city: string,
  postalCode: string,
  state: string,
  quantity: string,
  mileage: string,
  tradeInValue: string,
  amountOwedOnTradeIn: string,
}


interface Props {
  equipmentData: EquipmentData
}


export const EquipmentViewOnly = ({ equipmentData }: Props) => {
  let classes = useStyles();

  const { year, make, model, equipmentPictureUrl, retailPrice, newUsed, stockId, serialNumberVin, city, state } = equipmentData;

  return (
    <Card className={classes.cardContainer}>
      <img className={classes.equipmentImage} alt="equipment-image" src={equipmentPictureUrl} />
      <div className={classes.equipmentInfo}>
        <h3>{year} {make} {model}</h3>
        <div className={classes.detailsContainer}>
          <ul className={classes.detailsList}>
            {retailPrice && <li><b>Price:</b> {priceFormatter.format(parseFloat(retailPrice))}</li>}
            {year && <li><b>Year:</b> {year}</li>}
            {make && <li><b>Make:</b> {make}</li>}
            {model && <li><b>Model:</b> {model}</li>}
          </ul>
          <ul className={classes.detailsList}>
            {newUsed && <li><b>Condition:</b> {newUsed}</li>}
            {stockId && <li><b>Stock Number:</b> {stockId}</li>}
            {serialNumberVin && <li><b>VIN:</b> {serialNumberVin}</li>}
            {(state || (state && city)) && <li><b>Location:</b> {city ? city + ', ' + state : state}</li>}
          </ul>
        </div>
      </div>
    </Card>
  )
}