import axios from "axios";
import { apiURL } from "../config";

const getSigningTask = VOId => {
  return axios.post(apiURL, {
    operationName: null,
    variables: {},
    query: `{signingTask(VOId: "${VOId}") {
        url
      
    }}`,
  });
};

export default getSigningTask;
