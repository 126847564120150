import { gql } from 'apollo-boost';

export const QUERY_CONTACT = gql`
  query GetContact($dynamicsId: ID!) {
    contact(dynamicsId: $dynamicsId) {
      email
      firstName
      lastName
      fullName
      title
      phoneNumber
      vendorContactRole
      mugshot
      status
      accountId
      locationId
    }
  }
`;
