import { getURLParameter } from '../../helpers';
import { useContext, useEffect, useState } from 'react';
import styles from '../../CreditApplicationFormWrapper.module.css';
import { VendorContext } from '../../context';
import defaultMessage from '../../context/Message';
import { FormattedMessage } from 'react-intl';

import _ from 'lodash';

const config = require('../../config');


export const ReceiptPage = ({
  publicAccount,
  activeTemplate,
}: {
  publicAccount: any;
  activeTemplate: any;
}) => {
  const {
    color: vendorColor,
    logo: vendorLogo,
    partner,
    partnerLink,
  } = useContext(VendorContext);
  const [customPageLayout, setCustomPageLayout] = useState();
  const [logoToRender, setLogoToRender] = useState<string | undefined>(undefined);
  const isPartnerOCA = _.some(partner) && !_.some(partnerLink);

  useEffect(() => {
    if (!_.isEmpty(publicAccount) && !_.isEmpty(activeTemplate)) {
      const currentLocation = _.find(publicAccount.locations, { 'locationId': getURLParameter('locationId') });
      const logoToRender = _.get(currentLocation, "logo") || vendorLogo;
      setLogoToRender(logoToRender);
      setCustomPageLayout(_.get(activeTemplate, "jsonSettings.confirmationPage.layout", null));
    }
  }, [publicAccount, activeTemplate]);

  return (
    <>
      {!isPartnerOCA && logoToRender ? (
        <div className={styles.vendorLogoWrapper}>
          <img
            src={
              logoToRender.includes('/')
                ? `https://${config.S3_BUCKET_NAME}.s3-us-west-2.amazonaws.com/${logoToRender}`
                : `https://credit-app-images.s3-us-west-2.amazonaws.com/${logoToRender}`
            }
            alt="Vendor Logo"
          />
        </div>
      ) : null
      }
      {customPageLayout ? (
        <div
          dangerouslySetInnerHTML={{
            __html: customPageLayout,
          }}></div>
      ) : (
        <>
          <span className={styles.cardTitle} data-test-id="submittal-received-title">
            <FormattedMessage id="thankYou" defaultMessage={defaultMessage.thankYou} />
          </span>
          <p>
            <FormattedMessage id="thankYou2" defaultMessage={defaultMessage.thankYou2} />
          </p>
          <br />
        </>
      )}
    </>
  );
};
