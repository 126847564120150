import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import { FormikProps, useFormikContext } from 'formik';
import _ from 'lodash';
import { useCallback, useEffect, useState, useContext } from 'react';
import { useSearchParams } from 'react-router-dom';
import { CommonDataContext } from '../../context';
import { FormattedMessage } from 'react-intl';
import { FieldType } from '../types';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  select: {
    '& .MuiOutlinedInput-input.MuiSelect-outlined': {
      whiteSpace: 'normal',
    }
  },
  menuItem: {
    '&.MuiMenuItem-root': {
      whiteSpace: 'normal',
    }
  },
}));

const RepresentativeSelect = ({
  itemData,
  value,
  locale,
  externalData,
  externalCallbacks,
  displayLabel,
}: {
  itemData: FieldType;
  value: any;
  locale: string;
  externalData: any;
  externalCallbacks: any;
  displayLabel: string;
}) => {
  const classes = useStyles();
  const [searchParams, setSearchParams] = useSearchParams();
  const { id: fieldId, config: fieldConfig } = itemData;
  const [repList, setRepList] = useState([]);
  const [isQuestionInvalid, setIsQuestionInvalid] = useState(false);
  const [isRepSelectorInvalid, setIsRepSelectorInvalid] = useState(false);
  const vendorGUID = _.get(externalData, 'vendorGUID', null);
  const primaryContact = _.get(externalData, 'primaryContact', null);
  const locationId = _.get(externalData, 'locationId', null);
  const locationList = _.get(externalData, 'locationsList', []);
  const { isMobileView, highlightErrorFieldsState } = useContext(CommonDataContext);

  const [vendorContactIdSelected, setVendorContactIdSelected] = useState(
    _.get(externalData, 'vendorContactId', '')
  );

  const formik: FormikProps<any> = useFormikContext();
  const formikValue = _.get(formik, ['values', fieldConfig.fieldName], null);
  const [questionResponse, setQuestionResponse] = useState(!!formikValue ? 'yes' : '');

  const handleChange = useCallback(
    (e) => {
      setVendorContactIdSelected(e.target.value);
      formik.setFieldValue(itemData.config.fieldName, e.target.value, false);
    },
    [formik]
  );
  const _resetField = (): void => {
    // Check if error is already set to ''
    if (Boolean(_.get(formik.errors, itemData.config.fieldName))) {
      //Remove Error
      formik.setFieldError(itemData.config.fieldName, '');
    }

    if (!_.has(formik.values, itemData.config.fieldName)) { // do not set '' value for untouched fields
      return;
    }

    //Erase value
    formik.setFieldValue(itemData.config.fieldName, '');
  };

  useEffect(() => {
    return () => {
      formik.setFieldError(itemData.config.fieldName, '');
    };
  }, []);

  useEffect(() => {
    const locationId = _.get(externalData, 'locationId', null);
    if (locationId) {
      const locationList = _.get(externalData, 'locationsList', []);
      const found = _.find(locationList, {
        locationId,
      });

      if (!_.isEmpty(found)) {
        setRepList(found.contactsInfo);
      } else {
        setRepList(_.get(externalData, 'repList'));
      }
    } else {
      setRepList(_.get(externalData, 'repList'));
    }

    setVendorContactIdSelected(_.get(externalData, 'vendorContactId', ''));
  }, [externalData]);

  useEffect(() => {
    //When there is no need to display the field, we reset it's state to a clean one
    if (!itemData.config.displayed) {
      _resetField();
    } else {
      const isFieldRequired = _.get(itemData, 'config.required', false);
      const isFormikValueEmpty = !_.get(formik, ['values', fieldConfig.fieldName], false);
      const isRepRequiredButNotSelect = questionResponse == 'yes' &&
        _.isEmpty(value);
      const isFieldValueEmpty = isFieldRequired && ((isFormikValueEmpty && isRepRequiredButNotSelect) || !questionResponse);
      const isRequiredToFill = isFieldValueEmpty && _.some(repList) && !vendorContactIdSelected;
      formik.setFieldError(
        itemData.config.fieldName,
        isRequiredToFill ? 'required' : ''
      );
      setIsQuestionInvalid(highlightErrorFieldsState.state && !questionResponse);
    }
  }, [itemData.config.displayed, itemData.config.required, repList, vendorContactIdSelected, questionResponse]);

  useEffect(() => {
    switch (questionResponse) {
      case 'yes':
        if (!!vendorContactIdSelected) {
          const contactData: any = _.find(repList, {
            dynamicsContactId: vendorContactIdSelected,
          });
          if (!contactData) {
            return;
          }
          searchParams.delete('vendorGUID');
          searchParams.set('vendorcontactId', vendorContactIdSelected);
          setSearchParams(searchParams);
          //@ts-ignore
          window.history.replaceState(
            null,
            'Online Credit App',
            `${process.env.PUBLIC_URL}?${searchParams.toString()}`
          );
          const setRepData = _.get(externalCallbacks, 'setRepData', null);
          setRepData({
            officePhone: contactData.phoneNumber ? contactData.phoneNumber : '',
            mugshot: contactData.mugshot ? contactData.mugshot : '',
            email: contactData.email,
            name: contactData.fullName,
            availability:
              contactData.availability || 'Monday-Friday, 8am-5pm PST',
          });
        }
        break;
      case 'no':
        searchParams.delete('vendorcontactId');
        searchParams.set('vendorGUID', vendorGUID);
        setSearchParams(searchParams);
        //@ts-ignore
        window.history.replaceState(
          null,
          'Online Credit App',
          `${process.env.PUBLIC_URL}?${searchParams.toString()}`
        );
        _resetField();
        if (!!primaryContact) {
          const setRepData = _.get(externalCallbacks, 'setRepData', null);
          setRepData({
            officePhone: primaryContact.phoneNumber,
            email: primaryContact.email,
            name: primaryContact.fullName,
            availability:
              primaryContact.availability || 'Monday-Friday, 8am-5pm PST',
            mugshot: primaryContact.mugshot,
          });
        }
        if (!!locationId && !!locationList) {
          const setRepData = _.get(externalCallbacks, 'setRepData', null);
          let locationInfo = locationList.filter((item: any) => item.locationId === locationId);
          locationInfo = locationInfo[0];
          setRepData({
            officePhone: locationInfo.ocaContact.phoneNumber,
            email: locationInfo.ocaContact.email,
            name: locationInfo.ocaContact.fullName,
            availability: locationInfo.ocaContact.availability || "Monday-Friday, 8am-5pm PST",
            mugshot: locationInfo.ocaContact.mugshot,
          });
        }
    }
  }, [questionResponse, vendorContactIdSelected]);

  useEffect(() => {
    if (highlightErrorFieldsState.state) {
      setIsQuestionInvalid(!questionResponse);
      setIsRepSelectorInvalid(!!formik.getFieldMeta(itemData.config.fieldName).error);
    }
  }, [highlightErrorFieldsState]);

  const handleChangeQuestion = (e: any) => {
    setQuestionResponse(e.target.value);
  };

  if (vendorContactIdSelected || _.isEmpty(repList)) {
    return null;
  }
  return (
    <Box
      component={Grid}
      item
      xl={_.get(fieldConfig, 'columns.xl', 12)}
      lg={_.get(fieldConfig, 'columns.lg', 12)}
      md={_.get(fieldConfig, 'columns.md', 12)}
      sm={_.get(fieldConfig, 'columns.sm', 12)}
      xs={_.get(fieldConfig, 'columns.xs', 12)}
      display={{
        xl: !itemData.config.displayed ? 'none' : 'block',
        lg: !itemData.config.displayed ? 'none' : 'block',
        md: !itemData.config.displayed ? 'none' : 'block',
        sm: !itemData.config.displayed ? 'none' : 'block',
        xs: !itemData.config.displayed ? 'none' : 'block',
      }}
    >
      <Grid container spacing={2}>
        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
          {isMobileView &&
            <InputLabel
              id={fieldConfig.fieldName}
              required={fieldConfig.required}
            >
              <FormattedMessage
                id={'haveSalesRepQuestion'}
                defaultMessage={'haveSalesRepQuestion'}
              />
            </InputLabel>
          }
          <FormControl variant={isMobileView ? "outlined" : "standard"} fullWidth={fieldConfig.fullWidth} error={isQuestionInvalid}>
            {!isMobileView &&
              <InputLabel
                id={fieldConfig.fieldName}
                required={fieldConfig.required}
              >
                <FormattedMessage
                  id={'haveSalesRepQuestion'}
                  defaultMessage={'haveSalesRepQuestion'}
                />
              </InputLabel>
            }
            <Select
              key={fieldId}
              fullWidth={fieldConfig.fullWidth}
              name={`repSelectorQuestion`}
              disabled={fieldConfig.disabled}
              value={questionResponse}
              onChange={handleChangeQuestion}
              label={isMobileView ? '' : 'Are you working with a Representative?'}
              labelId={fieldConfig.fieldName}
              style={{ maxHeight: '40vh' }}
              required={fieldConfig.required}
              variant={isMobileView ? "outlined" : "standard"}
            >
              <MenuItem key={`${fieldId}.yes`} value={'yes'}>
                <FormattedMessage id={'yes'} defaultMessage={'yes'} />
              </MenuItem>
              <MenuItem key={`${fieldId}.no`} value={'no'}>
                <FormattedMessage id={'no'} defaultMessage={'no'} />
              </MenuItem>
            </Select>
            <FormHelperText>
              {_.get(fieldConfig, `helperText.${locale}`, '')}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
          {questionResponse && questionResponse === 'yes' ? (
            <FormControl fullWidth={fieldConfig.fullWidth} error={isRepSelectorInvalid}>
              <InputLabel
                id={fieldConfig.fieldName}
                required={fieldConfig.required}
              >
                {displayLabel}
              </InputLabel>
              <Select
                key={fieldId}
                fullWidth={fieldConfig.fullWidth}
                name={fieldConfig.fieldName}
                disabled={fieldConfig.disabled}
                value={value ? value : ''}
                onChange={handleChange}
                label={displayLabel}
                labelId={fieldConfig.fieldName}
                style={{ maxHeight: '40vh' }}
                required={fieldConfig.required}
                variant={isMobileView ? "outlined" : "standard"}
                className={classes.select}
              >
                {!_.isEmpty(repList) &&
                  repList.map((option: any, index: number) => {
                    return (
                      <MenuItem
                        className={classes.menuItem}
                        key={`${fieldId}${index}`}
                        value={option.dynamicsContactId}
                      >
                        {option.fullName + (option.title && ` - ${option.title}`)}
                      </MenuItem>
                    );
                  })}
              </Select>
              <FormHelperText>
                {_.get(fieldConfig, `helperText.${locale}`, '')}
              </FormHelperText>
            </FormControl>
          ) : null}
        </Grid>
      </Grid>
    </Box>
  );
};

export { RepresentativeSelect };


