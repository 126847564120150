import React, { } from "react";
import { makeStyles } from "@mui/styles";
import { Box, Typography } from "@mui/material";

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ProductLogo from "./ProductLogo";



interface Props {
  product: any,
  primaryColor: string,
};

const ProductCard = ({ product, primaryColor }: Props) => {

  const classes = useStyles();

  return <Box className={classes.productContainer}>
    <ProductLogo product={product} />
    <Box display="flex" justifyContent="space-between" padding={1}>
      <Typography><strong>{product.title}</strong></Typography>
      <InfoOutlinedIcon style={{ color: "#2196F3", marginLeft: 4 }} />
    </Box>

    {product.addons?.map((addon: any) => {
      return <Box key={addon.title}>
        <Box display="flex" padding={1}>
          <CheckCircleIcon style={{ color: primaryColor, marginRight: 4 }} />
          <Typography variant="subtitle2">{addon.title}</Typography>
          <InfoOutlinedIcon style={{ color: "#2196F3", marginLeft: "auto" }} />
        </Box>
      </Box>
    })}

  </Box>
};

const useStyles = makeStyles(() => {
  return {
    productContainer: {
      background: "#F5F5F5",
      margin: "4px"
    },
  };
});

export default ProductCard;
