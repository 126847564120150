import { Box, Button, Grid, FormControl, FormHelperText, InputLabel, Select, MenuItem } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FormikProps, useFormikContext } from 'formik';
import _ from 'lodash';
import React, { useEffect, useState, useContext } from 'react';
import { CommonDataContext } from '../../context';
import { FieldType } from '../types';
import { UPSERT_FILE } from '../../api';
import { apiURL } from '../../config';
import { v4 as uuidv4 } from 'uuid';

const useStyles = makeStyles(() => ({
  docsUploadContainer: {
    marginBottom: "20px"
  }
}));

export const FileInput = ({
  itemData,
  setIsInvalid,
  docToUpload,
  setDocsUpload,
  locale,
}: {
  itemData: any;
  setIsInvalid: any;
  docToUpload: any;
  setDocsUpload: any;
  locale: string;
}) => {
  const fileTypes = [
    {
      value: "Financial",
      label: "Financial Doc",
    },
    {
      value: "tax returns",
      label: "Tax Returns",
    },
    {
      value: "bank statements",
      label: "Bank Statements",
    },
    {
      value: "other",
      label: "Other",
    },
  ];
  const formik: FormikProps<any> = useFormikContext();
  const { isMobileView } = useContext(CommonDataContext);
  const classes = useStyles();
  const selectedFileTypeToSet = docToUpload.docsUpload || _.get(itemData, `config.defaultFileType`) || '';
  const [selectedFileType, setSelectedFileType] = useState(selectedFileTypeToSet);
  const [selectedFile, setSelectedFile] = useState<any>(docToUpload.file || null);

  const uploadButtonHandler = () => {
    const file = document.getElementById('file-input-' + itemData.id);
    if (!!file) {
      file.click();
    }
  };

  const uploadFileChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileToUpload: any = _.get(event, 'currentTarget.files[0]');
    if (!fileToUpload) {
      return;
    }
    const formData = new FormData();
    const fileStringMap = `{"0": ["variables.files.0"]}`;
    formData.append(
      'operations',
      JSON.stringify({
        query: UPSERT_FILE,
        variables: {
          files: [null],
        },
      })
    );
    formData.append('map', fileStringMap);
    formData.append('0', fileToUpload);
    return new Promise(async (resolve, reject) => {
      fetch(apiURL, {
        method: 'POST',
        body: formData,
      })
        .then(response => response.json())
        .then(response => {
          const upsertedFile = _.get(response, 'data.upsertFile[0]');
          if (!upsertedFile) {
            return;
          }
          setSelectedFile(fileToUpload);
          setDocsUpload((docsUpload: any) => docsUpload.map((d: any) => {
            return d.id === docToUpload.id
              ? { ...d, fileToUpload: upsertedFile, docsUpload: selectedFileType, file: { name: fileToUpload.name } }
              : d;
          }))
          setIsInvalid(false);
        })
        .catch((err) => {
          console.log(err);
          setIsInvalid(true);
        });
    });
  };

  return (
    <Grid item container spacing={3}>
      <Grid item xs={6} style={{ display: 'flex', alignItems: 'center' }}>
        <FormControl variant={isMobileView ? "outlined" : "standard"} fullWidth>
          <InputLabel id={'select-' + itemData.id}>File Type</InputLabel>
          <Select
            value={selectedFileType}
            onChange={(e) => setSelectedFileType(e.target.value)}
            variant={isMobileView ? "outlined" : "standard"}
            size="small"
            labelId={'select-' + itemData.id}
          >
            {fileTypes.map((fileType: any) => {
              return <MenuItem key={fileType.value} value={fileType.value}>
                {fileType.label}
              </MenuItem>
            })}
          </Select>
          <FormHelperText>
            {_.get(itemData, `config.helperText.${locale}`, '')}
          </FormHelperText>
        </FormControl>
      </Grid>
      <Grid item xs={6} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        {_.get(selectedFile, 'type') === 'image/png'
          ? (
            <img
              src={URL.createObjectURL(selectedFile)}
              style={{
                margin: '15px 15px 15px 0',
                maxWidth: '150px',
                maxHeight: '190px',
                display: 'block',
              }}
            />
          )
          : (selectedFile && <span>{selectedFile.name}</span>)
        }
        {!docToUpload.fileToUpload && (
          <>
            <input
              id={'file-input-' + itemData.id}
              type="file"
              style={{ display: 'none' }}
              onChange={uploadFileChanged}
              name={'file-input-' + itemData.id}
            />
            <Button
              variant="outlined"
              onClick={uploadButtonHandler}
              disabled={!selectedFileType}
            >
              Upload File
            </Button>
          </>
        )}
        {docToUpload.fileToUpload && (
          <Button
            variant="outlined"
            onClick={() =>
              setDocsUpload((docsUpload: any) => docsUpload.length === 1
                ? [{ id: uuidv4() }]
                : docsUpload.filter((d: any) => d.id !== docToUpload.id))
            }
          >
            Remove Document
          </Button>
        )}
      </Grid>
    </Grid>
  );
};


export const TypedFileInput = ({
  itemData,
  value,
  locale,
}: {
  itemData: FieldType;
  value: any;
  locale: string;
}) => {
  const { config: fieldConfig } = itemData;
  const formik: FormikProps<any> = useFormikContext();
  const classes = useStyles();
  const [isInvalid, setIsInvalid] = useState(false);
  const formikDocsUpload = _.get(formik, `values.docsUpload`) || [{ id: uuidv4() }];
  const [docsUpload, setDocsUpload] = useState(formikDocsUpload);


  const _resetField = (): void => {
    // Check if error is already set to ''
    if (Boolean(_.get(formik.errors, itemData.config.fieldName))) {
      //Remove Error
      formik.setFieldError(itemData.config.fieldName, '');
    }

    if (!_.has(formik.values, itemData.config.fieldName)) { // do not set '' value for untouched fields
      return;
    }

    //Erase value
    formik.setFieldValue(itemData.config.fieldName, '');
    setIsInvalid(false);
  };

  useEffect(() => {
    formik.setFieldValue(`docsUpload`, docsUpload, false);
  }, [docsUpload]);

  useEffect(() => {
    //When there is no need to display the field, we reset it's state to a clean one
    if (!itemData.config.displayed) {
      _resetField();
    } else {
      let isEmpty = false;

      if (itemData.config.required) {
        isEmpty = _.isEmpty(value);
      }

      formik.setFieldError(
        itemData.config.fieldName,
        isInvalid || isEmpty ? 'required' : ''
      );
    }
  }, [itemData.config.displayed, itemData.config.required, isInvalid, value]);

  const docsToRender = _.reject(docsUpload, { docsUpload: 'Addendum' });
  return (
    <Box
      component={Grid}
      item
      xl={_.get(fieldConfig, 'columns.xl', 12)}
      lg={_.get(fieldConfig, 'columns.lg', 12)}
      md={_.get(fieldConfig, 'columns.md', 12)}
      sm={_.get(fieldConfig, 'columns.sm', 12)}
      xs={_.get(fieldConfig, 'columns.xs', 12)}
      display={{
        xl: !itemData.config.displayed ? 'none' : 'block',
        lg: !itemData.config.displayed ? 'none' : 'block',
        md: !itemData.config.displayed ? 'none' : 'block',
        sm: !itemData.config.displayed ? 'none' : 'block',
        xs: !itemData.config.displayed ? 'none' : 'block',
      }}
    >
      {docsToRender.map((docToUpload: any, idx: number) => {
        const isLast = idx === docsToRender.length - 1;
        return <Grid container spacing={2} key={docToUpload.id} className={classes.docsUploadContainer}>
          <Grid item container>
            <FileInput
              itemData={itemData}
              locale={locale}
              setIsInvalid={setIsInvalid}
              docToUpload={docToUpload}
              setDocsUpload={setDocsUpload}
            />
          </Grid>
          {isLast && (
            <Grid item>
              <Button
                variant="outlined"
                disabled={!docToUpload.fileToUpload}
                onClick={() => setDocsUpload([...docsUpload, { id: uuidv4() }])}
              >
                Add Document
              </Button>
            </Grid>
          )}
        </Grid>
      })}
    </Box>
  );
};
