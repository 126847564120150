import { Grid, LinearProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Form, Formik, FormikProps, FormikValues } from 'formik';
import _ from 'lodash';
import { useEffect, useState, useContext, useRef } from 'react';
import { VendorContext } from '../../../../context';
import { OCAv4Page } from '../../../OCAv4Page';
import { RuleEvaluatorWrapper } from '../../../RuleEvaluationWrapper';
import { apiMapping } from '../../../../api';
import { LinearProgressWithLabel } from '../../../elements';
import { PagesWizard } from '../../../PagesWizard';
import { UpdateCreditApplicationV2Input } from '@trnsact/trnsact-shared-types';
import { formatForUpdateCreditAppV2Payload } from '../../../../helpers';


const useStyles = (props: any) =>
  makeStyles((theme) => ({
    ocaPage: {
      '& .MuiFormLabel-root': {
        color: _.get(props, 'textColor', '#1473E6'),
      },
      '& .outer-label': {
        whiteSpace: 'normal',
        marginBottom: '5px',
      },
    },
  }));

export const RecertificationOCA = ({
  ocaConfig,
  publicAccount,
  activeTemplate,
  formikInitialValues,
  externalData,
  handleSubmit,
}: {
  ocaConfig: any;
  publicAccount: any;
  activeTemplate: any;
  formikInitialValues: any;
  externalData: any;
  handleSubmit: Function;
}) => {
  const { color, textColor } = useContext(VendorContext);
  const classes = useStyles({
    color,
    textColor
  })();

  const [pageIndex, setPageIndex] = useState(0);  // page index from config
  const [pageNumber, setPageNumber] = useState(1);  // page number from visible pages
  const [visiblePagesCount, setVisiblePagesCount] = useState(0);  // total visible pages count
  const [isCreditAppSubmitted, setIsCreditAppSubmitted] = useState(false);

  const formikRef = useRef<FormikProps<FormikValues>>(null);

  useEffect(() => {
    if (isCreditAppSubmitted) {
      submit();
    }
  }, [isCreditAppSubmitted]);

  const submit = () => {
    const apiMappingPayload: any = apiMapping({
      values: formikRef.current?.values,
      activeTemplate,
    });

    const updateCreditAppV2Payload: UpdateCreditApplicationV2Input = formatForUpdateCreditAppV2Payload(apiMappingPayload);
    updateCreditAppV2Payload.recertificationDate = new Date().toISOString();
    handleSubmit(updateCreditAppV2Payload);
  };

  return (
    <>
      <LinearProgressWithLabel
        totalPages={visiblePagesCount}
        pageNumber={pageNumber}
      />
      {/*     {pageIndex >= 1 && !isLastPage ? (
      <Beforeunload onBeforeunload={(event: any) => event.preventDefault()} />
    ) : null} */}
      <Formik
        validateOnChange={false}
        validateOnBlur={false}
        validate={() => { }}
        initialValues={formikInitialValues}
        onSubmit={() => { }}
        innerRef={formikRef}
      >
        {({ errors, values }) => {
          return (
            <Grid className={classes.ocaPage} container spacing={5}>

              <Grid item lg={12}>
                <Grid container>
                  <Grid item lg={12}>
                    {formikRef.current && <Form>
                      <RuleEvaluatorWrapper
                        rules={ocaConfig[pageIndex].rules}
                        elementType={'page'}
                        rulesSatisfactionType={ocaConfig[pageIndex].rulesSatisfactionType}
                        children={
                          <OCAv4Page
                            config={ocaConfig[pageIndex]}
                            template={activeTemplate}
                            externalData={externalData}
                          />
                        }
                      />
                    </Form>
                    }
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <PagesWizard
                  formikRef={formikRef}
                  pageNumber={pageNumber}
                  pageIndex={pageIndex}
                  config={ocaConfig}
                  setPageNumber={setPageNumber}
                  setPageIndex={setPageIndex}
                  setVisiblePagesCount={setVisiblePagesCount}
                  setIsCreditAppSubmitted={setIsCreditAppSubmitted}
                />
                {isCreditAppSubmitted && <LinearProgress />}
              </Grid>
            </Grid>
          );
        }}
      </Formik>
    </>
  );
};
