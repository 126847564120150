import React, { useContext } from "react";
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';
import styles from "./PaymentSelector.module.css";
import { VendorContext } from "../../context";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    left: 0,
    top: 0,
    zIndex: 10000,
    backgroundColor: '#FFF',
  },

}));

const config = require("../../config");

const stepLabels = ["Credit Application", "Payment Selection", "Sign Your Docs"];

export const LenderPSConfirmed = () => {
  const { logo, accountName: name, color: vendorColor } = useContext(VendorContext);
  const classes = useStyles();

  return (
    < >
      {logo ? (
        <>
          <div className={styles.phVendorLogoWrapper}>
            <img
              src={logo && logo.includes("/") ? `https://${config.S3_BUCKET_NAME}.s3-us-west-2.amazonaws.com/${logo}` : `https://credit-app-images.s3-us-west-2.amazonaws.com/${logo}`}
              alt="Vendor Logo"
            />
          </div>
          <hr className={styles.horizontalRule} style={{ borderColor: vendorColor, marginTop: "24px" }} />
        </>
      ) : (
        ""
      )}

      <Stepper activeStep={2} alternativeLabel>
        {stepLabels.map(label => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <hr />
      <div style={{ height: "28px" }} />
      <div style={{ textAlign: "center" }}>
        <h4>Thank you for selecting your payment option.</h4>
        <br />
        {name ? name : "Your dealer"} who will be working to finalize the details of your equipment purchase.
        <br />
        Once this occurs, you will receive an email notifying you that you financing documents are ready for e-signature.
      </div>
    </>
  );
};

