import React, { useContext } from "react";
import { makeStyles } from "@mui/styles";
import { AftermarketProductVendorApiChannel, ProposalProduct } from "@trnsact/trnsact-shared-types/dist/generated";
import NTP_LOGO from "../../../assets/images/aftermarket-vendor-logos/ntp-logo.png";
import GAIG_LOGO from "../../../assets/images/aftermarket-vendor-logos/gaig-logo.png";
import PLACEHOLDER_LOGO from "../../../assets/images/aftermarket-vendor-logos/placeholder-logo.png";


const ProductLogo = ({ product }: { product: ProposalProduct }) => {
  const classes = useStyles();

  let logo = PLACEHOLDER_LOGO;

  if (product.aftermarketProduct?.aftermarketVendorApiChannel === AftermarketProductVendorApiChannel.Ntp) {
    logo = NTP_LOGO;
  } else if (product.aftermarketProduct?.aftermarketVendorApiChannel === AftermarketProductVendorApiChannel.Gaig) {
    logo = GAIG_LOGO;
  }

  return <img className={classes.productLogo} src={logo} alt="Product Logo" />;
};


const useStyles = makeStyles(() => {
  return {
    productLogo: {
      width: "32px",
      height: "32px",
      marginRight: "8px",
    },
  };
});

export default ProductLogo;