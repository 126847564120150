import { Grid } from '@mui/material';

export const DisclosureLanguage = ({ language }: { language: string; }) => {
  return (
    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
      <div
        dangerouslySetInnerHTML={{
          __html: language,
        }}
      ></div>
    </Grid>
  );
};
