import React, { useContext } from "react";
import styles from "./OptionCard.module.css";

import { hexToRgb } from "../../../helpers";
import { VendorContext } from "../../../context";

const OptionCard2 = ({ payment, term, rate, handleClick, rateCheck, thisProposalIsFastTracked, paymentSelectorOptionsForThisCard }: { payment: any, term: any, rate: any, handleClick: any, rateCheck?: any, thisProposalIsFastTracked?: any, paymentSelectorOptionsForThisCard?: any }) => {
  const { color: vendorColor } = useContext(VendorContext);
  let rgbColor = `rgba(0, 0, 0, 0.3)`;
  const rgbObj = hexToRgb(vendorColor);
  if (!!vendorColor && rgbObj) {
    rgbColor = `rgba(${rgbObj.r}, ${rgbObj.g}, ${rgbObj.b}, 0.3)`;
  }
  const showInterestRate = paymentSelectorOptionsForThisCard ? paymentSelectorOptionsForThisCard.showInterestRate : false;

  // console.log(`Option2Card: rate: ${rate} | rateCheck: ${rateCheck} | paymentSelectorOptionsForThisCard: ${JSON.stringify(paymentSelectorOptionsForThisCard)}`);

  return (
    <React.Fragment>
      {rateCheck > 15 ? (
        <div className={styles.card} style={{ backgroundColor: rgbColor }} onClick={handleClick}>
          <p >${payment}/mo.</p>
          <p className={styles.greyText}>for {term} months</p>
          <p>
            <strong>See Details</strong>
          </p>
        </div>
      ) : (
        <div className={styles.card} style={{ backgroundColor: rgbColor }} onClick={handleClick}>
          <p >${payment}/mo.</p>
          <p className={styles.greyText}>
            for {term} months {thisProposalIsFastTracked || !showInterestRate ? "" : `at ${rate}%`}
          </p>
          <p>
            <strong>See Details</strong>
          </p>
        </div>
      )}
    </React.Fragment>
  );
};

export default OptionCard2;
