import { useEffect } from 'react';
import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FormikProps, useFormikContext } from 'formik';
import { Element } from '../Element';
import { DisclosureLanguage, TermsAndConditions } from '../elements';
import { FieldType, SectionType } from '../types';
import _ from 'lodash';
const useStyles = makeStyles(() => ({
  sectionHeader: {
    fontWeight: 500,
    fontFamily: 'Source Sans Pro, sans-serif',
    fontSize: 22,
    textAlign: 'center',
  },
}));
const Signatures = ({
  config,
  disclosureLangEN,
  disclosureLangES,
  dcrDisclosureTermsEN,
  dcrDisclosureTermsES,
  dcrDisclosureUseDefaultTerms,
  locale,
  template,
  externalData,
}: {
  config: SectionType;
  disclosureLangEN: string;
  disclosureLangES: string;
  dcrDisclosureTermsEN: string;
  dcrDisclosureTermsES: string;
  dcrDisclosureUseDefaultTerms: boolean;
  locale: string;
  template: any;
  externalData: any;
}) => {
  const classes = useStyles();
  const formik: FormikProps<any> = useFormikContext();
  const tempateSettingsDisclosures = _.get(template, "jsonSettings.disclosure");
  const { isIndividualApp } = externalData;
  const disclosureKey = isIndividualApp ? 'individual' : 'commercial';
  const dynamicDisclosureAuthorizationLanguage = _.get(tempateSettingsDisclosures, [disclosureKey, 'authorizationLanguage', locale], null);
  const dynamicDisclosureTermsAndConditionsLanguage = _.get(tempateSettingsDisclosures, [disclosureKey, 'termsAndConditionsLanguage', locale], null);
  const authorizationLanguageToShow = dynamicDisclosureAuthorizationLanguage ||
    (locale === 'es' ? disclosureLangES : disclosureLangEN);
  const customTermsAndConditionsLanguage = dynamicDisclosureTermsAndConditionsLanguage ||
    (locale === 'es' ? dcrDisclosureTermsES : dcrDisclosureTermsEN);

  useEffect(() => {
    formik.setFieldValue('disclosure', JSON.stringify({
      authorizationLanguage: authorizationLanguageToShow,
      termsAndConditionsLanguage: customTermsAndConditionsLanguage
    }));
  }, []);

  return (
    <Grid container spacing={4}>
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <Grid container spacing={2}>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <h3
              style={{ fontSize: 22, textAlign: 'center' }}
              className={classes.sectionHeader}
            >
              {_.get(config, 'title.config.displayLabel.en', '')}
            </h3>
          </Grid>
          <DisclosureLanguage language={authorizationLanguageToShow} />
          <TermsAndConditions
            customTermsAndConditionsLanguage={customTermsAndConditionsLanguage}
            dcrDisclosureUseDefaultTerms={dcrDisclosureUseDefaultTerms}
            sectionConfig={config}
          />
          <Grid
            item
            xl={12}
            lg={12}
            md={12}
            sm={12}
            xs={12}
            key={`signaturesPageSimple}`}
          >
            <Grid container spacing={4}>
              {config.fields.map((field: FieldType, index: number) => (
                <Element
                  item={field}
                  key={`signatures.${index}`}
                  locale={locale}
                  externalData={externalData}
                />
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export { Signatures };
