import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useFormikContext, FormikProps } from 'formik';
import { useState, useContext } from 'react';
import { CommonDataContext } from '../../context';
import { FieldConfigValuesListType, FieldType } from '../types';

const useStyles = makeStyles(() => ({
  radioGroup: {
    flexDirection: 'row',
  },
  radioButton: {
    marginRight: 40,
    color: '#818181',
    '& .MuiTypography-root': {
      fontSize: 12,
    },
  },
  header: {
    fontSize: 20,
    fontWeight: 500,
  },
  text: {
    margin: '10px 0',
    color: '#606060',
  },
}));

const RadioField = ({
  itemData,
  value,
  locale,
}: {
  itemData: FieldType;
  value: any;
  locale: string;
}) => {
  const { id: fieldId, config: fieldConfig } = itemData;
  const [isInvalid, setIsInvalid] = useState(false);
  const formik: FormikProps<any> = useFormikContext();
  const { isMobileView } = useContext(CommonDataContext);

  const classes = useStyles();
  return (
    <FormControl
      variant={isMobileView ? "outlined" : "standard"}
      component="fieldset"
      style={{ padding: 10 }}
    >
      <Typography variant="h5" component="h5" className={classes.header}>
        Quick Question
      </Typography>
      <p className={classes.text}>Does this business:</p>
      <p className={classes.text}>
        1.Generate $10 million or more in total sales?
      </p>
      <p className={classes.text}>2.Have more than 6 owners?</p>
      <RadioGroup className={classes.radioGroup} name={fieldConfig.fieldName}>
        {fieldConfig.valuesList?.map(
          (variant: FieldConfigValuesListType, index: number) => (
            <FormControlLabel
              className={classes.radioButton}
              value={variant.value}
              control={<Radio key={`${fieldId}${index}`} color="primary" />}
              label={variant.label}
              disabled={fieldConfig.disabled}
            />
          )
        )}
      </RadioGroup>
    </FormControl>
  );
};
export { RadioField };
