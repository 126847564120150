import { getURLParameter } from '../helpers';
import { validate, v4 as uuidv4 } from 'uuid';
import _ from 'lodash';

//Set TTL for localstorage for a week
const daysToSaveData = 7;
const ttlSavedData = 1000 * 60 * 60 * 24 * daysToSaveData;

export const destroyLocalStorage = (formId: string) => {
  localStorage.removeItem(`savedForm_${formId}`);
};

export const markAsAlreadySubmitted = (formId: string) => {
  const key = `savedForm_${formId}`;
  const itemStr = localStorage.getItem(key);
  if (!itemStr) {
    return null;
  }
  const item = JSON.parse(itemStr);

  item.alreadySubmitted = true;

  localStorage.setItem(`savedForm_${getURLParameter('formId')}`, JSON.stringify(item));
};

export const getLocalStorageWithExpiry = (formId: string) => {
  const key = `savedForm_${formId}`;
  const itemStr = localStorage.getItem(key);
  // if the item doesn't exist, return null
  if (!itemStr) {
    return null;
  }
  const item = JSON.parse(itemStr);
  const now = new Date();
  // compare the expiry time of the item with the current time
  if (now.getTime() > item.expiry) {
    // If the item is expired, delete the item from storage
    // and return null
    localStorage.removeItem(key);
    return null;
  }
  return item.values;
};

export const restoreSaveFormData = (initialValues: any, formId: string) => {
  const localStoragePrev = getLocalStorageWithExpiry(formId);

  //Merge initial values
  _.merge(initialValues, { ...localStoragePrev });
  return initialValues;
};

export const setLocalStorageWithExpiry = (values: any, formId: string) => {
  const now = new Date();

  // `item` is an object which contains the original value
  // as well as the time when it's supposed to expire
  const item = {
    values,
    expiry: now.getTime() + ttlSavedData,
  };
  localStorage.setItem(`savedForm_${formId}`, JSON.stringify(item));
};
