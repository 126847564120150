import { useMutation } from '@apollo/react-hooks';
import _ from 'lodash';
import { useEffect } from 'react';
import { apiMapping, MUTATION_CREATE_CREDIT_APP } from '../api';
const TICKER_INTERVAL = 10000;
export const SaveAndReturn = ({
  values,
  errors,
  vendorContactId,
  formId,
  vendorGUID,
  locationId,
  activeTemplate,
  source,
  isCreditAppSubmitted,
  setIsCreateDraftCreditAppInProgress,
}: {
  values: any;
  errors: any;
  vendorContactId: string;
  formId: string;
  vendorGUID: string;
  locationId: string;
  activeTemplate: any;
  source: string | null | undefined;
  isCreditAppSubmitted: boolean;
  setIsCreateDraftCreditAppInProgress: any;
}) => {

  const [createDraftCreditApp, { data, loading: isCreateDraftCreditAppLoading }] = useMutation(
    MUTATION_CREATE_CREDIT_APP
  );

  // we need to nullify errored fields since they can cause errors on api-server (e.g. if date format is wrong)
  const nullifyErroredFields = (valuesCopy: any) => {
    _.forEach(valuesCopy, (value: any, key: string) => {
      if (errors[key] === 'required') {
        _.set(valuesCopy, key, null);
      }
    });
  };

  useEffect(() => {
    const ticker = setInterval(() => {
      //Min data required
      const isDraftReady = _.every(['firstName', 'lastName', 'email'], (key) => !_.isEmpty(values[key]));
      if (!isDraftReady || isCreditAppSubmitted || isCreateDraftCreditAppLoading) {
        return;
      }

      const valuesCopy: any = _.cloneDeep(values);  // to not modify original values
      nullifyErroredFields(valuesCopy);
      const payload = apiMapping({
        values: valuesCopy,
        vendorContactId,
        vendorGUID,
        formId,
        locationId: locationId || _.get(valuesCopy, "locationId", null),
        draft: true,
        activeTemplate,
        source
      });

      try {
        //@ts-ignore
        window.waitForGlobal('_mfq', function () {
          //@ts-ignore
          window._mfq.push(['setVariable', 'EMAIL', valuesCopy.email]);
          //@ts-ignore
          window._mfq.push(['setVariable', 'FIRST_NAME', valuesCopy.firstName]);
          //@ts-ignore
          window._mfq.push(['setVariable', 'LAST_NAME', valuesCopy.lastName]);
        });
      } catch (error) { }

      try {
        createDraftCreditApp({
          variables: {
            creditApplicationInput: { ...payload, owners: [], references: [], equipments: [] },
          },
        });
      } catch (error) { }
    }, TICKER_INTERVAL);

    if (isCreditAppSubmitted) {
      clearInterval(ticker);
    }
    return () => {
      clearInterval(ticker);
    };
  }, [values, errors, isCreditAppSubmitted, isCreateDraftCreditAppLoading]);

  useEffect(() => {
    setIsCreateDraftCreditAppInProgress(isCreateDraftCreditAppLoading);
  }, [isCreateDraftCreditAppLoading]);

  return null;
};
