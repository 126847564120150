import { gql } from 'apollo-boost';


const creditApplicationFullAttributesFragemnt = gql`
  fragment creditApplicationAttributes on CreditApplication {
      id
      dynamicsId
      amountRequested
      firstName
      middleName
      lastName
      nameSuffix
      email
      businessName
      phone
      address
      city
      state
      postalCode
      legalStructure
      tib
      repId
      vendorSalespersonId
      transactionGuid
      yearsLicensed
      signature
      title
      ssn
      ssnExempt
      ownerPercentage
      businessType
      parentCompany
      country
      dateEstablished
      dateIncorporated
      taxId
      monthlyRevenue
      dateOfBirth
      industryExpYears
      monthlyIncome
      previousFinanceExp
      trucksOwned
      bizPhone
      homeSameAsBiz
      homeAddress
      homeCity
      homeState
      homePostalCode
      equipmentDescription
      createdDateTime
      modifiedDateTime
      paymentSelectorURL
      dcrType
      dcrProductId
      paymentSelectorURLV2
      proposalId
      rate
      riskTier
      markupInternal
      markupDealer
      dba
      vendorGUID
      stockId
      stateOfIncorp
      businessAddressCounty
      driversLicenseNum
      dlStateIssued
      homeType
      homePhone
      applicationType
      ocaLanguage
      creditLineAmountRequested
      creditLineNotes
      sic
      equipmentCategoryCode
      purchaseReason
      piecesOfHeavyEqpt
      piecesOfMediumEqpt
      piecesOfTrailerEqpt
      totalEqpt
      eqptUse
      numOfOwnerOps
      typesOfGoods
      longHaulRegional
      hazmat
      yearMakeModel
      payment
      eqptPaidOff
      eqptTrade
      ownAuth
      mcNumber
      usDotNum
      eqptAddOrReplace
      applyingFor
      invoiceDelivery
      purchaseOrder
      primaryAgriculturalProduct
      farmingStartYear
      farmingIncome
      nonFarmingIncome
      nearestRelativeFirstName
      nearestRelativeLastName
      nearestRelativeCity
      nearestRelativePhone
      nearestRelativeState
      nearestRelativeZip
      billingAddress
      billingCity
      billingState
      billingPostalCode
      numberOfEmployees
      useByApplicant
      kubotaPrincipalUse

      medicalPractice
      industryType
      prequalStatus
      ocaTemplateId
      ocaTemplate {
        name
      }
      creditApplicationOwner {
        ownerPgId
        pc
        pg
        pcOnly
        firstName
        middleName
        lastName
        nameSuffix
        ownerPercentage
        ssn
        title
        dateOfBirth
        address
        address2
        city
        state
        postalCode
        addressCounty
        driversLicenseNum
        dlStateIssued
        driverLicenseExp
        ownerNumber
        homeType
        homePhone
        phone
        timeAtAddressYears
        timeAtAddressMonths
        netWorth
        grossMonthlyIncome
        monthlyHousingPayment
        employerName
        employerAddress
        employerAddress2
        employerCity
        employerState
        employerZip
        employmentStatus
        workPhone
        employerEmail
        timeAtJobYears
        timeAtJobMonths
        mobilePhone
        signature
        signatureDate
        dlUploaded
        email
        usCitizen
        commercialDriverMonths
        commercialDriverYears
        residentStatus
        providingGuaranty
        maritalStatus
        companyDriverYears
        ownerOperatorYears
        countryOfResidence
        ownershipType
        firstTimeFinancing
        nearestRelativeFirstName
        nearestRelativeLastName
        nearestRelativePhone
        nearestRelativeCity
        nearestRelativeZip
        prevAddress
        prevAddress2
        prevCity
        prevState
        prevCounty
        prevPostalCode
        reasonForDeclining
        yearsWithCdl
        personalGuaranteeSignature
        personalGuaranteeSignatureDate
        addendum
      }
      lenderStatus
      primaryContact {
        ownerPgId
        pc
        pg
        pcOnly
        firstName
        middleName
        lastName
        nameSuffix
        ownerPercentage
        ssn
        title
        dateOfBirth
        address
        address2
        city
        state
        postalCode
        driversLicenseNum
        dlStateIssued
        ownerNumber
        homeType
        homePhone
        phone
        timeAtAddressYears
        timeAtAddressMonths
        netWorth
        grossMonthlyIncome
        monthlyHousingPayment
        employerName
        employerAddress
        employerAddress2
        employerCity
        employerState
        employerZip
        employerEmail
        employmentStatus
        workPhone
        timeAtJobYears
        timeAtJobMonths
        mobilePhone
        signature
        signatureDate
        dlUploaded
        email
        usCitizen
        commercialDriverMonths
        commercialDriverYears
        residentStatus
        providingGuaranty
        maritalStatus
        companyDriverYears
        ownerOperatorYears
        yearsWithCdl
        personalGuaranteeSignature
        personalGuaranteeSignatureDate
        addendum
      }
      creditLineStatus
      truckType
      references {
        referenceId
        companyName
        contactName
        contactPhone
        accountNum
        originalLoanAmt
        percRevenue
        years
        months
        lineOfCredit
        creditLimit
        currentBalance
        monthlyPayment
        renewalDate
        referenceType
        contactEmail
        contactFax
        address
        city
        state
        zip
        county
        jobTitle
        startDate
        endDate
        industry
        salary
        monthlyRevenue
        paidPerMile
        percentGross
        averageBalance
        yearMakeModel
        paidOff
        trade
        securedBy
        physicalDamageDeduction
        liabilityCoverage
        productType
        okayToContact
        driverType
        futureEmployerSameAsCurrent
        monthlyMiles
        custom1
        custom2
        custom3
        referenceIdx
        ownerId
      }
      location {
        locationId
        locationName
        category
        accountDynamicsId
      }
      businessOrIndividual
      farmType
      usCitizen
      farmingStatus
      addressCounty
      agriculturalExempt
      annualMileage
      annualRevenue
      apContactEmail
      apContactName
      apContactPhone
      bankruptcyDate
      contractorLicenseNum
      currentOwnerStartBusiness
      downPaymentAmount
      employerAddress
      employerAddress2
      employerCity
      employerEmail
      employerName
      employerPhone
      employerState
      employerZip
      existingCustomer
      fleetOperation
      fleetSegments
      formId
      homeCounty
      inactiveMonths
      incomeAmountPerYearOther
      incomeSourceOther
      lienOrJudgement
      lienOrJudgementDetail
      numOfEmployees
      numberOfAdditions
      numberOfReplacements
      ocaType
      operatingLine
      periodOfEmploymentMonths
      periodOfEmploymentYears
      piecesOfTractorEqpt
      piecesOfTruckEqpt
      piecesOfUnitsPurchased
      prevAddress
      prevCity
      prevCounty
      prevPostalCode
      prevState
      previousEmployerName
      previousEmployerPeriodOfEmploymentYears
      primaryBankAccountNum
      primaryBankAverageBalance
      primaryBankContactName
      primaryBankName
      primaryBankPhone
      primaryCreditAccountNum
      primaryCreditAverageBalance
      primaryCreditContactEmail
      primaryCreditContactName
      primaryCreditName
      primaryCreditPhone
      priorBankruptcy
      salesTaxExempt
      seasonalBusiness
      termRequested
      timeAtAddressInMonths
      timeAtAddressInYears
      timeAtPrevAddressMonths
      timeAtPrevAddressYears
      totalAssets
      totalLiabilities
      yearsInBusiness
      pastExperience
      disclosure
      applicantType
      hadItemRepossessed
      repossessionDetail
      rentalHouse
      firstTimeBuyer
      addendum
      addendumContent
      insuranceWaiver
      insuranceWaiverContent
      providingInsurance
      purchaseTimeframe
      authorizedParties
      workingCapitalRequestedAmount
      averageDailyBalance
      workingCapitalOffer
      recertificationDate
      recertificationHistory
  }
`;

export const Q_CREDIT_APP = gql`
  query CreditApplication($id: ID!) {
    creditApplication(id: $id) {
      ...creditApplicationAttributes
    }
  }
  ${creditApplicationFullAttributesFragemnt}
`;

export const Q_CREDIT_APP_PUBLIC = gql`
  query CreditApplicationPublic($id: ID!) {
    creditApplicationPublic(id: $id) {
      ...creditApplicationAttributes
    }
  }
  ${creditApplicationFullAttributesFragemnt}
`;

export const UPDATE_CREDIT_APPLICATION_V2_QUERY = gql`
  mutation($creditAppId: ID!, $input: UpdateCreditApplicationV2Input!) {
    updateCreditApplicationV2(creditAppId: $creditAppId, input: $input)
  }
`;