import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';
import React, { SyntheticEvent, useCallback, useEffect } from 'react';
import NotFoundBg from './assets/images/not_found.jpg';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    left: 0,
    top: 0,
    zIndex: 10000,
    backgroundColor: '#FFF',
  },
  imgBg: {
    cursor: 'pointer',
    maxHeight: '100vh',
    margin: '0 auto',
    '@media (max-width: 768px)': {
      height: '100vh',
      width: '100vw',
      margin: '0',
    },
  },
}));

const sendMail = (e: SyntheticEvent) => {
  e.preventDefault();
  //@ts-ignore
  window.location =
    'mailto:support@trnsact.com?subject=Received incorrect application link';
  return;
};

const PageNotFound = () => {
  useEffect(() => {
    try {
      //@ts-ignore
      window.waitForGlobal('_mfq', function () {
        //@ts-ignore
        window._mfq.push([
          'newPageView',
          `${process.env.PUBLIC_URL}/no-vendor`,
        ]);
      });
    } catch (error) {
      console.log(error);
    }
  }, []);

  const classes = useStyles();
  return (
    <Grid container className={classes.container}>
      <img
        className={classes.imgBg}
        src={NotFoundBg}
        alt=""
        onClick={sendMail}
      />
    </Grid>
  );
};

export default PageNotFound;
