import { gql } from 'apollo-boost';

export const QUERY_LENDER_PROFILE_BY_DYNAMICS_ID = gql`
  query getLenderProfileByDynamicsId($lenderProfileDynamicsId: ID) {
    getLenderProfileByDynamicsId(lenderProfileDynamicsId: $lenderProfileDynamicsId) {
      lenderProfileId
      name
      insuranceRequirements
    }
  }
`;

export const QUERY_LENDER_PROFILE_BY_ID = gql`
  query getLenderProfileById($lenderProfileId: ID) {
    lenderProfileById(lenderProfileId: $lenderProfileId) {
      lenderProfileId
      lenderProfileDynamicsId
      lenderDynamicsAccountId
      dealerAccountId
      dealerDynamicsAccountId
      ocaTemplate
      ocaLenderUserProfileId
      ocaDealerUserProfileId
      ocaBranding
      ocaAutoSubmission
      ocaPrescreenRules
    }
  }
`;