import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { client } from './config';
import { BrowserRouter } from 'react-router-dom';
import { ApolloProvider } from '@apollo/react-hooks';
import TagManager from 'react-gtm-module';
import { GTM_ID } from './config';

const tagManagerArgs = {
  gtmId: GTM_ID
};
TagManager.initialize(tagManagerArgs);

ReactDOM.render(
  <ApolloProvider client={client}>
    <BrowserRouter basename={'/'} >
      <App />
    </BrowserRouter>
  </ApolloProvider>,

  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
