import { gql } from 'apollo-boost';

export const QUERY_TASK = gql`
query ($taskId: ID!) {
  publicTask(taskId: $taskId) {
    taskId
    vendorOpportunityId
    regarding
    status
    priority
    createdBy
    createdDateTime
    assignedByUser
    assignedByAccount
    assignedToUser
    assignedToAccount
    assignedToAccountId
    lastReminderSent
    nextReminder
    creditSubId
    ownerPgId
    assignedToApplicant
    assignedBySystem
    taskItems {
      taskItemId
      type
      docType
      taskAssignorNotes
      taskAssigneeNotes
      documents {
        docName
        documentId
      }
    }
    vendorOpportunity {
      equipmentDescription
      dynamicsVendorOpportunityId
      salesRepresentative {
        firstName
        lastName
        dynamicsContactId
        fullName
        title
      }
    }
    createdByUserProfile {
        lastName
        firstName
        fullName
        email
        phoneNumber
        mugshot
      }
  }
}
`;

export const QUERY_ACCOUNT_TASKS_TEMPLATES = gql`
  query GetAccountTasksTemplates($options: UpsertTaskInput) {
    tasks(options: $options) {
      taskId
      regarding
      assignedToAccountId
      assignedByAccountId
      assignedToAccountId
      assignedByAccount
      assignedToApplicant
      active
      status
      priority
      notifyByEmail
      isTemplate
    }
  }
`;

export const UPDATE_TASK_STATUS = gql`
  mutation ($taskId: ID!, $status: String!) {
    updateTaskStatus(taskId: $taskId, status: $status)
  }
`;


export const GENERATE_E_SIGN_URL = gql`
  query generateESignUrl($taskId: ID!) {
    generateESignUrl(taskId: $taskId)
  }
`;

export const SAVE_E_SIGN_DOCUMENT = gql`
  mutation ($taskId: ID!, $eid: String!) {
    saveESignDocument(taskId: $taskId, eid: $eid)
  }
`;


export const REMOVE_DOCUMENT_TASK_ITEM = gql`
mutation RemoveTaskItemDocument($documentId: ID!, $taskItemId: ID!) {
  removeTaskItemDocument(documentId: $documentId, taskItemId: $taskItemId)
}
`;
export const REMOVE_ALL_DOCUMENT_TASK_ITEM = gql`
mutation RemoveAllTaskItemDocument($taskItemId: ID!) {
  removeAllTaskItemDocument(taskItemId: $taskItemId)
}
`;