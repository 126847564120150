import {
  Box,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FormikProps, useFormikContext } from 'formik';
import _ from 'lodash';
import { useCallback, useEffect, useState, useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { FieldConfigValuesListType, FieldType } from '../types';
import { CommonDataContext } from '../../context';

const useStyles = makeStyles(() => ({
  radioGroup: {
    flexDirection: 'row',
  },
  radioButton: {
    color: '#818181',
    '& .MuiTypography-root': {
      fontSize: 12,
    },
    maxWidth: '40%',
    float: 'left',
  },
  header: {
    fontSize: 20,
    fontWeight: 500,
  },
  text: {
    margin: '10px 0',
    color: '#606060',
  },
}));

const DocumentQuestion = ({
  itemData,
  value,
  locale,
}: {
  itemData: FieldType;
  value: any;
  locale: string;
}) => {
  const { id: fieldId, config: fieldConfig } = itemData;
  const formik: FormikProps<any> = useFormikContext();
  const { isMobileView, highlightErrorFieldsState } = useContext(CommonDataContext);
  const [isInvalid, setIsInvalid] = useState(false);
  const classes = useStyles();

  const evaluateIfInvalid = (value: string): boolean => {
    if (!value) return true;
    return false;
  };

  const handleChange = useCallback((e) => {
    formik.setFieldValue(itemData.config.fieldName, e.target.value, false);
    setIsInvalid(evaluateIfInvalid(e.target.value));
  }, []);

  const _resetField = (): void => {
    // Check if error is already set to ''
    if (Boolean(_.get(formik.errors, itemData.config.fieldName))) {
      //Remove Error
      formik.setFieldError(itemData.config.fieldName, '');
    }

    if (!_.has(formik.values, itemData.config.fieldName)) { // do not set '' value for untouched fields
      return;
    }

    //Erase value
    formik.setFieldValue(itemData.config.fieldName, '');
  };

  useEffect(() => {
    return () => {
      formik.setFieldError(itemData.config.fieldName, '');
    };
  }, []);

  useEffect(() => {
    //When there is no need to display the field, we reset it's state to a clean one
    if (!itemData.config.displayed) {
      _resetField();
    } else {
      let isEmpty = false;
      if (itemData.config.required) {
        isEmpty = _.isEmpty(value);
      }
      formik.setFieldError(
        itemData.config.fieldName,
        isInvalid || isEmpty ? 'required' : ''
      );
    }
  }, [itemData.config.displayed, itemData.config.required, isInvalid, value]);

  useEffect(() => {
    if (highlightErrorFieldsState.state) {
      setIsInvalid(!!formik.getFieldMeta(itemData.config.fieldName).error);
    }
  }, [highlightErrorFieldsState]);

  return (
    <Box
      component={Grid}
      item
      xl={_.get(fieldConfig, 'columns.xl', 12)}
      lg={_.get(fieldConfig, 'columns.lg', 12)}
      md={_.get(fieldConfig, 'columns.md', 12)}
      sm={_.get(fieldConfig, 'columns.sm', 12)}
      xs={_.get(fieldConfig, 'columns.xs', 12)}
      display={{
        xl: !itemData.config.displayed ? 'none' : 'block',
        lg: !itemData.config.displayed ? 'none' : 'block',
        md: !itemData.config.displayed ? 'none' : 'block',
        sm: !itemData.config.displayed ? 'none' : 'block',
        xs: !itemData.config.displayed ? 'none' : 'block',
      }}
    >
      <Grid container spacing={1}>
        <FormControl
          variant={isMobileView ? "outlined" : "standard"}
          component="fieldset"
          fullWidth={true}
          style={{ padding: 10 }}
        >
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Typography variant="h5" component="h5" className={classes.header}>
              Document Upload
            </Typography>
            <p className={classes.text} style={isInvalid ? { color: "#d32f2f" } : {}}>
              <FormattedMessage
                id={'driverLicenceNowOrLater'}
                defaultMessage={'driverLicenceNowOrLater'}
              />
              {fieldConfig.required && " *"}
            </p>
          </Grid>
          <Grid container spacing={4}>
            <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
              <RadioGroup
                className={classes.radioGroup}
                onChange={handleChange}
                name={fieldConfig.fieldName}
                value={value ? value : ''}
              >
                {fieldConfig.valuesList?.map(
                  (variant: FieldConfigValuesListType, index: number) => (
                    <FormControlLabel
                      key={`formControl${fieldId}${index}`}
                      className={classes.radioButton}
                      value={variant.value}
                      control={
                        <Radio key={`${fieldId}${index}`} color="primary" />
                      }
                      label={_.get(variant, 'displayLabel.en', '')}
                    />
                  )
                )}
              </RadioGroup>
            </Grid>
            <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
              {!!value && value == 'no' ? (
                <FormattedMessage
                  id={'expectEmailDriverLicense'}
                  defaultMessage={'expectEmailDriverLicense'}
                />
              ) : null}
            </Grid>
          </Grid>
        </FormControl>
      </Grid>
    </Box>
  );
};
export { DocumentQuestion };
